import React from "react";
import parse from 'html-react-parser';
import { Box, useTheme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import { useTranslate } from "react-admin";

const useStyles = makeStyles()((theme) => ({
  root: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
  },
  title: {
    fontSize: '21px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '20px',
    color: theme.colors.shark,
  },
}));

export default ({ title, quadrant, description, props }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  // const translate = useTranslate();

  return (
    <Box
      {...props}
      className={classes.root}
      padding={4}
    >
      <Box
        className={classes.title}
        style={{ color: theme.colors.quadrantTypes[quadrant] }}
      >
        {parse(title || '')}
      </Box>
      <Box
        marginTop={3}
        className={classes.description}
      >
        {parse(description || '')}
      </Box>
    </Box>
  );
};