import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import WelcomeTitle from '../common/WelcomeTitle';

import JotFormReact from 'jotform-react';
import { Button } from 'ra-ui-materialui';
import { Link } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { useAppContext } from '../../contexts/AppContext';

const useStyles = makeStyles()((theme) => ({
  title_container: {
    padding: '32px',

  },
  modal_title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#333333',
    textAlign: 'center',
  },
  modal_img: {
    margin: '0 auto',
    height: '317px',
    width: '370px',
    marginTop: '16px',

  },
  modal_text: {
    fontSize: '16px',
    color: '#667085',
    textAlign: 'center',
    marginTop: '16px',

  },
  modal_buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
    gap: '12px',
    padding: '0'

  },
  modal_button_resubmit: {
    padding: '6px 16px',
    borderRadius: '4px',
    border: '1px solid #003E6F',
    color: '#003E6F',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    height: '36px',
    outline: 'none',
    cursor: 'pointer',

  },
  modal_button_dashboard: {
    padding: '6px 16px',
    borderRadius: '4px',
    background: '#003E6F',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: '0',
    height: '36px',
    color: 'white !important',
    textDecoration: 'none !important',
    textDecorationColor: 'transparent',
    border: 'none',
    outline: 'none',
    marginLeft: '0 !important',
    cursor: 'pointer',

    'a': {
      color: 'white',
      textDecoration: 'none',
    }
  },
  modal_content: {
    '.MuiDialog-paper': {
      width: '1000px',
      maxWidth: '1000px',
      // boxSizing: 'content-box',
      padding: '24px'

    }
  },
  modal_content2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0'
  }
}));

const TestPage = ((props) => {
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { refetch } = useAppContext();

  const handleClose = () => {
    setShowModal(false)

  }
  const record = useRecordContext();
  const handleSubmit = async (e) => {
    setShowModal(true);
    await refetch();
  };

  const lastSurve = useMemo(() => {
    return record.Surveys[record.Surveys.length - 1]


  }, [record.Surveys])


  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <Box
          className={classes.title_container}

        >
          <WelcomeTitle
            title={`Get started on your Life Inventory`}
            subtitle={'The assessment may take a moment to load...'}
          />
        </Box>
        <JotFormReact
          formURL="https://form.jotform.com/200358087447257"
          formID="200358087447257" // Required for multiple forms on the same page
          onSubmit={handleSubmit}
          style={{
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '11700px',

            border: 'none',
            // overflow: 'auto',
          }}
        />
        <Dialog
          open={showModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modal_content}
        >
          <DialogContent className={classes.modal_content2}>
            <Typography className={classes.modal_title}>Successfully Submitted!</Typography>
            <img className={classes.modal_img} src="/submitTestImg.png" alt="image" />
            <Typography className={classes.modal_text}>Thank you. You are Life Map is ready and you can check it in “LIA Record”</Typography>
          </DialogContent>
          <DialogActions className={classes.modal_buttons}>
            <button className={classes.modal_button_resubmit} onClick={() => { handleClose() }}>
              Re-Submit
            </button>
            <button className={classes.modal_button_dashboard} onClick={() => { }}>
              <Link to={`/client/${record.id}/assessments/${lastSurve.id}`}>

                Dashboard
              </Link>

            </button>

          </DialogActions>
        </Dialog>
      </Box >
    </>
  );
});

export default TestPage;