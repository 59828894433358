import React, { useCallback, useMemo, useState } from 'react';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
  Notification, useGetIdentity, useGetList, useRecordContext,
  useResourceDefinitions,
} from 'react-admin';
import defaultStyles from '../styles/default';

import { defaultTheme } from 'react-admin';
import NewSidebar from '../components/sidebar/NewSidebar';
import NewHeader from '../components/sidebar/NewHeader';
import { Content } from './Content';
import { useLocation } from 'react-router-dom';
import { SidebarLink } from '../components/sidebar/SidebarLink';
import AppContext from '../contexts/AppContext';
import { useWindowSizes } from '../hooks/useWindowSize';

const theme = createTheme({
  ...defaultTheme.props,
  palette: {
    ...defaultTheme.palette,
    ...defaultStyles.palette,
  },
  colors: {
    ...defaultStyles.colors,
  },
  typography: {
    ...defaultTheme.typography,
    ...defaultStyles.typography,
  },
  overrides: {
    ...defaultTheme.overrides,
    ...defaultStyles.overrides,
  }
});

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#E5EBEA5C',
    position: 'relative',
    // maxWidth: '1366px',
    padding: '0 !important',
    boxShadow: '0px 3px 6px #00000029',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    height: '100vh',
  },
  contentWithSidebar: {
    display: 'grid',
    gridTemplateColumns: '256px 1fr',
    // flexGrow: 1,
    overflow: 'hidden',
    height: '100%',
    transition: '0.5s',
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr !important',
    }
  },
  contentWithSidebarHidenSidebar: {
    gridTemplateColumns: '0px 1fr !important',

    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr !important',
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    ////////////////////
    flexGrow: 2,
    padding: theme.spacing(6),
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      // padding: '20px',
      // overflowX: 'hidden',
      // maxWidth: "100vw",
      // backgroundColor: 'white'
    },
  },
  content_without_padding: {
    padding: '0',

  },
  sidebar: {
    flexShrink: 0,
    zIndex: 5,

    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  player: {
    display: 'block',
  },
}));



const gerSidebarIcons = ({ isActive, iconName }) => {
  const sidebarIcons = {
    clients: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM13.6 6.34C14.67 6.34 15.53 7.2 15.53 8.27C15.53 9.34 14.67 10.2 13.6 10.2C13.0881 10.2 12.5972 9.99666 12.2353 9.63472C11.8733 9.27277 11.67 8.78187 11.67 8.27C11.66 7.2 12.53 6.34 13.6 6.34ZM7.6 4.76C8.9 4.76 9.96 5.82 9.96 7.12C9.96 8.42 8.9 9.5 7.6 9.5C6.3 9.5 5.24 8.42 5.24 7.12C5.24 5.81 6.29 4.76 7.6 4.76ZM7.6 13.89V17.64C5.2 16.89 3.3 15.04 2.46 12.68C3.5 11.56 6.13 11 7.6 11C8.13 11 8.8 11.07 9.5 11.21C7.86 12.08 7.6 13.23 7.6 13.89ZM10 18C9.72 18 9.46 18 9.2 17.96V13.89C9.2 12.47 12.14 11.76 13.6 11.76C14.67 11.76 16.5 12.15 17.44 12.91C16.27 15.88 13.38 18 10 18Z"
        fill={isActive ? "white" : "#003E6E"} />
    </svg>,

    coaches: <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11.0004C8.96667 11.0004 9.79167 10.6587 10.475 9.97539C11.1583 9.29206 11.5 8.46706 11.5 7.50039C11.5 6.53372 11.1583 5.70872 10.475 5.02539C9.79167 4.34206 8.96667 4.00039 8 4.00039C7.03333 4.00039 6.20833 4.34206 5.525 5.02539C4.84167 5.70872 4.5 6.53372 4.5 7.50039C4.5 8.46706 4.84167 9.29206 5.525 9.97539C6.20833 10.6587 7.03333 11.0004 8 11.0004ZM8 17.9004C8.98333 17.5837 9.85417 17.0879 10.6125 16.4129C11.3708 15.7379 12.0333 14.9754 12.6 14.1254C11.8833 13.7587 11.1375 13.4796 10.3625 13.2879C9.5875 13.0962 8.8 13.0004 8 13.0004C7.2 13.0004 6.4125 13.0962 5.6375 13.2879C4.8625 13.4796 4.11667 13.7587 3.4 14.1254C3.96667 14.9754 4.62917 15.7379 5.3875 16.4129C6.14583 17.0879 7.01667 17.5837 8 17.9004ZM8 19.9004C7.88333 19.9004 7.775 19.8921 7.675 19.8754C7.575 19.8587 7.475 19.8337 7.375 19.8004C5.125 19.0504 3.33333 17.6629 2 15.6379C0.666667 13.6129 0 11.4337 0 9.10039V4.37539C0 3.95872 0.120833 3.58372 0.3625 3.25039C0.604167 2.91706 0.916667 2.67539 1.3 2.52539L7.3 0.275391C7.53333 0.192057 7.76667 0.150391 8 0.150391C8.23333 0.150391 8.46667 0.192057 8.7 0.275391L14.7 2.52539C15.0833 2.67539 15.3958 2.91706 15.6375 3.25039C15.8792 3.58372 16 3.95872 16 4.37539V9.10039C16 11.4337 15.3333 13.6129 14 15.6379C12.6667 17.6629 10.875 19.0504 8.625 19.8004C8.525 19.8337 8.425 19.8587 8.325 19.8754C8.225 19.8921 8.11667 19.9004 8 19.9004Z"
        fill={isActive ? "white" : "#003E6E"} />
    </svg>

  }


  return sidebarIcons[iconName]

}

const CoacheLayout = ({
  children,
  withoutPadding,
  props,
}) => {

  const { classes } = useStyles();
  const location = useLocation();
  const currentPage = location.pathname.split('/')[1];
  const resources = useResourceDefinitions();
  const sibebarLinks = useMemo(() => [
    ...Object.values(resources).filter(name => name.hasList).map(item => ({
      ...item,
      text: item.name,
      isMenuItemLink: true,
      isCurrentPage: currentPage === item.name,
      icon: gerSidebarIcons({ isActive: currentPage === item.name, iconName: item.name })
    })),
  ], [currentPage, resources])
  const { isLoading, identity } = useGetIdentity();
  const [width] = useWindowSizes();
  const [isOpenSidebar, setIsOpenSidebar] = useState(width > 1535 ? true : false);
  const { data: coacheList } = useGetList("coaches", { pagination: { page: 1, perPage: 400 } });
  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{
        isOpenSidebar,
        setIsOpenSidebar,
        coaches: coacheList,
      }}>
        <Container
          className={classes.root}
          maxWidth={false}
        >
          <NewHeader userName={identity?.fullName} />
          <Box className={classes.appFrame}>
            <main className={`${classes.contentWithSidebar} ${!isOpenSidebar && classes.contentWithSidebarHidenSidebar}`}>
              <NewSidebar
                props={{
                  height: "100%",
                }}
              >
                {sibebarLinks.map((item) => <SidebarLink key={item.text} {...item} />)}
              </NewSidebar>
              <Content withoutPadding={withoutPadding}>{children}</Content>
            </main>
          </Box>
        </Container>
      </AppContext.Provider>


    </ThemeProvider>
  );
};

export default CoacheLayout;