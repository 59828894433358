import React from 'react'
import WelcomeTitle from '../../common/WelcomeTitle';
import { useStylesAssessments } from '../../assessments/LIARecordPage';
import { Box, Button } from '@mui/material';
import { useRedirect, useRecordContext } from 'react-admin';

export const AssessmentsHeader = ({
  title,
  userName,
  subtitle,
  whithButtonBack,
  whithCompareButton
}) => {
  const { classes: classes2 } = useStylesAssessments();
  const record = useRecordContext();
  const redirect = useRedirect();
  return (
    <Box className={classes2.header}>
      <WelcomeTitle
        whithButtonBack={whithButtonBack}
        title={title}
        userName={userName}
        subtitle={subtitle}
      />
      {whithCompareButton && <Button
        // variant="contained"
        // color="primary"
        className={classes2.actions_field_button}
        onClick={(e) => {
          e.stopPropagation();
          redirect(`/clients/compare/${record.id}`)
        }}
      >
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H7V22H9V0H7V2ZM7 17H2L7 11V17ZM16 2H11V4H16V17L11 11V20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2Z" fill="white" />
        </svg>

        Compare Progress
      </Button>}
    </Box>
  )
}
