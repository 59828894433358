import react, { useEffect } from "react"
import PDFHeader from "./PDFHeader";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';

import { pdfStyles } from "./pdfstyle"
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';
import PDFFooter from "./PDFFooter";
import { TotalGraph } from "./Graph";
import categories from "../../resources/categories";
import quadrants from "../../resources/quadrants";



export const PDFPersonalize = (props) => {
  const { lists, theme, rating } = props;

  return (
    <View>
      <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
      <View style={pdfStyles.main_page}>
        <View style={pdfStyles.title_text_30}>
          <Text>Your Personalized Life Map</Text>
        </View>
        <View>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignItems: 'center' }}>
            <Text style={pdfStyles.text_font_16_bold}>{props.firstName}{", "}</Text>
            <Text style={pdfStyles.text_font_12}>&nbsp;congratulations on completing your Life Inventory Assessment.</Text>
          </View>
          <View style={{ marginTop: '16px' }}>
            <Text style={pdfStyles.text_font_13_bold}>Review Your Life Map</Text>
            <Text style={[pdfStyles.text_font_12, { marginTop: '8px' }]}>
              Your Life Map gives you a full perspective of your life at this moment in time. With this perspective, and in using the Illumination Insights below, you can make decisions that bring your life into alignment with who you are and what you desire.</Text>
          </View>
        </View>

        <View sx={{ marginTop: '32px', marginBottom: '12px', width: '100%', textAlign: 'center' }}>
          <Text style={pdfStyles.text_font_12_bold}>
            size of bubble = total health of life category
          </Text>
        </View>
        <TotalGraph lists={lists} rating={rating} theme={theme} />
        <View sx={{ display: 'flex', flexDirection: 'row', marginTop: "4px" }}>
          <Text style={[pdfStyles.text_font_12_bold]}>Note:{" "}</Text>
          <Text style={pdfStyles.text_font_12}>The further to the left a life category, the more unfulfilled it is.</Text>
        </View>
      </View>
      <PDFFooter />
    </View>
  )
}

export default PDFPersonalize;