import * as XLSX from 'xlsx';

const formatDate = (date) => {
  return date ? new Date(date).toISOString().split('T')[0] : '';
};

export const exportClientsToXLSX = async (data) => {
  try {
    // Use the provided clients data directly
    const clients = data;

    // Prepare data for "Clients" worksheet
    const clientsData = clients.map((client) => ({
      ID: client.id,
      'First Name': client.firstName,
      'Last Name': client.lastName,
      Email: client.email,
      Created: formatDate(client.created_at),
    }));

    // Prepare data for "Survey Detail" worksheet
    const surveyData = clients.flatMap((client) =>
      (client.Surveys || []).map((survey) => ({
        ID: client.id,
        'First Name': client.firstName,
        'Last Name': client.lastName,
        Email: client.email,
        Created: formatDate(client.created_at),
        'Survey ID': survey.id,
        'Survey Date': formatDate(survey.created_at),
        'Coach ID': client.coach_id,
      }))
    );

    // Create workbook and add worksheets
    const wb = XLSX.utils.book_new();

    // Helper function to calculate column widths
    const autoFitColumns = (json) => {
      const columnWidths = {};

      // Get all possible keys from the JSON data
      const keys = Object.keys(json[0] || {});

      // Initialize with header widths
      keys.forEach(key => {
        columnWidths[key] = key.length;
      });

      // Check cell contents for maximum width
      json.forEach(row => {
        keys.forEach(key => {
          const cellValue = String(row[key] || '');
          columnWidths[key] = Math.max(columnWidths[key], cellValue.length);
        });
      });

      return columnWidths;
    };

    // Create and format "Clients" worksheet
    const ws1 = XLSX.utils.json_to_sheet(clientsData);
    const clientsColumnWidths = autoFitColumns(clientsData);
    ws1['!cols'] = Object.keys(clientsColumnWidths).map(key => ({
      wch: clientsColumnWidths[key] + 2 // Add padding
    }));
    XLSX.utils.book_append_sheet(wb, ws1, 'Clients');

    // Create and format "Survey Detail" worksheet
    const ws2 = XLSX.utils.json_to_sheet(surveyData);
    const surveyColumnWidths = autoFitColumns(surveyData);
    ws2['!cols'] = Object.keys(surveyColumnWidths).map(key => ({
      wch: surveyColumnWidths[key] + 2 // Add padding
    }));
    XLSX.utils.book_append_sheet(wb, ws2, 'Survey Detail');

    // Generate and download file
    XLSX.writeFile(wb, `clients.xlsx`);
  } catch (error) {
    console.error('Export failed:', error);
    throw error;
  }
};
