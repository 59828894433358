import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import { Stack } from '@mui/material';
import { useWindowSizes } from '../../hooks/useWindowSize';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#C4C4C4',
    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    // color: 'linear-gradient(0.25turn, #003E6E, #000F0F)',
    color: 'blue',
    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  circle: {
    strokeLinecap: 'round',
    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  },
  percentage: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#003E6F',
    [theme.breakpoints.down('xl')]: {
      fontSize: '24px',
    },
  },
}));

export default function CircularProgressWithLabel({ value, color, isCoach }) {
  const { classes } = useStyles();
  const label = `${Math.round(value)}%`;
  const [width] = useWindowSizes();
  const size = width > 1535 ? 128 : 100;

  return (
    <Box
      position="relative"
      display="inline-flex"
      width={size}
      height={size}
    >
      <Box>
        <CircularProgress
          variant="determinate"
          className={`${classes.bottom} ${isCoach && classes.bottom_is_coach}`}
          size={size}
          thickness={6}
          value={100}
        />
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#0288F0" />
              <stop offset="100%" stopColor="#003E6E" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          variant="determinate"
          className={`${classes.top} ${isCoach && classes.top_is_coach}`}
          classes={{
            circle: classes.circle,
            circle_is_coach: isCoach && classes.circle_is_coach,
          }}
          sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
          // style={{ color: color }}
          size={size}
          thickness={6}
          value={value}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.percentage}
        // style={{ color: color }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};