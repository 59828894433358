import React, { useEffect, useRef, useState, useMemo } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, MenuItem, Link } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, withStyles } from 'tss-react/mui';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Logo from '../common/Logo';
import Moment from "react-moment";
import LogoutButton from "../../pages/LogoutButton";
import { useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';

import { useTheme } from '@mui/material';

import PDFPersonalize from '../pdf/PDFPersonalLize';
import InventoryAssessment from '../pdf/InventoryAssessment';
import HowtoReady from '../pdf/HowtoReady';
import ZonePage from '../pdf/ZonePage';

import LastPage from '../pdf/LastPage';
import ZonePageMain from '../pdf/ZonePageMain';


const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors.wildSand,
    boxShadow: '0px 3px 6px #00000029',
  },
  title: {
    fontSize: '22px',
    marginBottom: '30px',
  },
  menuItem: {
    backgroundColor: theme.colors.white,
    borderBottom: '1px solid #00000020',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  menuItemDisabled: {
    opacity: '0.6',
    pointerEvents: 'none'
  },
  subMenuItem: {
    padding: theme.spacing(1),

    '&:not(:first-of-type)': {
      borderTop: '1px solid #00000020',
    },
  },
  menuButton: {
    padding: theme.spacing(1),

    '& > p': {
      padding: theme.spacing(0, 1),
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: `rgba(0, 0, 0, 0.04)`,
      },
    },
  },
  link: {
    padding: theme.spacing(1),
    cursor: 'pointer',

    '&.Mui-selected': {
      pointerEvents: 'none',
      backgroundColor: theme.colors.loblolly,
    },
  },
  accordionDetails: {
    maxHeight: '500px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
  }
}));

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    minHeight: '54px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
    [`&:not(.${classes.expanded}) .MuiIconButton-label`]: {
      transform: 'rotate(90deg)',
    },
  },
  expanded: {},
}));

const AccordionSummary = withStyles(MuiAccordionSummary, (theme, _params, classes) => ({
  root: {
    backgroundColor: theme.colors.white,
    minHeight: 56,

    '& .MuiAccordionSummary-expandIcon': {
      padding: theme.spacing(0.5),
    },

    [`&.${classes.expanded}`]: {
      backgroundColor: theme.colors.iron,
      minHeight: 56,
    },
  },
  content: {
    margin: theme.spacing(1, 0),

    [`&.${classes.expanded}`]: {
      margin: theme.spacing(1, 0),
    },
  },
  expanded: {},
}));

const AccordionDetails = withStyles(MuiAccordionDetails, (theme) => ({
  root: {
    flexDirection: 'column',
    padding: 0,
  },
}));




const ClientSidebar = ({ userId, surveys, handlePlayInstructions, handleChangeCoach, props }) => {

  const currentRef = useRef(null);
  const { classes } = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const dataProvider = useDataProvider();
  const assessmentId = useParams().assessmentId;
  const [isDataFetched, setDataFetched] = React.useState(true);

  const [lists, setLists] = useState([]);

  const [actionList, setActionList] = useState([]);
  const [flowList, setFlowList] = useState([]);
  const [easeList, setEaseList] = useState([]);
  const [watchList, setWatchList] = useState([]);

  const [zoneStatusArray, setZoneStatusArray] = useState([]);
  const [ratingData, setRatingData] = useState([]);

  const [percentage, setPercentage] = useState(0);

  const record = useRecordContext();

  const firstName = record.firstName;
  const resultsDate = surveys.find(item => `${item.id}` === assessmentId)?.created_at || new Date();

  const zoneStatus = ['happiness', 'emotional', 'capabilities', 'engagement', 'time'];

  const [isButtonClicked, setButtonClicked] = useState(false);
  const [pageNumbers, setPageNumber] = useState({
    actionList: [],
  });

  const ReportPDF = (props) => (
    <Document onRender={(e) => { }}>

      <Page wrap size="A4">
        <PDFPersonalize rating={ratingData} resultsDate={resultsDate} firstName={firstName} lists={lists} theme={theme} />
      </Page>
      <Page wrap size="A4">
        <InventoryAssessment percentage={percentage} resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <Page wrap size="A4">
        <HowtoReady resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={watchList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      {/* <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} /> */}

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={lists} rating={ratingData} firstName={firstName} />

      <Page wrap size="A4">
        <LastPage lists={lists} zone_ratings={ratingData} title="Ease" resultsDate={resultsDate} firstName={firstName} />
      </Page>

    </Document>
  );

  const pdfLink = useMemo(() => {
    return (
      <PDFDownloadLink
        sx={{
          textDecoration: 'none',
          color: 'black',
          marginTop: '2px',
          textAlign: "center"
        }}
        document={<ReportPDF id={assessmentId} />}
        fileName={`${firstName} - Life Map - ${(new Date()).toLocaleString().split(',')[0]}.pdf`}>
        {({ blob, url, loading, error }) =>
          loading ? 'Loading ...' : 'Download Report'
        }
      </PDFDownloadLink>
    );
  }, [isButtonClicked]);

  // useEffect(() => {
  //   if (currentRef.current === true) return;

  //   currentRef.current = true
  //   async function fetchData() {
  //     if (assessmentId) {

  //       const { data: response } = await dataProvider.getOne('assessments', { id: assessmentId });
  //       const updatedRatingData = await Promise.all(response.SurveyResults.map(async (data, index) => {
  //         const res = await dataProvider.getOne(`assessments/${assessmentId}/categories`, { id: data.selector });
  //         const updatedRatings = {
  //           ratings: res.data.ratings,
  //           percentage: res.data.health_percentage,
  //           selector: res.data.category
  //         }

  //         return updatedRatings
  //       }))
  //       setRatingData(updatedRatingData)

  //       let newArray;
  //       try {
  //         newArray = await Promise.all(response.SurveyResults.map(async (data) => {
  //           return {
  //             category: data.selector, data:
  //               await Promise.all(zoneStatus.map(async (element) => {
  //                 const res = await dataProvider.getOne(
  //                   `assessments/${assessmentId}/categories/${data.selector}/element`,
  //                   { id: element }
  //                 );
  //                 return res;
  //               }))
  //           }
  //         }));
  //       } catch (error) {
  //         console.error("An error occurred:", error);
  //       }

  //       setZoneStatusArray(newArray);

  //       setPercentage(response.total_life_score)

  //       setLists(enchanceSurveyResults(response.SurveyResults));

  //       enchanceSurveyResults(response.SurveyResults) &&
  //         enchanceSurveyResults(response.SurveyResults).map(data => {
  //           switch (data.quadrant) {
  //             case "action":
  //               setActionList((previewActionList) => [...previewActionList, data]);
  //               break;
  //             case "flow":
  //               setFlowList((previewFlowList) => [...previewFlowList, data]);
  //               break;
  //             case "watch":
  //               setWatchList((previewWatchList) => [...previewWatchList, data]);
  //               break;
  //             case "ease":
  //               setEaseList((previewEaseList) => [...previewEaseList, data]);
  //               break;
  //           }
  //         })
  //       setDataFetched(true)
  //     }
  //   }

  //   fetchData().finally(() => { currentRef.current = null });

  // }, []);

  const onFetchData = async (data) => {
    if (currentRef.current === true) return;

    currentRef.current = true
    async function fetchData() {

      if (assessmentId) {
        setDataFetched(false)
        const { data: response } = await dataProvider.getOne('assessments', { id: assessmentId });
        const updatedRatingData = await Promise.all(response.SurveyResults.map(async (data, index) => {
          const res = await dataProvider.getOne(`assessments/${assessmentId}/categories`, { id: data.selector });
          const updatedRatings = {
            ratings: res.data.ratings,
            percentage: res.data.health_percentage,
            selector: res.data.category
          }

          return updatedRatings
        }))
        setRatingData(updatedRatingData)

        let newArray;
        try {
          newArray = await Promise.all(response.SurveyResults.map(async (data) => {
            return {
              category: data.selector, data:
                await Promise.all(zoneStatus.map(async (element) => {
                  const res = await dataProvider.getOne(
                    `assessments/${assessmentId}/categories/${data.selector}/element`,
                    { id: element }
                  );
                  return res;
                }))
            }
          }));
        } catch (error) {
          console.error("An error occurred:", error);
        }

        setZoneStatusArray(newArray);

        setPercentage(response.total_life_score)

        setLists(enchanceSurveyResults(response.SurveyResults));

        enchanceSurveyResults(response.SurveyResults) &&
          enchanceSurveyResults(response.SurveyResults).map(data => {
            switch (data.quadrant) {
              case "action":
                setActionList((previewActionList) => [...previewActionList, data]);
                break;
              case "flow":
                setFlowList((previewFlowList) => [...previewFlowList, data]);
                break;
              case "watch":
                setWatchList((previewWatchList) => [...previewWatchList, data]);
                break;
              case "ease":
                setEaseList((previewEaseList) => [...previewEaseList, data]);
                break;
            }
          })
        setDataFetched(true)
      }
    }

    fetchData().finally(() => {
      setButtonClicked(true);

      currentRef.current = null;
    });

  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      {...props}
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={124}
        >
          <Logo width={114} />
        </Box>
        <Box>
          <Box
            className={classes.menuItem}
          >
            <Accordion
              expanded={expanded === 'lifeMapResults'}
              onChange={handleChange('lifeMapResults')}
            >
              <AccordionSummary
                aria-controls="lifeMapResultsbh-content"
                id="lifeMapResultsbh-header"
                expandIcon={<ArrowDropUpIcon />}
              >
                <Typography>LIA Results</Typography>
              </AccordionSummary>
              <AccordionDetails
                className={classes.accordionDetails}
              >
                {surveys.map((survey, key) => (
                  <Box
                    key={key}
                    className={classes.subMenuItem}
                  >
                    {(() => {
                      const pathname = `/client/${userId}/assessments/${survey.id}`;
                      const isCurrent = pathname === window.location.pathname;

                      return (
                        <MenuItem
                          component={Link}
                          className={classes.link}
                          href={pathname}
                          display="block"
                          selected={isCurrent}
                        >
                          <Moment format="DD/MM/YYYY">
                            {survey?.created_at}
                          </Moment>
                        </MenuItem>
                      )
                    })()}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            className={`${classes.menuItem} ${classes.menuButton}`}
            onClick={handlePlayInstructions}
          >
            <Typography>Instruction Video</Typography>
          </Box>
          <Box
            className={`${classes.menuItem} ${classes.menuButton}`}
            onClick={handleChangeCoach}
          >
            <Typography>LIA Coach</Typography>
          </Box>

          <Box
            className={`${classes.menuItem} ${classes.menuButton} ${!isDataFetched && classes.menuItemDisabled}`}
            onClick={() => setButtonClicked(true)}

          >
            <Typography sx={{ color: 'black', textDecoration: 'none' }}>
              Generate Report
            </Typography>

            {/* {isDataFetched &&
              <Typography sx={{ color: 'black', textDecoration: 'none' }}>
                Generate Report
              </Typography>
            } */}
            {isButtonClicked && pdfLink}
            {/* {!isDataFetched &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            } */}

          </Box>

        </Box>

      </Box>
      <Box>
        <LogoutButton
          marginTop="auto"
          component={Box}
        />
      </Box>
    </Box >
  );
};

export default ClientSidebar

