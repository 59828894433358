import React, { useEffect } from "react";
import {
  EditButton,
  TopToolbar,
  Show,
  usePermissions,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { roles } from "../../config";
import ClientLayout from '../../layouts/ClientLayout';

const ClientShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ClientTitle = ({ record }) => {
  return <div>Client {record ? `"${record.firstName}"` : ""}</div>;
};

export const ClientShow = props => {
  const { permissions } = usePermissions();
  const isCoach = permissions === roles.ADMIN || permissions === roles.COACH;
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetch = async () => {
      const { data: response } = await dataProvider.getOne('clients', { id: 976 });
      console.log(response)
    }

    fetch()

  }, [dataProvider]);

  // https://lia-life-inventory-staging.herokuapp.com/api/v1/clients/976

  return (
    <Show
      {...props}
      component="div"
      title={<ClientTitle />}
      actions={isCoach ? <ClientShowActions /> : <div />}
      hasList={isCoach}
      hasEdit={isCoach}
    >
      {/* <ClientLayout> */}
      {props.children}
      {/* </ClientLayout> */}
    </Show>
  );
};