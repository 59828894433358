import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfstyle';
import categories from '../../../resources/categories';
export const TotalGraph = (props) => {
  const { lists, rating, theme } = props;
  const width = 30;
  const max = Math.max(...lists.map(item => item.z));
  const min = Math.min(...lists.map(item => item.z));
  const parsedSeries = lists.map((point, index) => {
    const category = categories.find((category) => category.selector === point.selector) || {};
    const rate = rating.find((rate) => rate.selector === point.selector)
    return {
      x: point.x,
      y: point.y,
      z: point.z,
      percentage: 65 + ((point.z - min) * (125 / max)),
      selector: point.selector,
      name: `${index + 1}. ${category.name}`,
      color: theme.colors.quadrantTypes[point.quadrant],
    };
  })


  return (
    <View style={{
      backgroundColor: '#F6FBFA', width: '100%', height: '428px', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '12px', paddingRight: '12px', display: 'flex', justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Image style={{ position: 'absolute', left: '0px', top: '170px', width: 'auto', height: '120px', marginLeft: '12px' }} src={'/self-rank-2.png'} />
      <Image style={{ position: 'absolute', bottom: '0px', left: '250px', width: '80px', height: 'auto' }} src={'/fullfillment-2.png'} />
      <View style={{ backgroundColor: 'white', width: '450px', height: '100%' }}>
        <Text style={[pdfStyles.text_font_12_Inter, { position: 'absolute', color: "#FF551C", left: '4px', top: '4px' }]}>Action</Text>
        <Text style={[pdfStyles.text_font_12_Inter, { position: 'absolute', color: "#02CAD6", right: '4px', top: '4px' }]}>Flow</Text>
        <Text style={[pdfStyles.text_font_12_Inter, { position: 'absolute', color: "#7910E9", right: '4px', bottom: '4px' }]}>Ease</Text>
        <Text style={[pdfStyles.text_font_12_Inter, { position: 'absolute', color: "#FBD100", left: '4px', bottom: '4px' }]}>Watch</Text>
        <View style={{ position: 'absolute', backgroundColor: 'grey', left: '273px', top: '4px', width: '1px', height: '380px', zIndex: -1 }} />
        <View style={{ position: 'absolute', backgroundColor: 'grey', left: '4px', top: '200px', width: '440px', height: '1px', zIndex: -1 }} />
        {parsedSeries.map(((point, index) => (
          <>
            <View key={point}
              style={{
                position: 'absolute',
                width: (2 * width * point.percentage) / 100,
                height: (2 * width * point.percentage) / 100,
                left: point.x * width + 2 * width * (max / 2 - point.percentage / 2) / 100,
                textAlign: 'center',
                top: 70 + ((14 - point.y) * width * 2) / 3 - 2 * width * ((point.percentage / 2)) / 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 10,
                  backgroundColor: point.color,
                  borderRadius: '100%',
                  opacity: 0.5,
                  zIndex: 1,
                  width: '100%',
                }}
              >

              </View>
              <Text
                style={{
                  ...pdfStyles.text_font_6_Inter,
                  color: 'black',
                  zIndex: 999,
                }}
              >
                {point.name}
              </Text>

            </View>
          </>
        )))}
      </View>
    </View>
  )
}

export default TotalGraph;