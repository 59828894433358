import React, { useEffect } from "react";
import {
  useDataProvider,
  useRedirect,

} from "react-admin";
import { useNavigate } from "react-router-dom";
import FullscreenLoading from '../common/FullscreenLoading';

export const ClientLoading = props => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const navigate = useNavigate();

  useEffect(() => {
    dataProvider.getList('clients',
      { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'DESC' }, filter: {} }
    ).then(({ data }) => {
      const [user] = data;
      const survey = user.Surveys[user.Surveys.length - 1];
      navigate(`/client/${user.id}/assessments/${survey.id}`);
    }).catch((e) => {
      // redirect('/login');
    });
  }, []);

  return (
    <FullscreenLoading />
  );
}

export default ClientLoading;