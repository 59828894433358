import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { Link } from "react-router-dom";
import { useRedirect } from 'react-admin';

const useStyles = makeStyles()((theme) => ({
  root: {
    [theme.breakpoints.down('xl')]: {
      backgroundColor: 'transparent',
      padding: '0',
    },
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    letterSpacing: 0,
    display: 'flex',
    gap: '8px',
    color: '#003E6E',
    [theme.breakpoints.down('xl')]: {
      fontSize: '24px',
    },

  },
  name: {
    fontSize: '30px',
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    letterSpacing: 0,
    color: '#003E6E',
    [theme.breakpoints.down('xl')]: {
      fontSize: '24px',
    },
  },
  subtitle: {
    marginTop: '8px',
    fontSize: '22px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },
  },
  button_back: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    padding: 0,
    '&:hover': {
      background: 'transparent',

    }
  }
}));

const WelcomeBox = (props) => {
  const { classes } = useStyles();
  const { whithButtonBack } = props
  const redirect = useRedirect()
  // const translate = useTranslate();

  return (
    <Box
      className={classes.root}
    >
      <Typography
        className={`${classes.title} ${props.className || ''}`}
        variant="h2"
      >
        {whithButtonBack && <button onClick={() => {
          redirect(-1)
        }} className={classes.button_back}>
          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6666 15.1671V17.8338H10.6666L18 25.1671L16.1066 27.0604L5.54663 16.5004L16.1066 5.94043L18 7.83376L10.6666 15.1671H26.6666Z" fill="#232323" />
          </svg>
        </button>}

        {props.title}
        {' '}

        <Typography
          className={`${classes.name} ${props.className || ''}`}
          variant="h2"
        >
          {props.userName}
        </Typography>
      </Typography>
      <Typography
        className={`${classes.subtitle} ${props.className || ''}`}
        variant="h3"
      >
        {props.subtitle}
      </Typography>
    </Box>
  );
};

export default WelcomeBox;