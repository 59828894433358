import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';
import { pdfStyles } from "./pdfstyle";

export const InventoryAssessment = (props) => {
  return (
    <View>
      <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
      <View style={pdfStyles.main_page}>

        <View style={pdfStyles.title_text_30}>
          <Text>Life Inventory Assessment</Text>
        </View>
        <Text style={pdfStyles.text_font_12}>
          LIA takes a measure of the three most impacting dimensions that determine fulfillment in your life:
        </Text>
        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
          <Text style={pdfStyles.text_font_12_bold}>{" "} 1. Engagement: {" "}</Text>
          <Text style={pdfStyles.text_font_12}>How you show up when you show up.</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={pdfStyles.text_font_12_bold}>{" "} 2. Capabilities: {" "}</Text>
          <Text style={pdfStyles.text_font_12}>Your skills, knowledge, abilities, and resources.</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={pdfStyles.text_font_12_bold}>{" "} 3. Engagement: {" "}</Text>
          <Text style={pdfStyles.text_font_12}>Your commitment, the relative time you invest.</Text>
        </View>

        <View>
          <Text style={{ ...pdfStyles.text_font_13_bold, marginTop: '8px' }}>Life Score Results</Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={pdfStyles.text_font_12}>
            Your total life score represents your average level of fulfillment across all 13 categories. Your current Total Life
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={pdfStyles.text_font_12}>Score is{" "}{props.percentage} {"%"}</Text>
        </View>
        <View>
          <Text style={[pdfStyles.text_font_12, { marginTop: '24px', marginBottom: '16px' }]}>
            In the Illumination Report that follows, you'll be given insights so that you can begin to explore each of these dimensions in detail. Take your time as you go through and practice radical compassion. Choose the actions that resonate most with you, you are the expert of your life.
          </Text>
          <Text style={[pdfStyles.text_font_12, { marginBottom: '16px' }]}>
            I have worked closely with psychologist, emotional expert and founder of the Bio-Emotive Framework, Dr Doug J. Tataryn, to provide insights developed from Dr. Tataryn's work from over 30 years of study and clinical practice. The Life Map and the Illumination Insights below represent a system for radical self-reflection designed to get you the furthest, the fastest, so that you live your best life. It is highly recommended that you review your Life Map with a LIA Certified Coach.
          </Text>
          <Text style={[pdfStyles.text_font_12, { marginBottom: '16px' }]}>
            We honor you and your pursuit of living of your full life. Our mission is to impact a million lives by helping people to see themselves with clarity so that they can be themselves with confidence because when that happens, the whole world wins.
          </Text>

          <Text style={[pdfStyles.text_font_12, { marginBottom: '16px' }]}>Live Your Best Life,</Text>
          <Text style={[pdfStyles.text_font_12, { color: 'blue' }]}>Maurice Thibodeau</Text>
          <Text style={pdfStyles.text_font_12}>Illumination Experiences-Founder</Text>
          <Text style={pdfStyles.text_font_12}>Creator of the Life Illumination Assessment(LIA)</Text>
          <Text style={pdfStyles.text_font_12}>and the Life Illumination System(LIS)</Text>

          <View style={{ margin: '24px', padding: '24px', textAlign: 'center' }}>
            <Text style={pdfStyles.text_font_14_italic}>"Don't ask what the world needs. Ask what makes you come alive.</Text>
            <Text style={pdfStyles.text_font_14_italic}>and go do it. Because what the world needs is people who have come alive."</Text>
            <Text style={pdfStyles.text_font_14_italic}>Howard Thurman - mystic and Theologian</Text>
          </View>
          <Text style={pdfStyles.text_font_12_bold}>P.S {" "}It is no-one's business to tell you what
            your happiness should or should not be. Ultimately you know what makes you most fulfilled,
            and over time, your wisdom and preferences will become more clear. LIA
            is a relative framework to serve your growing perspective on what happiness is and how you can best achieve it.
          </Text>
        </View>
      </View>
      <PDFFooter />
    </View>
  )
}


export default InventoryAssessment;