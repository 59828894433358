import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';
import { pdfStyles, color_action, color_ease, color_flow, color_watch } from "./pdfstyle";

export const HowtoReady = (props) => {
  return (
    <View>
      <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
      <View style={pdfStyles.main_page}>
        <Text style={pdfStyles.text_font_24_bold}>How to Read Your Life Map</Text>
        <View style={{ marginTop: '24px' }}>
          <Text style={pdfStyles.text_font_12_bold}>1. Fulfillment (X-axis)</Text>
          <Text style={[pdfStyles.text_font_12, { marginTop: '10px' }]}>
            Fulfillment is the X-axis which runs left to right. Fulfillment represents your degree of overall life satisfaction.
            The further to the right, the higher your life satisfaction, the further to the left, the lower your life satisfaction for that category.
          </Text>
        </View>
        <View style={{ marginTop: '16px' }}>
          <Text style={pdfStyles.text_font_12_bold}>2. Self-Ranked Priorities (Y-axis)</Text>
          <Text style={[pdfStyles.text_font_12, { marginTop: '10px' }]}>
            Self-Ranked Priority is the Y-axis which runs top to bottom, Self-ranked Priority represents the order that you ranked your life categories, from highest (#1, top) to lowest (#13, bottom).
          </Text>
        </View>

        <View style={{ marginTop: '32px', display: 'flex', flexDirection: 'col' }}>

          <View style={{ width: '100%', height: "30px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[pdfStyles.text_font_13_bold, { paddingRight: "8px", paddingLeft: '8px', width: '55px', textAlign: 'center' }]}>Zones</Text>
            <View style={{ height: '30px', width: '1px', backgroundColor: '#E7E7E7' }}></View>
            <Text style={[pdfStyles.text_font_13_bold, { paddingLeft: "8px" }]}>Description</Text>
          </View>
          <View style={{ width: '100%', height: '1px', marginTop: '8px', marginBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

          <View style={{ width: '480px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ display: 'flex', width: '55px', flexDirection: 'row', justifyContent: 'space-between', lignItems: 'center', paddingLeft: '4px', paddingRight: '4px', alignItems: 'center' }}>
              <View style={{ backgroundColor: color_action, width: '12px', height: '12px' }} />
              <Text style={[pdfStyles.text_font_12]}>Action</Text>
            </View>
            <View style={{ height: '100%', width: '1px', paddingTop: '8px', paddingBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

            <View style={[pdfStyles.text_font_13_bold, { paddingLeft: "8px", display: 'flex', flexDirection: 'column' }]}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={pdfStyles.text_font_12}>
                  Take Actions:  focus your energy on moving these most important categories to total fulfillment.
                </Text>
              </View>
              <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12}>
                    Focus: focus your vital energy (your limited time and money) on doing what matters most: this
                    zone represents your highest priority categories; they deserve to be in total abundance.
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12}>Ask: what do I need to support me in achieving my highest level of fulfillment in this area.</Text>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12}>Seek help and build resources: consider how you can build your capabilities, what do you need need to learn, who can you lean in to?</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: '100%', height: '1px', marginTop: '8px', marginBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

          <View style={{ width: '480px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ display: 'flex', width: '55px', flexDirection: 'row', justifyContent: 'space-between', lignItems: 'center', paddingLeft: '4px', paddingRight: '4px', alignItems: 'center' }}>
              <View style={{ backgroundColor: color_flow, width: '12px', height: '12px' }} />
              <Text style={[pdfStyles.text_font_12]}>Flow</Text>
            </View>
            <View style={{ height: '100%', width: '1px', paddingTop: '8px', paddingBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

            <View style={[pdfStyles.text_font_13_bold, { paddingLeft: "8px", display: 'flex', flexDirection: 'column' }]}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={pdfStyles.text_font_12_bold}>Celebrate: {" "}</Text>
                <Text style={pdfStyles.text_font_12}>
                  your abundance
                </Text>
              </View>
              <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12}>Ask: what can I learn here and apply in other areas of my life.</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12}>Please keep doing: what you are doing; it's working.</Text>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12}>Share and serve: what you've learned; we are meant to share,
                      and when we do, our abundance multiplies; how can you this should
                      say how can you help others achieve the same level of abundance.

                    </Text>
                  </View>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12}>
                      Pay Gratitude: for the flow that you are experiencing
                      pay attention to all the factors and people that have supported you to get here.

                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: '100%', height: '1px', marginTop: '8px', marginBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

          <View style={{ width: '480px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ display: 'flex', width: '55px', flexDirection: 'row', justifyContent: 'space-between', lignItems: 'center', paddingLeft: '4px', paddingRight: '4px', alignItems: 'center' }}>
              <View style={{ backgroundColor: color_ease, width: '12px', height: '12px' }} />
              <Text style={[pdfStyles.text_font_12]}>Ease</Text>
            </View>
            <View style={{ height: '100%', width: '1px', paddingTop: '8px', paddingBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

            <View style={[pdfStyles.text_font_13_bold, { paddingLeft: "8px", display: 'flex', flexDirection: 'column' }]}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={pdfStyles.text_font_12_bold}>Celebrate: your abundance</Text>
              </View>
              <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12}>Ask: what can I learn here and apply in other areas of my life.</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={pdfStyles.text_font_12_bold}>Please keep doing: what you are doing; it's working.</Text>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12_bold}>Share and serve: what you've learned; we are meant to share, and when we do, our abundance multiplies; how can you</Text>
                  </View>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12_bold}>help others: achieve the same level of abundance?</Text>
                    <Text style={pdfStyles.text_font_12}></Text>
                  </View>
                </View>
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={pdfStyles.text_font_12_bold}>Pay Gratitude: for the flow that you are experiencing pay attention to all the factors and people that have supported you to get here.</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: '100%', height: '1px', marginTop: '8px', marginBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

          <View style={{ width: '480px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ display: 'flex', width: '55px', flexDirection: 'row', justifyContent: 'space-between', lignItems: 'center', paddingLeft: '4px', paddingRight: '4px', alignItems: 'center' }}>
              <View style={{ backgroundColor: color_watch, width: '12px', height: '12px' }} />
              <Text style={[pdfStyles.text_font_12]}>Watch</Text>
            </View>
            <View style={{ height: '100%', width: '1px', paddingTop: '8px', paddingBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

            <View style={[pdfStyles.text_font_13_bold, { paddingLeft: "8px", display: 'flex', flexDirection: 'column' }]}>
              <Text style={pdfStyles.text_font_12_bold}>Pay Attention: {" "}</Text>
              <Text style={pdfStyles.text_font_12}>
                these aren't your most important categories, yet all things are connected, so 'watch' these categories.
                The message here is not to try to do everything; your energies are likely better spent on items in your action zone...BUT you'll know best.

              </Text>
              <Text style={pdfStyles.text_font_12_bold}>Look for Easy Wins:</Text>
              <Text style={pdfStyles.text_font_12}>what are the small things that you can change, start doing, stop doing, that doesn't take up a lot of your time and would help move these categories into greater ease.

              </Text>
            </View>
          </View>
          <View style={{ width: '100%', height: '1px', marginTop: '8px', marginBottom: '8px', backgroundColor: '#E7E7E7' }}></View>

        </View>
      </View>
      <PDFFooter />
    </View>
  )
}

export default HowtoReady;