import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Loading } from "ra-ui-materialui";
import { useGetList, usePermissions } from 'react-admin';
import { roles } from "../../config";
import { makeStyles } from 'tss-react/mui';

import Notes from './Notes';
const useStyles = makeStyles()((theme) => ({
  notes: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '24px',
  },
  comments: {
    display: 'flex',
    gap: '16px',
    paddingBottom: '24px',
  },
  comments_text: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  comments_title: {
    color: '#003E6E',
    fontSize: '20px',
    fontWeight: '600',
  },
  comments_subtitle: {
    color: '#667085',
    fontSize: '12px'
  },
  comments_comments: {
    color: '#003E6E',
    fontSize: '18px',
    margin: '6px 0 6px auto'
  },
}));

export const NotesIterator = ({ props, assessmentId, category, autosave }) => {
  const { classes } = useStyles()
  const { isLoadingPerm, permissions } = usePermissions();
  // if (!loaded) return null;

  const isCoach = permissions === roles.ADMIN || permissions === roles.COACH;

  const { data, total, isLoading, error } = useGetList(
    `assessments/${assessmentId}/categories/${category}/notes`,
    {
      pagination: { page: 1, perPage: 10 },
      // sort: { field: 'created_at', order: 'DESC' }
    }
  );



  return isLoading
    ? (<Loading />) :
    (
      <>
        <Box className={classes.comments}>
          <Box className={classes.comments_text}>
            <Typography className={classes.comments_title}>Comments</Typography>
            <Typography className={classes.comments_subtitle}>Summary reflections, general guidance and recommendations here.</Typography>

          </Box>
          <Typography className={classes.comments_comments}>
            {data?.length || 0} Comments
          </Typography>

        </Box>
        <Notes
          title={isCoach ? "Coach" : "Individuals"}
          description={
            isCoach ?
              "Add reflections, guidance, and recommendations here" :
              "Add reflections, context, and commitments here. Ideal for tracking your progress over time."

          }
          editable={true}
          assessmentId={assessmentId}
          source={`categories/${category}/notes`}
          autosave={autosave}
        />

        <Box className={classes.notes} >
          {data.map((note, index) => (
            <Notes
              editable={false}
              assessmentId={assessmentId}
              note={note}
              source={`categories/${category}/notes`}
              // isLast={index === data.length - 1}
              // // showStatus={showStatus}
              // reference={reference}
              key={note?.id || note.text}
            />
          ))}
        </Box>
      </>
    )
}


export default NotesIterator;