import React, { useState } from "react";
import {
  useAuthProvider,
  Notification,
  TextInput,
  required,
  useNotify,
  Form
} from "react-admin";

import { CssBaseline, Button, Link, Grid } from "@mui/material";

import { Typography, Box } from "@mui/material";
import red from "@mui/material/colors/red";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { createTheme } from "@mui/material/styles";
import Logo from '../common/Logo';

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
    minHeight: "100vh",
    position: "relative",
    maxWidth: '100%',
  },
  loginText: {
    textAlign: "center",
  },
  grid: { backgroundColor: "Lavender" }
}));

const myTheme = createTheme({
  palette: {
    primary: {
      main: "#182C3B"
    },
    secondary: {
      main: "#3694F9"
    },
    // background: {
    //     default: "#3694F9"
    // },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white" // Some CSS
      }
    }
  }
});

const ForgotPassword = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const [email, setEmail] = useState("");

  const { classes } = useStyles();
  const handleSubmit = async values => {
    try {
      if (email.trim() !== "") {
        await authProvider.forgot_password({
          email: email
        });
        notify("Email was sent succesfully.");
      }
    } catch (e) {
      console.log(e);
      notify(e.message, "warning");
    }
  };
  return (
    <ThemeProvider theme={createTheme(myTheme)}>
      <div className={classes.root}>
        <CssBaseline />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Form classes={classes.grid} onSubmit={handleSubmit}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Logo
                  height={100}
                />
                <Typography variant="subtitle1" className={classes.loginText}>
                  Reset your password
                </Typography>
                <TextInput
                  name="email"
                  source="email"
                  type="email"
                  value={email}
                  label="E-mail"
                  onChange={e =>
                    setEmail(e.target.value)
                  }
                  validate={[required()]}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Link
                    href="/login"
                    color="inherit"
                    variant="body2"
                  >
                    Back to Login
                  </Link>
                </Box>

                <Box mt="1em" />
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Send Reset Link
                  </Button>
                </Box>
                <Notification />
              </Box>
            </Form>
          </Grid>
        </Grid>



      </div>
    </ThemeProvider>
  );
};

export default ForgotPassword;
