function getTypeBySelector(selector) {
  const types = {
    innerIndividual: ['intellectual_creative', 'spiritual'],
    individualExpression: ['character', 'physical'],
    relational: ['family', 'love', 'social_life', 'parenting'],
    communityExpression: ['financial', 'work', 'leisure', 'social_contributions'],
  };

  for (const [key, value] of Object.entries(types)) {
    if (value.some((val) => val === selector)) return key;
  }
}

function getQuadrantByPoints(x, y) {
  const xThreshold = 8.5;
  const yThreshold = 7.5;

  if (x >= xThreshold && y >= yThreshold) return 'flow';
  if (x < xThreshold && y >= yThreshold) return 'action';
  if (x < xThreshold && y <= yThreshold) return 'watch';
  return 'ease';
}

export function enchanceSurveyResults(surveyResults) {
  const results = [...surveyResults];

  results.sort((serieA, serieB) => serieA.y < serieB.y ? 1 : -1);
  results.forEach((result) => {
    result.type = getTypeBySelector(result.selector);
    result.quadrant = getQuadrantByPoints(result.x, result.y);
  });

  return results;
}