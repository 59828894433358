import React, { useCallback, useState } from 'react'
import { useStylesAssessments } from '../../assessments/LIARecordPage';
import { Box, CircularProgress, Typography } from '@mui/material';
import { SelectAssessments } from '../../ui/SelectAssessments';
import { HtmlTooltip } from '../HtmlTooltip';

import PDFPersonalize from '../../pdf/PDFPersonalLize';
import ZonePage from '../../pdf/ZonePage';
import ZonePageMain from '../../pdf/ZonePageMain';
import { Document, Page, pdf } from '@react-pdf/renderer';
import InventoryAssessment from '../../pdf/InventoryAssessment';
import HowtoReady from '../../pdf/HowtoReady';
import LastPage from '../../pdf/LastPage';
import { Mixpanel } from '../../../analytics';


export const generatePdf = async ({ setIsDownloading, ReportPDF, firstName, record }) => {
  Mixpanel.track('Download Life Map Report', {
    firstName: firstName,
    lastName: record.lastName,
    email: record.email,
    user_id: record.user_id,
    date: new Date().toLocaleString().split(',')[0],
  });

  setIsDownloading(true)
  let blobPDF = await pdf(ReportPDF()).toBlob();
  const element = document.createElement('a');
  element.setAttribute('href', `${URL.createObjectURL(blobPDF)}`);
  element.setAttribute('download', `${firstName} - Life Map - ${(new Date()).toLocaleString().split(',')[0]}.pdf`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  setIsDownloading(false)
}

export const AssessmentsChartHeader = ({
  assessmentId,
  surveys,
  handleChange,
  isDataFetched,
  instance,
  record,
  firstName,
  actionList, easeList,
  flowList, lists, percentage, ratingData, resultsDate,
  theme, watchList, zoneStatusArray,
}) => {
  const { classes: classes2 } = useStylesAssessments();
  const [isDownloading, setIsDownloading] = useState(false)
  const ReportPDF = useCallback((props) => (
    <Document onRender={(e) => { }}>

      <Page wrap size="A4">
        <PDFPersonalize rating={ratingData} resultsDate={resultsDate} firstName={firstName} lists={lists} theme={theme} />
      </Page>
      <Page wrap size="A4">
        <InventoryAssessment percentage={percentage} resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <Page wrap size="A4">
        <HowtoReady resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={watchList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      {/* <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} /> */}

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={lists} rating={ratingData} firstName={firstName} />

      <Page wrap size="A4">
        <LastPage lists={lists} zone_ratings={ratingData} title="Ease" resultsDate={resultsDate} firstName={firstName} />
      </Page>

    </Document>
  ), [actionList, easeList, firstName,
    flowList, lists, percentage, ratingData, resultsDate,
    theme, watchList, zoneStatusArray]);



  return (
    <Box
      className={classes2.bubbleExplanation}
    >
      <Box
        className={classes2.surveyCreationDate}
      >
        <SelectAssessments
          value={assessmentId}
          surveys={surveys}
          id="select3"
          label='Record Date'
          onChange={(e) => handleChange(e)}
          disabled={!isDataFetched}
        />

      </Box>
      <Box
        className={classes2.chartLegendExplanation}
      >
        <HtmlTooltip title={
          <Box style={{ width: '324px' }}>
            <Typography color="inherit">
              1. Hover over items on this page, like the words Flow, Action, Ease, Watch, Fulfilment, and any of the life categories for a detailed description of each.
            </Typography>
            <Typography color="inherit">
              2. Click on the bubbles to access your Life Health Dashboards.
            </Typography>

          </Box>
        }>
          <button
            className={classes2.infoButton}
            onClick={() => {
              // setIsTooltipOpen(true)
            }}
          >
            Info

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#003E6F" />
            </svg>

          </button>


        </HtmlTooltip>

      </Box>
      <button
        disabled={!isDataFetched || instance?.loading}
        onClick={async () => {
          generatePdf({ setIsDownloading, ReportPDF, firstName, record });
        }}
        className={classes2.reportButton}
      >

        {isDownloading ? <CircularProgress className={classes2.loader} /> : <>
          Report
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 16C3.98333 16 2.68767 15.475 1.613 14.425C0.537667 13.375 0 12.0917 0 10.575C0 9.275 0.391667 8.11666 1.175 7.1C1.95833 6.08333 2.98333 5.43333 4.25 5.15C4.63333 3.8 5.346 2.66666 6.388 1.75C7.42933 0.83333 8.63333 0.274997 10 0.0749969V8.15L9.1 7.275C8.91667 7.09166 8.68767 7 8.413 7C8.13767 7 7.9 7.1 7.7 7.3C7.51667 7.48333 7.425 7.71666 7.425 8C7.425 8.28333 7.51667 8.51666 7.7 8.7L10.3 11.3C10.5 11.5 10.7333 11.6 11 11.6C11.2667 11.6 11.5 11.5 11.7 11.3L14.3 8.7C14.4833 8.51666 14.5793 8.28733 14.588 8.012C14.596 7.73733 14.5 7.5 14.3 7.3C14.1167 7.11666 13.8877 7.02066 13.613 7.012C13.3377 7.004 13.1 7.09166 12.9 7.275L12 8.15V0.0749969C13.7167 0.30833 15.146 1.079 16.288 2.387C17.4293 3.69566 18 5.23333 18 7C19.15 7.13333 20.1043 7.629 20.863 8.487C21.621 9.34566 22 10.35 22 11.5C22 12.75 21.5627 13.8127 20.688 14.688C19.8127 15.5627 18.75 16 17.5 16H5.5Z" fill="#003E6F" />
          </svg>
        </>}


      </button>
      {/* {isButtonClicked && pdfLink}
              {!isButtonClicked && <button onClick={() => { setButtonClicked(true) }} className={classes2.reportButton}>
                Report
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 16C3.98333 16 2.68767 15.475 1.613 14.425C0.537667 13.375 0 12.0917 0 10.575C0 9.275 0.391667 8.11666 1.175 7.1C1.95833 6.08333 2.98333 5.43333 4.25 5.15C4.63333 3.8 5.346 2.66666 6.388 1.75C7.42933 0.83333 8.63333 0.274997 10 0.0749969V8.15L9.1 7.275C8.91667 7.09166 8.68767 7 8.413 7C8.13767 7 7.9 7.1 7.7 7.3C7.51667 7.48333 7.425 7.71666 7.425 8C7.425 8.28333 7.51667 8.51666 7.7 8.7L10.3 11.3C10.5 11.5 10.7333 11.6 11 11.6C11.2667 11.6 11.5 11.5 11.7 11.3L14.3 8.7C14.4833 8.51666 14.5793 8.28733 14.588 8.012C14.596 7.73733 14.5 7.5 14.3 7.3C14.1167 7.11666 13.8877 7.02066 13.613 7.012C13.3377 7.004 13.1 7.09166 12.9 7.275L12 8.15V0.0749969C13.7167 0.30833 15.146 1.079 16.288 2.387C17.4293 3.69566 18 5.23333 18 7C19.15 7.13333 20.1043 7.629 20.863 8.487C21.621 9.34566 22 10.35 22 11.5C22 12.75 21.5627 13.8127 20.688 14.688C19.8127 15.5627 18.75 16 17.5 16H5.5Z" fill="#003E6F" />
                </svg>

              </button>} */}


    </Box>
  )
}
