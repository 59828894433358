import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more"; //module
import ElementGauge from "../clients/ElementGauge";

import SimpleLoading from './SimpleLoading';
import { makeStyles } from 'tss-react/mui';
import { getCategoryLabel } from "../../utils/getCategoryLabel";

const useStyles = makeStyles()((theme) => ({
  root: {
    // boxShadow: '0px 3px 6px #00000029',
    // borderRadius: '12px',
    // backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
    },
  },
  title: {
    fontSize: '21px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '14px',
    color: theme.colors.shark,
  },
  label: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  gauge: {
    cursor: 'pointer',
    flexGrow: 0,
    width: 100,
    '&:not(:first-of-type)': {
      // marginLeft: '8px',
    }
  },
  '@keyframes blink': {
    '50%': {
      color: theme.colors.c0c0,
    },
  },
  instruction: {
    backgroundColor: theme.colors.white,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    '& > strong': {
      color: theme.colors.azureRadiance,
      textTransform: "uppercase",
      marginRight: theme.spacing(0.5),
      animation: '$blink 1s step-start 0s infinite',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  infoButton: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
    svg: {
      flexShrink: '0'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  tooltip: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '100',
    padding: '56px 16px 32px',
    fontSize: '12px',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px 16px 0 0',
    boxShadow: '0 12px 16px -4px #000',
    transform: 'translateY(100%)',
    transition: '1s',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  tooltip_open: {
    transform: 'translateY(0%)'
  },
  tooltip_close: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '0',
  },
  tooltip_text: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '8px',
  },
  tooltip_title: {
    color: '#0689FB',
    fontSize: '16px',
    lineHeight: '18px',
    paddingBottom: '16px',
    fontWeight: '600',
  },
}));

HighchartsMore(Highcharts); //init module

const ElementalDashboard = ({ ratings, handleElementClick }) => {
  const { classes } = useStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);


  if (!ratings || ratings.length <= 0) return <SimpleLoading />;

  return (
    <>
      <Box>

        <button
          className={classes.infoButton}
          onClick={() => {
            setIsTooltipOpen(true)
          }}
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#5FB4FE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </button>
        <Box
          className={classes.instruction}
        >
          <strong>Instruction:</strong>
          Click on the gauges below to receive deeper insight and guided direction.
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          className={classes.root}
        >
          {
            ratings.filter((r) => r.name !== 'Total Health').map((element, index) => (
              <Box
                className={classes.gauge}
                key={index}
                paddingX={1}
                onClick={() => handleElementClick(element.name)}
              >
                <ElementGauge category={element} />
                <Box
                  className={classes.label}
                >
                  {getCategoryLabel(element.name)}
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
      <Box className={`${classes.tooltip} ${isTooltipOpen && classes.tooltip_open}`}>
        <button className={classes.tooltip_close} onClick={() => {
          setIsTooltipOpen(false)
        }}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13M1 1L13 13" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <Typography className={classes.tooltip_title}>Instructions</Typography>
        <Typography className={classes.tooltip_text}>
          Click on the gauges below to receive deeper insight and guided direction.
        </Typography>
      </Box>
    </>
  );
};

export default ElementalDashboard;