import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import WelcomeTitle from '../common/WelcomeTitle';
import TotalLifeHealth from '../common/TotalLifeHealth';

import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import LifeInventoryAssessmentChart from '../common/LifeInventoryAssessmentChart';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';
import Moment from "react-moment";
import parse from 'html-react-parser';

import { useSearchParams, useParams } from 'react-router-dom';
import { getSearchWith } from '../../utils/searchHelper.js';
import CompareCategoryDrawer from '../clients/CompareCategoryDrawer.js';
import { SelectAssessments } from '../ui/SelectAssessments.js';
import { CompareAccordion } from '../ui/CompareAccordion.js';
import { getCategoryColor } from '../../utils/geCategoryColor.js';
import { CompareCategories } from '../ui/CompareCategories.js';
import { getAssessmentData } from '../../utils/getAssessmentData.js';
import { getCategoriesData } from '../../utils/getCategoriesData.js';
import { getChartData } from '../../utils/getChartData.js';
import { useAppContext } from '../../contexts/AppContext.js';
import { IconBreadcrumbs } from '../clients/client.edit.js';
import { AssessmentsHeader } from '../common/assessmentComponents/AssessmentsHeader.js';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  compareButton: {
    color: 'white',
    background: '#003E6E',
    textTransform: 'uppercase',
    width: '157px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: 'auto',
    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: '0',
    },
  },

  lifeInventoryAssessmentChart: {
    // height: '765px'
    padding: '43px',
    width: 'calc((100vw - 256px - 176px - 24px) / 2)',
    transition: '0.5s',

    ':last-of-type': {
      borderLeft: '1px solid #E2E2E2'

    },

    [theme.breakpoints.down('xl')]: {
      padding: '10px',
      ':last-of-type': {
        borderLeft: 'none !important',
        borderTop: '1px solid #E2E2E2',
        marginTop: '50px',
      },
      width: 'calc((100vw - 90px - 24px))',
    },
  },
  lifeInventoryAssessmentChartwithoutSidebar: {
    width: 'calc((100vw - 176px - 24px) / 2)',
  },
  categories_container: {
    display: 'flex',
    gap: '24px',
  },
  life_score_container: {
    display: 'flex',

    [theme.breakpoints.down('xl')]: {
      // display: 'grid',
      // gridTemplateColumns: '1fr',
    },

  },
  categories_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
  },
  categories: {
    flexGrow: '1',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',

  },
  chartText: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
    color: '#232323',

  },
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: '32px',
    gap: '32px'
  },
  infoContainerItem: {
    padding: '24px',
    background: 'white',
    borderRadius: '12px',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  infoContainerItem_accordion: {
    margin: '0 !important',
    boxShadow: 'none',
    outline: 'none',

    "&::before": {
      display: 'none',

    },

  },
  selectChart: {
    display: 'flex',
    gap: '24px'

  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
      maxHeight: '220px'
    },
  },
  life_score: {
    display: 'grid',
    gridTemplateColumns: '1fr 150px',
    gap: '8px',
    flexGrow: '1',
    paddingLeft: '16px',

    '&:last-of-type': {
      borderLeft: '2px solid #E2E2E2',
      paddingLeft: '48px',
      marginLeft: '48px',

      [theme.breakpoints.down('xl')]: {
        paddingLeft: '20px',
        marginLeft: '20px',
      },

    },

    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
      gridTemplateColumns: '1fr',
    },

  },
  life_score_text_container: {
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
  },
  life_score_title: {
    marginTop: '37px',
    fontSize: '20px',
    fontWeight: '600',
    textAlign: 'start',

  },
  life_score_text: {
    marginTop: '10px',
    fontSize: '12px',
    color: '#5c5c5c',
    textAlign: 'start',
    marginBottom: '37px',
  },
  summaryContainer: {
    display: 'none',
    [theme.breakpoints.down('xl')]: {
      marginTop: '48px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      width: '100%',
      paddingTop: '32px',
      borderTop: '1px solid #EAECF0',

      span: {
        fontSize: '15.4px',
        fontWeight: '600',
        textAlign: 'center',
      }
    },
  },
  accordion_title: {
    color: '#003E6E',
    display: 'block',
    fontSize: '24px',
    fontWeight: '600',
  },
  accordion_text: {
    color: '#878787',
    display: 'block',
    fontSize: '16px',
    fontWeight: '400',
  },
  accordion_text_container: {
    '.MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  },

  select: {
    width: '320px',
    height: '48px',
    padding: '12px',
    // '&::after': {
    //   content: '123'
    // }
    '.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '11px',
      paddingLeft: '0'
    }
  },
  selectLabel: {
    display: 'flex',
    color: '#003E6F',
    alignItems: 'center',
    gap: '8px',
  },
  charts: {
    display: 'flex',

    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
    },

  },
  categories_item: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: '25px 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    cursor: 'pointer',
    // padding: '16px 0',
  },
  categories_item_percentage_container: {
    flexGrow: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

  },
  categories_item_percentage: {
    height: '6px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#cccccc',
    position: 'relative',
  },

  categories_title: {

    fontSize: '16px',
    color: 'black',
  },
  categories_container_title: {
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '8px',

  },
  view_less: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    margin: '0 auto',
    color: '#003E6F',
    textDecorationColor: '#003E6F',
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
  },
  categories_accordion: {
    padding: '0',

  },
  categories_accordion_root: {
    boxShadow: 'none',
    WebkitBoxShadow: 'none',

    '&::before': {
      display: 'none',
    },

    '.MuiAccordionSummary-root': {
      minHeight: '0px',
      height: '0px',
    },

    '.MuiCollapse-wrapper': {
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
    },

  }
}));


const CompareProgressPage = ((props) => {
  const { customClient } = props;
  const record = useRecordContext();
  const { classes } = useStyles();
  const [currentSelector, setCurrentSelector] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [assessment, setAssessment] = useState({
    assessmentId1: null,
    assessmentId2: null,
  });
  const [surveyResults, setSurveyResults] = useState({
    assessmentId1: [],
    assessmentId2: [],
  });
  const isDrawerOpenRef = useRef();
  const redirect = useRedirect();
  const [isCompare, setIsCompare] = useState(false);
  const [isViewLess, setIsViewLess] = useState(true);
  const [customRecord, setCustomRecord] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams()
  useEffect(() => {
    if (customClient) {
      const fetch = async () => {
        const { data: response } = await dataProvider.getOne('clients', { id: params.id });
        setCustomRecord(response)
      }

      fetch()
    }


  }, [dataProvider, customClient]);
  const userName = record?.firstName || customRecord?.firstName;
  const surveys = useMemo(() => {
    const sortedSurveys = record && record.Surveys ? [...record.Surveys] : customRecord?.Surveys || [];
    return sortedSurveys.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [record, customRecord]);

  isDrawerOpenRef.current = isDrawerOpen;


  const assessmentId1 = searchParams.get('assessmentId1') || ''
  const assessmentId2 = searchParams.get('assessmentId2') || ''


  const [selects, setSelects] = React.useState({ assessmentId1: assessmentId1, assessmentId2: assessmentId2 });
  const surveys1Date = surveys?.find(item => `${item.id}` === `${selects.assessmentId1}`)?.created_at
  const surveys2Date = surveys?.find(item => `${item.id}` === `${selects.assessmentId2}`)?.created_at
  const handleChange = (selectName, event) => {
    setSearchParams(getSearchWith(searchParams, { [selectName]: event.target.value }));
    setSelects(prev => ({ ...prev, [selectName]: event.target.value }));
  };
  const currentRef = useRef(null);
  const [lists, setLists] = useState({
    assessmentId1: [],
    assessmentId2: [],
  });



  const [ratingData, setRatingData] = useState({
    assessmentId1: [],
    assessmentId2: [],
  });
  const [isDataFetched, setDataFetched] = React.useState(false);


  const fetchAssessmentData = async () => {
    const values = Object.values(selects)
    if (!values.includes('')) {
      Object.entries(selects).forEach(async ([key, id]) => {

        try {
          await getChartData({
            assessmentId: id,
            dataProvider,
            setSurveyResults: (newValue) => setSurveyResults(prev => ({ ...prev, [key]: newValue })),
            enchanceSurveyResults,
            setAssessment: (newValue) => setAssessment(prev => ({ ...prev, [key]: newValue })),
          })
        } catch (e) {
          redirect('/login');
        }

      });

    }

    if (currentRef.current === true) return;
    if (!values.includes('')) {
      Promise.all(Object.entries(selects)?.map(async ([key, id]) => {
        return getAssessmentData({
          assessmentId: id,
          dataProvider,
          setRatingData: (newValue) => setRatingData(prev => ({ ...prev, [key]: newValue })),
          setLists: (newValue) => setLists(prev => ({ ...prev, [key]: newValue })),
          enchanceSurveyResults,

        })
      })).then((data) => {
        setDataFetched(true);
      })
    }

    currentRef.current = null
  }

  // if (!assessment) return <FullscreenLoading />

  const categoriesData = useMemo(() => getCategoriesData({ listsAssessmentId: lists.assessmentId1, ratingDataAssessmentId: ratingData.assessmentId1 }), [lists, ratingData]);
  const categoriesData2 = useMemo(() => getCategoriesData({ listsAssessmentId: lists.assessmentId2, ratingDataAssessmentId: ratingData.assessmentId2 }), [lists, ratingData]);

  function handleCategoryClick(selector) {
    setCurrentSelector(selector);
    setIsDrawerOpen(true);
  }
  const { isOpenSidebar } = useAppContext();

  return (
    <>

      <Box
        // className={classes.root}
        position="relative"
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >

        {customClient ? <>
          <IconBreadcrumbs name={userName} lastItem='Compare Progress' />
          <AssessmentsHeader
            whithButtonBack
            title={''}
            userName={`${userName}`}
            subtitle={'A tool you can use to find focus, align purpose and live more fully.'}
          />
        </> : <>
          <Box className={classes.header}>
            <WelcomeTitle
              title={`Compare Progress`}
              // userName={`${userName},`}
              subtitle={'Select 2 Life Maps from your available life maps to compare progress.'}
            />

          </Box>
        </>}




        <Box
          className={classes.infoContainer}
        >
          <Box
            className={`${classes.selectChart} ${classes.infoContainerItem}`}
          >
            <SelectAssessments
              value={selects.assessmentId1}
              surveys={surveys}
              id="select1"
              label='Life Map 1'
              onChange={(e) => handleChange('assessmentId1', e)}
            />
            <SelectAssessments
              value={selects.assessmentId2}
              surveys={surveys}
              id="select2"
              label='Life Map 2'
              onChange={(e) => handleChange('assessmentId2', e)}
            />
            <button
              className={classes.compareButton}
              onClick={() => {
                fetchAssessmentData();
                setIsCompare(true)
              }}
            >
              Compare

            </button>
          </Box>

          {isCompare && <CompareAccordion
            title='Life Map'
            text='Select 2 Life Maps from your available life maps to compare progress.'
            defaultExpanded

          >
            <Box className={classes.charts}>
              <Box
                marginTop={0.5}
                className={`${classes.lifeInventoryAssessmentChart} ${!isOpenSidebar && classes.lifeInventoryAssessmentChartwithoutSidebar}`}
              >
                <Typography className={classes.chartText}>Size of bubble = Total health of life category</Typography>
                <LifeInventoryAssessmentChart
                  surveyResults={surveyResults.assessmentId1}
                  handleCategoryClick={handleCategoryClick}
                  handleItemHover={() => { }}
                />
              </Box>
              <Box
                marginTop={0.5}
                className={`${classes.lifeInventoryAssessmentChart} ${!isOpenSidebar && classes.lifeInventoryAssessmentChartwithoutSidebar}`}
              >
                <Typography className={classes.chartText}>Size of bubble = Total health of life category</Typography>
                <LifeInventoryAssessmentChart
                  surveyResults={surveyResults.assessmentId2}
                  handleCategoryClick={handleCategoryClick}
                  handleItemHover={() => { }}
                />
              </Box>
            </Box>
          </CompareAccordion>}

          {isCompare && <CompareAccordion
            title='Categories'
            text='This 13 are the main categories of your life.'
            defaultExpanded

          >
            <Box className={classes.categories_wrapper}>
              <Box
                marginTop={0.5}
                className={classes.life_score_container}
              >
                <Box className={`${classes.life_score}`}>
                  <Box className={`${classes.life_score_text_container}`}>
                    <Typography className={`${classes.life_score_title}`}>Life Score</Typography>
                    <Typography className={`${classes.life_score_text}`}>Your total life score represents your average level of fulfillment across all 13 categories.</Typography>
                  </Box>

                  <TotalLifeHealth
                    percentage={assessment.assessmentId1?.total_life_score}
                  // color={theme.colors.lightTeal}
                  />
                </Box>
                <Box className={`${classes.life_score}`}>
                  <Box className={`${classes.life_score_text_container}`}>
                    <Typography className={`${classes.life_score_title}`}>Life Score</Typography>
                    <Typography className={`${classes.life_score_text}`}>Your total life score represents your average level of fulfillment across all 13 categories.</Typography>
                  </Box>

                  <TotalLifeHealth
                    percentage={assessment.assessmentId2?.total_life_score}
                  // color={theme.colors.lightTeal}
                  />
                </Box>

              </Box>
              {isDataFetched && <>
                <Accordion expanded={isViewLess} className={classes.categories_accordion_root}>
                  <AccordionSummary>
                  </AccordionSummary>
                  <AccordionDetails className={classes.categories_accordion}>
                    <Typography className={classes.categories_container_title}>Categories Insight</Typography>
                    <Box
                      marginTop={0.5}
                      className={classes.categories_container}
                    >
                      <Box className={`${classes.categories}`}>
                        <Typography className={`${classes.categories_title}`}>
                          <Moment format="MM/DD/YYYY">
                            {surveys1Date}

                          </Moment>
                        </Typography>
                        <CompareCategories
                          categoriesData={categoriesData}
                          handleCategoryClick={handleCategoryClick}
                        />

                      </Box>

                      <Box className={`${classes.categories}`}>
                        <Typography className={`${classes.categories_title}`}>
                          <Moment format="MM/DD/YYYY">
                            {surveys2Date}

                          </Moment>
                        </Typography>
                        <CompareCategories
                          categoriesData={categoriesData2}
                          handleCategoryClick={handleCategoryClick}
                          withDetails
                        />

                      </Box>
                    </Box>
                  </AccordionDetails>

                </Accordion>
                <button className={classes.view_less} onClick={() => { setIsViewLess(prev => !prev) }}>
                  {isViewLess ? 'View Less Insight' : 'View More Insight'}
                </button>

              </>}
            </Box>
          </CompareAccordion>}
        </Box>
        {(() => {
          const index1 = surveyResults.assessmentId1.findIndex((item) => item.selector === currentSelector);
          const item1 = surveyResults.assessmentId1[index1] || {};
          const index2 = surveyResults.assessmentId2.findIndex((item) => item.selector === currentSelector);
          const item2 = surveyResults.assessmentId2[index2] || {};
          function closeDrawer() {
            setIsDrawerOpen(false);
          }

          return (
            <CompareCategoryDrawer
              assessmentId1={assessmentId1}
              assessmentId2={assessmentId2}
              category={item1.selector}
              categoryName={parse(item1.category || '')}
              quadrant1={item1.quadrant}
              quadrant2={item2.quadrant}
              // categoryLevel={index + 1}
              // categoryCount={surveyResults.length}
              open={isDrawerOpen}
              // lifeJournal={lifeJournal}
              handleDrawerClose={closeDrawer}
              getCategoryColor={getCategoryColor}
            />
          )
        })()}
      </Box >
    </>
  );
});

export default CompareProgressPage;