import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfstyle';
import categories from '../../../resources/categories';
export const ActionGraph = (props) => {
  const { lists, rating, theme } = props;
  const width = 30;
  const max = Math.max(...lists.map(item => item.z));
  const min = Math.min(...lists.map(item => item.z));
  const parsedSeries = lists.map((point, index) => {
    const category = categories.find((category) => category.selector === point.selector) || {};
    const rate = rating.find((rate) => rate.selector === point.selector)
    return {
      x: point.x,
      y: point.y,
      z: point.z,
      quadrant: point.quadrant,
      percentage: 65 + ((point.z - min) * (125 / max)),
      selector: point.selector,
      name: `${index + 1}. ${category.name}`,
      color: theme.colors.quadrantTypes[point.quadrant],
    };
  })

  return (
    <View style={{
      backgroundColor: '#F6FBFA', width: '100%', height: '100%', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '12px', paddingRight: '12px', display: 'flex', justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Image style={{ position: 'absolute', left: '-8px', zIndex: '10', top: '50px', width: 'auto', height: '120px', marginLeft: '12px' }} src={'/self-rank-2.png'} />
      <Image style={{ position: 'absolute', bottom: '-8px', zIndex: '10', left: '100px', width: '80px', height: 'auto' }} src={'/fullfillment-2.png'} />
      {/* <Image style={{position:'absolute', left:'0px', top:'170px', width:'20px', height:'100px', marginLeft:'12px'}} src={'/self-rank.jpg'}/>
            <Image style={{position:'absolute', bottom:'0px', left:'250px', width:'120px', height:'15px'}} src={'/fullfillment.jpg'}/> */}
      <View style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
        {/* <Image style={{ position: 'absolute', left: '0px', top: '170px', width: 'auto', height: '120px', marginLeft: '12px' }} src={'/self-rank-2.png'} />
        <Image style={{ position: 'absolute', bottom: '0px', left: '250px', width: '80px', height: 'auto' }} src={'/fullfillment-2.png'} /> */}
        <Text style={[pdfStyles.text_font_12_Inter, { position: 'absolute', color: "#FF551C", left: '4px', top: '4px' }]}>Action</Text>
        <View style={{ position: 'absolute', backgroundColor: 'grey', left: '273px', top: '4px', width: '1px', height: '200px', zIndex: -1 }} />
        <View style={{ position: 'absolute', backgroundColor: 'grey', left: '4px', top: '200px', width: '273px', height: '1px', zIndex: -1 }} />
        {parsedSeries.map(((point, index) => (
          <div key={point.selector}>
            {
              point.quadrant === "action" && (
                <View
                  style={{
                    position: 'absolute',
                    width: (2 * width * point.percentage) / 100,
                    height: (2 * width * point.percentage) / 100,
                    left: point.x * width + 2 * width * (max / 2 - point.percentage / 2) / 100,
                    textAlign: 'center',
                    top: 70 + ((14 - point.y) * width * 2) / 3 - 2 * width * ((point.percentage / 2)) / 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 10,
                      backgroundColor: point.color,
                      borderRadius: '100%',
                      opacity: 0.5,
                    }}
                  >

                  </View>
                  <Text
                    style={{
                      ...pdfStyles.text_font_6_Inter,
                      color: 'black',
                      zIndex: 999,
                    }}
                  >
                    {point.name}
                  </Text>
                </View>)
            }
          </div>
        )))}

      </View>
    </View>
  )
}

export default ActionGraph;