import React, { useEffect, useRef, useState, useMemo } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, MenuItem, Link } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, withStyles } from 'tss-react/mui';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Logo from '../common/Logo';
import Moment from "react-moment";
import LogoutButton from "../../pages/LogoutButton";
import { useParams } from 'react-router-dom';
import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import { enchanceSurveyResults } from '../../helpers/AssessmentHelper';

import { useTheme } from '@mui/material';

import PDFPersonalize from '../pdf/PDFPersonalLize';
import InventoryAssessment from '../pdf/InventoryAssessment';
import HowtoReady from '../pdf/HowtoReady';
import ZonePage from '../pdf/ZonePage';

import LastPage from '../pdf/LastPage';
import ZonePageMain from '../pdf/ZonePageMain';
import { generatePdf } from '../common/assessmentComponents/AssessmentsChartHeader';


const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors.wildSand,
    boxShadow: '0px 3px 6px #00000029',
  },
  title: {
    fontSize: '22px',
    marginBottom: '30px',
  },
  menuItem: {
    backgroundColor: theme.colors.white,
    borderBottom: '1px solid #00000020',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  menuItemDisabled: {
    opacity: '0.6',
    pointerEvents: 'none'
  },
  subMenuItem: {
    padding: theme.spacing(1),

    '&:not(:first-of-type)': {
      borderTop: '1px solid #00000020',
    },
  },
  menuButton: {
    padding: theme.spacing(1),

    '& > p': {
      padding: theme.spacing(0, 1),
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: `rgba(0, 0, 0, 0.04)`,
      },
    },
  },
  link: {
    padding: theme.spacing(1),
    cursor: 'pointer',

    '&.Mui-selected': {
      pointerEvents: 'none',
      backgroundColor: theme.colors.loblolly,
    },
  },
  button: {
    backgroundColor: '#0689FB',
    width: '183px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    color: 'white',
    height: '40px',
    cursor: 'pointer',
  },
  button_download: {
    color: 'white',
    textDecoration: 'none',
  },
}));






const PDFLink = ({ assessmentId,
  surveys,
  handleChange,
  isDataFetched,
  instance,
  firstName,
  actionList, easeList,
  flowList, lists, percentage, ratingData, resultsDate,
  theme, watchList, zoneStatusArray,
  record
}) => {
  const { classes } = useStyles();
  const [isDownloading, setIsDownloading] = useState(false);

  const ReportPDF = (props) => (
    <Document onRender={(e) => { }}>

      <Page wrap size="A4">
        <PDFPersonalize rating={ratingData} resultsDate={resultsDate} firstName={firstName} lists={lists} theme={theme} />
      </Page>
      <Page wrap size="A4">
        <InventoryAssessment percentage={percentage} resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <Page wrap size="A4">
        <HowtoReady resultsDate={resultsDate} firstName={firstName} lists={lists} />
      </Page>
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />
      <ZonePageMain resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={watchList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Action" zoneList={actionList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Flow" zoneList={flowList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Ease" zoneList={easeList} rating={ratingData} lists={lists} theme={theme} firstName={firstName} />

      <ZonePage resultsDate={resultsDate} zoneStatusArray={zoneStatusArray} title="Watch" zoneList={watchList} rating={ratingData} firstName={firstName} />

      <Page wrap size="A4">
        <LastPage lists={lists} zone_ratings={ratingData} title="Ease" resultsDate={resultsDate} firstName={firstName} />
      </Page>

    </Document>
  );

  return (
    <Typography
      onClick={() => generatePdf({ setIsDownloading, ReportPDF, firstName, record })}
      className={classes.button}
      sx={{ color: 'black', textDecoration: 'none' }}
    >
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 11.5V14.8333C16 15.2754 15.8244 15.6993 15.5118 16.0118C15.1993 16.3244 14.7754 16.5 14.3333 16.5H2.66667C2.22464 16.5 1.80072 16.3244 1.48816 16.0118C1.17559 15.6993 1 15.2754 1 14.8333V11.5M4.33333 7.33333L8.5 11.5M8.5 11.5L12.6667 7.33333M8.5 11.5V1.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {isDownloading ? 'Loading' : 'Download Report'}
    </Typography>
  );
};

export default PDFLink;

