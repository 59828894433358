
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import HighchartsReact from "highcharts-react-official";
import { create } from "../../charts/bubble";
import Highcharts from "highcharts";
import categories from '../../resources/categories';
import quadrants from '../../resources/quadrants';
import axis from '../../resources/axis';
import { HtmlTooltip } from "../common/HtmlTooltip";
import parse from 'html-react-parser';
import { getQuadrantColor } from "../../utils/getQuadrantColor";
import { useAppContext } from "../../contexts/AppContext";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',

    '& .highcharts-point': {
      strokeWidth: '0 !important', // overriding inline style
    },

    '& .highcharts-data-labels text': {
      fontFamily: 'Poppins !important',
    }
  },
  container: {
    maxWidth: '100%',
    position: 'relative',
  },

  label: {
    zIndex: 5,
    position: 'absolute',
    padding: theme.spacing(1),
    textTransform: 'capitalize',
    fontSize: '18px',
  },
  flow: {
    top: 0,
    right: 0,
  },
  action: {
    top: 0,
    left: 0,
  },
  watch: {
    bottom: 0,
    left: 0,
  },
  ease: {
    bottom: 0,
    right: 0,
  },
  fulfillment: {
    left: '50%',
    transform: 'translateX(-50%)',
  },
  priority: {
    top: '50%',
    transform: 'rotateZ(270deg)',
    left: -120,
    [theme.breakpoints.down('xl')]: {
      left: -110,
    },
  },
  tooltip: {
    maxWidth: '700px',
    fontSize: '16px',
    padding: '12px 4px'

  },
  tooltip__title: {
    fontSize: '20px',
    fontWeight: '700',
    marginBottom: '16px',
  }
}));

const CategoryBubbleChart = React.memo(({ series, handleCategoryClick, handleItemHover }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const parsedSeries = series.map((point, index) => {
    const category = categories.find((category) => category.selector === point.selector) || {};

    return {
      x: point.x,
      y: point.y,
      z: point.z,
      selector: point.selector,
      name: `${index + 1}. ${category.name}`,
      color: theme.colors.quadrantTypes[point.quadrant],
    };
  });


  const options = create({
    series: parsedSeries,
    handleClick: handleCategoryClick,
    handleHover: handleItemHover,
    displayBottomLines: false,
    // width: '200px',
    height: 500,
    zoomType: '',
  });
  const chartComponent = useRef({});
  const { isOpenSidebar } = useAppContext();
  useEffect(() => {
    const timeout = setTimeout(() => {
      const chart = chartComponent.current?.chart;

      if (chart) chart.reflow(false);
    }, 500);

    return () => {
      clearTimeout(timeout)

    }
  }, [isOpenSidebar]);


  return (
    <Box
      className={classes.root}
    >
      <Box className={classes.container}>
        {
          quadrants.map((quadrant) => (
            <HtmlTooltip
              key={quadrant.selector}
              title={
                <Box style={{ width: '500px' }} className={classes.tooltip}>
                  <Typography className={classes.tooltip__title} style={{ color: `${getQuadrantColor(quadrant.name.toLocaleLowerCase())}` }}>{quadrant.name} Zone</Typography>
                  {parse(quadrant.description)}
                </Box>
              }>
              <Box
                className={`${classes[quadrant.selector]} ${classes.label}`}
                color={theme.colors.quadrantTypes[quadrant.selector]}
                onMouseEnter={() => handleItemHover(true, quadrant.selector, 'quadrant')}
                onMouseLeave={() => handleItemHover(false)}
              >
                {quadrant.name}
              </Box>

            </HtmlTooltip>
          ))
        }
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={{
            ...options
          }} />

      </Box>
      {
        axis.map((axis) => (
          <Box
            key={axis.selector}
            className={`${classes[axis.selector]} ${classes.label}`}
            onMouseEnter={() => handleItemHover(true, axis.selector, 'axis')}
            onMouseLeave={() => handleItemHover(false)}
          >
            {axis.name}
          </Box>
        ))
      }
    </Box>
  );
});

export default CategoryBubbleChart;