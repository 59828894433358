import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react'
import Moment from 'react-moment';
import { makeStyles } from 'tss-react/mui';

const soperatorStyles = {};


const useStyles = makeStyles()((theme) => ({

  form: {
    '.MuiInputLabel-shrink': {
      paddingLeft: '0',
      top: '0%',
    },

    // '.MuiFormLabel-filled': {
    //   paddingLeft: '0'
    // },
  },
  ...soperatorStyles,
  option_separation: {
    height: '1px',
    width: '100%',
    background: '#e2e2e2',
    position: 'absolute',
    bottom: '0',
    left: "0",

  },
  select: {
    width: '320px',
    height: '48px',
    padding: '12px 12px 12px 44px',
    position: 'relative',

    [theme.breakpoints.down('xl')]: {
      width: '180px !important',
    },
    // background: 'red',
    '&::before': {
      content: '""',
      // content: '',
      display: 'block',
      width: '18px',
      height: '20px',
      position: 'absolute',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13ZM16 18H2V7H16V18Z' fill='%23003E6F'/%3E%3C/svg%3E%0A")`,
      top: '50%',
      transform: 'translateY(-50%)',
      left: '15px',
    },
    '.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '11px',
      paddingLeft: '0',

      '&::after': {
        // content: '',
        display: 'block',
        width: '20px',
        height: '20px',
        background: 'red',
        borderRadius: '50%',
        marginLeft: '16px',
        marginTop: '12px',
      },



      // '&::before': {
      //   content: '',


      // }
    },
  },

  option: {
    paddingLeft: '44px',
    position: 'relative',
  },
  selectLabel: {
    display: 'flex',
    color: '#003E6F',
    alignItems: 'center',
    gap: '8px',
    paddingLeft: '24px',
    paddingBottom: '8px',
    top: '-5%',
    // transform: 'translateY(50%)'
  },

}));

// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeMedium MuiInputLabel-outlined css-6kxngl-MuiFormLabel-root-MuiInputLabel-root-selectLabel
// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined css-824mpj-MuiFormLabel-root-MuiInputLabel-root-selectLabel

export const SelectAssessments = ({ value, onChange, surveys, id = 'select', label, disabled }) => {
  const { classes } = useStyles();

  return (
    <FormControl disabled={disabled} className={classes.form}>
      <InputLabel
        className={classes.selectLabel}
        // style={{ top: '-8px' }}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        className={classes.select}
        value={value}
        label={label}
        onChange={(e) => onChange(e)}
        style={{ width: 320 }}
      >
        {surveys?.map((survey, i, array) => {
          const currentMonth = (new Date(survey?.created_at)).getMonth();
          const nextMonth = (new Date(array[i + 1]?.created_at)).getMonth();

          return (
            <MenuItem
              className={`${classes.option} ${classes[`month_${(new Date(survey?.created_at)).getMonth()}`]}`}
              value={survey.id}>
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z" fill="#003E6F" />
              </svg> */}
              <Moment format="MM/DD/YYYY">

                {survey?.created_at}
              </Moment>
              {currentMonth !== nextMonth && nextMonth ? <Box className={classes.option_separation}></Box> : null}
            </MenuItem>
          )
        })}

      </Select>
    </FormControl>
  )
}
