import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

export const color_action = "#FF551C";
export const color_flow = "#02CAD6";
export const color_ease = "#7910E9";
export const color_watch = "#FBD100";

//Green
export const start_green = "#2FEA9B";
export const end_green = "#7FDD53";

export const green_r = 47;
export const green_g = 234;
export const green_b = 155;

//Red
export const start_red = "#FF551C";
export const end_red = "#FF480A";

export const red_r = 104;
export const red_g = 72;
export const red_b = 10;

//Purple
export const start_purple = "#6800D6";
export const end_purple = "#9747FF";

export const purple_r = 104;
export const purple_g = 0;
export const purple_b = 204;

//Yellow
export const start_yellow = "#DABE35";
export const end_yellow = "#FBD100";

export const yellow_r = 218;
export const yellow_g = 190;
export const yellow_b = 53;

export const pdfStyles = StyleSheet.create({
    main_page: {
        marginTop: '24px',
        marginBottom: '20px',
        paddingLeft: '32px',
        paddingRight: '32px',
        height: '698px',
    },
    zone_page: {
        marginTop: '6px',
        marginBottom: '20px',
        paddingLeft: '32px',
        paddingRight: '32px',
        height: '716px',
    },
    title_text_32: {
        fontSize: '32px',
        fontFamily: 'Times-Roman',
        fontWeight: "normal",
    },
    title_text_30: {
        width: '100%',
        textAlign: 'center',
        fontSize: '30px',
        fontFamily: 'Times-Roman',
        fontWeight: "normal",
        marginBottom: '24px',
    },
    title_text_30_bold: {
        width: '100%',
        textAlign: 'center',
        fontSize: '30px',
        fontFamily: 'Times-Bold',
        fontWeight: "normal",
        marginBottom: '24px',
    },
    text_30: {
        fontSize: '30px',
        fontFamily: 'Times-Roman',
        fontWeight: "normal",
    },
    text_30_bold: {
        fontSize: '30px',
        fontFamily: 'Times-Bold',
        fontWeight: "normal",
    },
    text_font_24_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '24px',
        fontWeight: "normal"
    },
    text_font_24: {
        fontFamily: 'Times-Roman',
        fontSize: '24px',
        fontWeight: "normal"
    },
    text_font_16_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '16px',
        fontWeight: "normal"
    },
    text_font_16: {
        fontFamily: 'Times-Roman',
        fontSize: '16px',
        fontWeight: "normal"
    },
    text_font_14_italic: {
        fontFamily: 'Times-Italic',
        fontSize: '14px',
        fontWeight: "normal"
    },
    text_font_13_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '13px',
        fontWeight: "normal"
    },
    text_font_12: {
        fontFamily: 'Times-Roman',
        fontSize: '12px',
        fontWeight: "normal"
    },
    text_font_12_semi: {
        fontFamily: 'Times-Roman',
        fontSize: '12px',
        fontWeight: 'semibold'
    },
    text_font_12_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '12px',
        fontWeight: "normal"
    },
    text_font_8_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '8px',
        fontWeight: "normal"
    },
    text_font_8: {
        fontFamily: 'Times-Bold',
        fontSize: '8px',
        fontWeight: 'semibold'
    },
    text_font_12_Inter: {
        fontFamily: 'Times-Roman',
        fontSize: '12px',
    },
    text_font_16_Inter: {
        fontFamily: 'Times-Roman',
        fontSize: '16px',
    },
    text_font_12_Inter_Bold: {
        fontFamily: 'Times-Bold',
        fontSize: '12px',
    },
    text_font_8_Inter: {
        fontFamily: 'Times-Roman',
        fontSize: '8px',
        fontWeight: 'bold'
    },
    text_font_6_Inter: {
        fontFamily: 'Times-Roman',
        fontSize: '6px',
        fontWeight: 'bold'
    },
    text_font_8_Inter_bold: {
        fontFamily: 'Times-Bold',
        fontSize: '8px',
    },
})

