import React from "react";
import { ReactComponent as Logo } from '../../logo-main.svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  logo: {
  },
}));

export default (props) => {
  const { classes } = useStyles();

  return <Logo
    {...props}
    className={`${classes.logo} ${props.className}`}
  />;
};