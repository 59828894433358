import ClientIcon from "@mui/icons-material/People";
import { ClientShow } from "./client.show";
import { ClientList } from "./client.list";
import { ClientEdit } from "./client.edit";
import {
  Edit,
} from "react-admin";
export const clients = {
  list: ClientList,
  show: ClientShow,
  edit: ClientEdit,
  compare: <Edit>123123</Edit>,
  icon: ClientIcon
};
