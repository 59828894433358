import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function SimpleLoading() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}