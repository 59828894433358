import React from "react";
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";

import {
  fetchUtils, Admin,
  Resource, CustomRoutes,
} from "react-admin";
import LoginPage from "./pages/Login";
import LogoutButton from "./pages/LogoutButton";

import simpleRestProvider from "ra-data-simple-rest";

import { AuthProvider } from "./authProvider";

import { roles } from "./config";
import { coaches } from "./components/coaches";
import { clients } from "./components/clients";

import defaultStyles from './styles/default';


import { Route } from 'react-router-dom';
import ForgotPassword from './components/auth/forgot_password';
import ResetPassword from './components/auth/reset_password';

import ClientView from './components/clients/client.view';
import { createBrowserHistory as createHistory } from "history";

import { MyLayout } from "./layouts/DefaultLayout";
import LiaRecordPage from "./components/assessments/LIARecordPage";
import CompareProgressPage from "./components/assessments/CompareProgressPage";
import TestPage from "./components/assessments/TestPage";
import CoacheLayout from "./layouts/CoacheLayout";
import { QueryClient, QueryClientProvider } from "react-query";

const authProvider = AuthProvider();

const apiURL = process.env.REACT_APP_API_URL;
const queryClient = new QueryClient()
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url.replace(/\/undefined/gi, ""), options);
};

const myTheme = createTheme(defaultStyles);

const dataProvider = simpleRestProvider(`${apiURL}/api/v1`, httpClient);
const history = createHistory();
const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Admin
          // logoutButton={LogoutButton}
          layout={CoacheLayout}
          loginPage={LoginPage}
          theme={myTheme}
          dataProvider={dataProvider}
          authProvider={authProvider}
        >

          <Resource name="clients" {...clients} />

          {/* {permissions => [
          permissions === roles.ADMIN ?
            <Resource name="coaches" {...coaches} />
            : null
        ]} */}
          {permissions => {
            console.log(permissions);
            return [
              permissions === roles.ADMIN ?
                <Resource name="coaches" {...coaches} />
                : null,
              permissions === roles.COACH || permissions === roles.ADMIN ?
                <CustomRoutes>
                  <Route
                    exact
                    path="/clients/compare/:id"
                    element={<CompareProgressPage customClient />}
                  />
                </CustomRoutes>
                : null
            ]

          }}
          <Resource name="assessments" />
          <Resource name="profile" />
          <CustomRoutes noLayout  >
            <Route exact path="/forgot" element={<ForgotPassword />} />
            <Route
              exact
              path="/reset"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/client/:id/assessments/:assessmentId"
              element={<ClientView>
                <LiaRecordPage />
              </ClientView>}
            />
            <Route
              exact
              path="/compare-progress/:id/assessments"
              element={<ClientView>
                <CompareProgressPage />
              </ClientView>}
            />
            <Route
              exact
              path="/test/:id"
              element={<ClientView withoutPadding>
                <TestPage />
              </ClientView>}
            />
          </CustomRoutes>
        </Admin>
      </QueryClientProvider>

    </BrowserRouter>
  )
};

export default App;