import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import classNames from 'classnames';

export const CoacheSelect = ({
  setCoachId, coachValue, renderCoaches, inputValue, setInputValue, className, label
}) => {

  const [isOpenSelect, setIsOpenSelect] = useState(false);

  return (
    <Autocomplete
      className={classNames(className)}
      // disablePortal
      value={coachValue}
      id="combo-box-demo"
      options={renderCoaches}
      // sx={{ height: 48 }}
      open={isOpenSelect}
      // renderOption={() => {}}
      onFocus={() => {
        if (inputValue.length >= 3) {
          setIsOpenSelect(true)
        } else {
          setIsOpenSelect(false)
        }
      }}
      onBlur={() => {
        setIsOpenSelect(false)
        setInputValue('')
      }}
      renderInput={(params) => <TextField
        onChange={(e) => {
          setInputValue(e.target.value)
          if (e.target.value.length >= 3) {
            setIsOpenSelect(true)
          } else {
            setIsOpenSelect(false)
          }
        }}
        {...params}
        label={label || "Start typing to see results…"}
      />}
      onChange={(event, newValue) => {
        setCoachId(newValue?.id);
        setIsOpenSelect(false);
      }}
    />
  )
}
