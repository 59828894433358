import React from 'react';
import { Box } from '@mui/material';
import InformationCard from '../common/InformationCard';
import categories from '../../resources/categories';
import quadrants from '../../resources/quadrants';
import axis from '../../resources/axis';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    transition: theme.transitions.create('opacity', { duration: '.5s' }),
    pointerEvents: 'none',
  },
}));

const getInfo = (selectorType, selector, surveyResults) => {
  let item = {};
  let quadrant = '';

  switch (selectorType) {
    case 'category': {
      item = categories.find((item) => item.selector === selector) || {};
      quadrant = surveyResults.find((result) => result.selector === selector).quadrant;
      break;
    }
    case 'quadrant': {
      item = quadrants.find((item) => item.selector === selector) || {};
      quadrant = item.selector;
      break;
    }
    case 'axis': {
      item = axis.find((item) => item.selector === selector) || {};
      break;
    }
    default: {
    }
  }

  return {
    title: item.name,
    description: item.description,
    quadrant: quadrant,
  }
}

const InformationGroup = (({ selectorType, selector, surveyResults, isDrawerOpen, isHovering }) => {
  const { classes } = useStyles();

  if (isDrawerOpen) return null;

  const item = getInfo(selectorType, selector, surveyResults);
  return (
    <Box
      className={classes.informationCard}
      style={{ opacity: isHovering ? 1 : 0 }}
    >
      <InformationCard
        title={item.title}
        description={item.description}
        quadrant={item.quadrant}
      />
    </Box>
  );
});

export default InformationGroup;