import { UAParser } from 'ua-parser-js';
import mixpanel from 'mixpanel-browser';

const PRODUCTION_ENV = process.env.NODE_ENV === 'production';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: !PRODUCTION_ENV,
  persistence: 'localStorage',
  track_pageview: 'full-url',
  ignore_dnt: true,
});

const ua = UAParser(window.navigator.userAgent);
const properties = {
    "$browser": ua.browser.name,
    "$browser_version": ua.browser.version,
    "$device": ua.device.model,
    "$os": ua.os.name,
}

const withProductionGuard = (fn) => (...args) => {
  if (!PRODUCTION_ENV || !process.env.REACT_APP_MIXPANEL_TOKEN) return;

  return fn(...args);
};

const actions = {
  identify: withProductionGuard((id, props) => {
    mixpanel.identify(id);
    if (props) {
      mixpanel.people.set(props);
    }
  }),
  reset: withProductionGuard(() => {
    mixpanel.reset();
  }),
  track: withProductionGuard((name, props) => {
    const mergedProps = {
      ...properties,
      ...props,
    };
    mixpanel.track(name, mergedProps);
  }),
};

export let Mixpanel = actions;
