import React, { useState, useEffect } from "react";
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import SimpleLoading from './SimpleLoading';
import { create } from "../../charts/bar.all-categories";

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: '21px',
    fontWeight: 'bold',
  },
  categoryAverage: {
    fontWeight: 'normal',
  },
  categoryName: {
    textTransform: 'capitalize',
  },
}));

const AllCategoriesChart = React.memo(({ elementName, dimensionResult, average }) => {
  const { classes } = useStyles();

  if (!dimensionResult || dimensionResult.length === 0) return <SimpleLoading />;

  const options = create({
    series: Object.entries(dimensionResult).map(([key, result]) => ({
      name: result.name,
      y: result.value,
    })),
  });


  return (
    <Box
      className={classes.root}
    >
      <Box
        className={classes.title}
      >
        Average <span className={classes.categoryName}>{elementName}</span> across all life categories: {average.toFixed(2)}
      </Box>
      <HighchartsReact style={{ width: '100%' }} highcharts={Highcharts} options={options} />
    </Box>
  );
});

export default AllCategoriesChart;
