import red from '@mui/material/colors/red';

// Color names are from https://chir.ag/projects/name-that-color
const colors = {
  lightPurple: '#8F1EFF',
  lightTeal: '#40C4C9',
  lightOrange: '#EE6123',
  lightYellow: '#FFD106',
  porcelain: '#E5EBEA',
  lightPorcelain: '#EDF2F1',
  loblolly: '#BACBCF',
  iron: '#D9DFDE',
  shark: '#222323',
  persianPink: '#F792C3',
  cherokee: '#FCCC90',
  sulu: '#90ED7D',
  wildSand: '#F5F5F5',
  gallery: '#EEEEEE',
  black: '#000000',
  white: '#FFFFFF',
  c0c0: '#C0C0C0',
  azureRadiance: '#0689FB',
};

export const categoryTypes = {
  innerIndividual: colors.lightPurple,
  individualExpression: colors.lightTeal,
  relational: colors.lightOrange,
  communityExpression: colors.lightYellow,
};

export const quadrantTypes = {
  ease: colors.lightPurple,
  flow: colors.lightTeal,
  watch: colors.lightYellow,
  action: colors.lightOrange,
};

// export const quadrants;

const style = {
  palette: {
    primary: {
      main: "#182C3B"
    },
    secondary: {
      main: "#3694F9"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  colors: {
    ...colors,
    categoryTypes,
    quadrantTypes,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Poppins",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Poppins',
      },
    },
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white" // Some CSS
      }
    },
  }
};

export default style;