import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',

    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
  },
  title: {
    fontSize: '21px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.colors.azureRadiance,
    letterSpacing: 0,
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontSize: '20px',
    color: theme.colors.shark,
    letterSpacing: 0,
  },
}));

const SubTitle = (props) => {
  const { classes } = useStyles();

  return <Typography
    {...props}
    className={classes.subTitle}
  >
    {props.label}
  </Typography>
}

const InstructionBox = ({ isCoach }) => {
  const { classes } = useStyles();

  return (
    <Box
      className={`${classes.root} ${isCoach && classes.root_is_coach}`}
      padding={4}
    >
      <Box
        className={classes.title}
      >
        INSTRUCTIONS:
      </Box>
      <SubTitle
        label="1. Hover over items on this page, like the words Flow, Action, Ease, Watch, Fulfillment, and any of the life categories for a detailed description of each."
      />
      <SubTitle
        label="2. Click on the bubbles to access your Life Health Dashboards."
      />
    </Box>
  );
}

export default InstructionBox;