import {
  ResourceContextProvider,
  useGetOne,
  RecordContextProvider,
  useGetList
} from "react-admin";

import { useParams } from 'react-router-dom';

import { AssessmentShow } from '../assessments/assessment.show';

import ClientAssessment from '../assessments/ClientAssessment';
import LiaRecordPage from "../assessments/LIARecordPage";
import AppContext from "../../contexts/AppContext";
import { useEffect, useState } from "react";
import { useWindowSizes } from "../../hooks/useWindowSize";

const ClientView = ({ withoutPadding, children }) => {
  let params = useParams();
  const id = params.id
  const { data, isLoading, error, refetch } = useGetOne("clients", { id });
  const { data: data2 } = useGetList("coaches", { pagination: { page: 1, perPage: 400 } });
  const [width] = useWindowSizes();
  const [isOpenSidebar, setIsOpenSidebar] = useState(width > 1535 ? true : false);

  useEffect(() => {
    // setIsOpenSidebar(true)

  }, [])

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <ResourceContextProvider value="clients" >
      <RecordContextProvider record={{ ...data, refetch: '123' }}>
        <AppContext.Provider value={{
          refetch,
          coaches: data2,
          isOpenSidebar,
          setIsOpenSidebar,
        }}>
          <AssessmentShow withoutPadding={withoutPadding} id={decodeURIComponent(params.id)}>
            {children}
          </AssessmentShow>
        </AppContext.Provider>

      </RecordContextProvider>
    </ResourceContextProvider>
  )
}

export default ClientView;