import * as React from 'react';
import { Children, isValidElement, useCallback } from 'react';
import PropTypes from 'prop-types';
// import {
//   useListContext,
//   useResourceContext,
//   Identifier,
//   RaRecord,
//   SortPayload,
//   useTranslate,
// } from 'ra-core';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
// import clsx from 'clsx';
import { DatagridClasses, DatagridHeaderCell, useDatagridContext } from 'ra-ui-materialui';
// import ExpandAllButton from 'ra-ui-materialui/src/list/datagrid/ExpandAllButton';
// import DatagridHeaderCell from 'ra-ui-materialui/src/list/datagrid/DatagridHeaderCell';
import { useListContext, useResourceContext, useTranslate } from 'react-admin';
import classNames from 'classnames';
// import ExpandAllButton from 'ra-ui-materialui/src/list/datagrid/ExpandAllButton';

// import DatagridHeaderCell from './DatagridHeaderCell';
// import { DatagridClasses } from './useDatagridStyles';
// import ExpandAllButton from './ExpandAllButton';
// import { useDatagridContext } from './useDatagridContext';

/**
 * The default Datagrid Header component.
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export const DatagridHeader = (props) => {
  const {
    children,
    className,
    hasExpand = false,
    hasBulkActions = false,
    isRowSelectable,
  } = props;
  const resource = useResourceContext(props);
  const translate = useTranslate();
  const { sort, data, onSelect, selectedIds, setSort } = useListContext(
    props
  );
  const { expandSingle } = useDatagridContext();

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        sort.field === newField
          ? sort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order;

      setSort({ field: newField, order: newOrder });
    },
    [sort.field, sort.order, setSort]
  );

  const updateSort = setSort ? updateSortCallback : null;

  const handleSelectAll = useCallback(
    event =>
      onSelect(
        event.target.checked
          ? selectedIds.concat(
            data
              .filter(
                record => !selectedIds.includes(record.id)
              )
              .filter(record =>
                isRowSelectable
                  ? isRowSelectable(record)
                  : true
              )
              .map(record => record.id)
          )
          : []
      ),
    [data, onSelect, isRowSelectable, selectedIds]
  );

  const handleUnSelectAll = useCallback(
    event =>
      onSelect([]),
    [onSelect]
  );

  const selectableIds = Array.isArray(data)
    ? isRowSelectable
      ? data
        .filter(record => isRowSelectable(record))
        .map(record => record.id)
      : data.map(record => record.id)
    : [];


  return (
    <TableHead className={classNames(className, DatagridClasses.thead)}>
      <TableRow
        className={classNames(DatagridClasses.row, DatagridClasses.headerRow)}
      >
        {hasExpand && (
          <TableCell
            padding="none"
            className={classNames(
              DatagridClasses.headerCell,
              DatagridClasses.expandHeader
            )}
          >
            {/* {!expandSingle ? (
              <ExpandAllButton
                resource={resource}
                ids={data.map(record => record.id)}
              />
            ) : null} */}
          </TableCell>
        )}
        {hasBulkActions && selectedIds && (
          <TableCell
            padding="checkbox"
            className={DatagridClasses.headerCell}
          >
            <Checkbox
              inputProps={{
                'aria-label': translate(
                  'ra.action.select_all',
                  { _: 'Select all' }
                ),
              }}
              className="select-all"
              indeterminate={selectedIds.length > 0 && data.length !== selectedIds.length}
              color="primary"
              checked={
                selectedIds.length > 0 &&
                selectableIds.length > 0 &&
                selectableIds.every(id =>
                  selectedIds.includes(id)
                )
              }
              onChange={data.length === selectedIds.length || selectedIds.length === 0 ? handleSelectAll : handleUnSelectAll}
              onClick={e => {
                if (selectedIds.length > 0) {


                }
                e.stopPropagation()
              }}
            />
          </TableCell>
        )}
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridHeaderCell
              className={classNames(
                DatagridClasses.headerCell,
                `column-${(field.props).source}`
              )}
              sort={sort}
              field={field}
              isSorting={
                sort.field ===
                ((field.props).sortBy ||
                  (field.props).source)
              }
              key={(field.props).source || index}
              resource={resource}
              updateSort={updateSort}
            />
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

DatagridHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ASC', 'DESC']),
  }),
  data: PropTypes.arrayOf(PropTypes.any),
  hasExpand: PropTypes.bool,
  hasBulkActions: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  isRowExpandable: PropTypes.func,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  setSort: PropTypes.func,
};


DatagridHeader.displayName = 'DatagridHeader';
