import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { createElement } from 'react';
import DefaultIcon from '@mui/icons-material/ViewList';

import {
  useResourceDefinitions,
  useGetResourceLabel,
  useCreatePath,
} from 'react-admin';
import { MenuItemLink } from 'ra-ui-materialui';
import { useAppContext } from '../../contexts/AppContext';
import { useWindowSizes } from '../../hooks/useWindowSize';

// import { MenuItemLink } from './MenuItemLink';

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    padding: '8px 16px',
    borderRadius: '4px',
    color: '#232323',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'start',
    transition: '0.5s',
    cursor: 'pointer',
    marginBottom: '4px',
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: `rgba(0, 0, 0, 0.04)`,
    },
  },
  menuItemActive: {
    background: '#003e6e !important',
    color: 'white !important',
  },
}));
export const SidebarLink = ({ to, isMenuItemLink, isCurrentPage, text = '', onClick, icon, hidden }) => {

  const { classes } = useStyles();
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const { setIsOpenSidebar } = useAppContext()
  const [width] = useWindowSizes();
  if (hidden) {
    return null;
  }

  if (isMenuItemLink) {


    return <MenuItemLink
      to={createPath({
        resource: text,
        type: 'list',
      })}
      className={`${classes.menuItem} ${isCurrentPage && classes.menuItemActive}`}
      state={{ _scrollToTop: true }}
      primaryText={<>{getResourceLabel(text, 2)}</>}
    // leftIcon={icon}
    >
      {icon}

      <Typography>
        {getResourceLabel(text, 2)}

      </Typography>
    </MenuItemLink>

  }


  if (!to) {
    return <Box
      className={`${classes.menuItem}`}
      onClick={onClick}
    >
      {icon}
      <Typography>{text}</Typography>
    </Box>

  }
  return (
    <>
      <Link
        to={to}
        onClick={() => {
          if (width < 1535) {
            setIsOpenSidebar(false)

          }
        }}
        className={`${classes.menuItem} ${isCurrentPage && classes.menuItemActive}`}
      >
        {icon}

        <Typography>{text}</Typography>
      </Link>

    </>
  )
}
