import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { pdfStyles } from './pdfstyle';
import { useEffect } from 'react';


export const PDFFooter = (props) => {

  return (
    <View style={{ marginLeft: '32px', marginRight: '32px', position: 'relative', marginBottom: '20px', marginTop: 'auto' }} render={({ pageNumber }) => <>
      <View style={{ width: '100%', height: '1px', backgroundColor: "black" }}></View>
      <View style={{ width: "100%", textAlign: 'center', marginTop: '8px' }}>
        <Text
          style={pdfStyles.text_font_12}
        >www.MyLifeInventory.com</Text>
      </View>
      <Text style={{ position: 'absolute', right: '0px', bottom: '0px', fontSize: '12px' }}>{pageNumber}</Text>
    </>}>

    </View>
  )
}
export default PDFFooter;