import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ElementDescription from '../common/ElementDescription';
import ElementalDashboard from '../common/ElementalDashboard';
import { useDataProvider } from "react-admin";
import InformationCard from '../common/InformationCard';
import quadrants from '../../resources/quadrants';
import NotesGroup from '../common/NotesGroup';
import AllCategoriesBarChart from '../common/AllCategoriesBarChart';
import NotesIterator from '../common/NotesIterator';
import { Loading } from "ra-ui-materialui";
import zIndex from '@mui/material/styles/zIndex';
import { ItemLoader } from '../ui/ItemLoader';
import { getCategoryLabel } from '../../utils/getCategoryLabel';
import { calculateAvgHealthPercentage } from '../../utils/calculateAvgHealthPercentage';

const categoryState = []

const drawerWidth = '50%';
const drawerMinWidth = 656;

const useStyles = makeStyles()((theme) => ({
  root: {
    width: drawerWidth,
    minWidth: drawerMinWidth,
    flexShrink: 0,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth})`,
    minWidth: `calc(100% - ${drawerMinWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  lifeCategoryHeader: {
    borderBottom: '1px solid #E2E2E2',
    padding: '12px 24px',
  },
  header: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  title: {
    color: '#003E6F',
    fontSize: '24px',
  },
  subtitle: {
    fontSize: '18px',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    // minWidth: drawerMinWidth,
    right: '50%',
    // width: '100%',
    boxShadow: 'none',
    overflow: 'auto',
    // [theme.breakpoints.down('xl')]: {
    //   width: '100%',
    //   minWidth: '100%',
    // },
  },
  lifeCategory: {
    justifyContent: 'flex-start',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '0%',
    overflow: 'auto',
    transition: '0.3s',

    height: '100%',
    background: 'white',
    zIndex: 1000,
    transform: 'translateX(-100%)',

    [theme.breakpoints.down('xl')]: {
      transform: 'translateX(0%)',
    },
  },
  lifeCategory_open: {
    width: '100%',


  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  closeButton: {
    padding: 0,
    display: 'flex',
    gap: '12px',
    fontSize: '12px',
    alignItems: 'center',
  },
  category: {
    display: 'flex',
    justifyContent: 'start',
    gap: '12px',
    marginLeft: 'auto',
    fontSize: '16px',
    flexDirection: 'column',
    padding: '16px 24px',
    fontWeight: '600',
  },
  category_percentage_wrapper: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    height: '10px',
  },
  category_percentage: {
    height: '10px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#cccccc',
    position: 'relative',
  },
  category_percentage_main: {
    height: '10px',
    borderRadius: '4px',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    background: 'black'
  },
  category_percentage_number: {
    position: 'relative',
    fontSize: '20px',
    fontWeight: '600',
    bottom: '16px'

  },
  info: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '0 24px',
    gap: '16px',
    overflow: 'hidden',
    marginTop: '24px',
  },
  zone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    // padding: '16px 24px',
    // fontWeight: '600',
  },
  zone_title: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#EE613F',
  },
  zone_text: {
    fontSize: '14px',
    color: '#667085',
  },
  elements: {
    padding: '16px',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    height: 'fit-content'
  },
  elements_title_box: {
    marginBottom: '20px',
    fontSize: '13px',
    color: '#667085',
    fontWeight: '500',
    span: {
      color: '#000',
      textTransform: 'capitalize',
    },
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },
  elements_title_percentage: {
    fontSize: '13px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600'

  },
  gauge: {
    display: 'flex',
    gap: '12px',
    padding: '4px 8px',
    fontSize: '10px',
    fontWeight: '600',
    color: '#667085',
    alignItems: 'center',
    marginBottom: '16px',
    // cursor: 'pointer',
  },
  gauge_label: {
    flexGrow: '1',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    fontWeight: '600',
    color: '#232323',
  },
  gauge_label_red: {
    color: '#FF551C',
  },
  gauge_scale: {
    width: '100%',
    position: 'relative',
    height: '5.5px',
    borderRadius: '5px',
    backgroundColor: '#F3F3F3',

    img: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      borderRadius: '5px',
      height: '5.5px',
    }
  },
  notes: {
    padding: '24px',
  },
  bottomContent: {
    '& > *': {
      width: '50%',
      textAlign: 'justify',

      '&:nth-of-type(n+2)': {
        // marginLeft: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        borderLeft: `1px solid ${theme.colors.gallery}`,
      },
    }
  },
  allCategoriesBarChart: {
    // minWidth: theme.spacing(50),
    width: '100%',

    [theme.breakpoints.up('xl')]: {
      margin: '0 32px',
      padding: '0',
    },
  },
  '@media (min-width: 1024px)': {
    appBarShift: {
      marginRight: drawerMinWidth,
    },
    content: {
      marginRight: -drawerMinWidth,
    },
  },
  categoryInfo: {
    color: theme.colors.shark,
    fontWeight: 'bold',
  },
  informationCard: {
    [theme.breakpoints.down('xl')]: {
      padding: '0'
    },
  },
  none: {
    display: 'none',
  }
}));

export default function CapabilityLevelDrawer({
  open, assessmentId, category, categoryName, quadrant, userName, categoryLevel, coachNotes, lifeJournal,
  handleDrawerClose, handleCoachNotesUpdate, handleLifeJournalUpdate, elementName, getColor, categoryDetails
}) {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const [currentQuadrant, setCurrentQuadrant] = useState(quadrant);
  const [elementDetails, setElementDetails] = useState(null);
  const elementNameRef = useRef();
  elementNameRef.current = elementName;
  const [isLoading, setIsLoading] = useState(true);
  const [dimensionResult, setDimensionResult] = useState({});
  const [isAnimatedComplete, setIsAnimatedComplete] = useState(false)
  useEffect(() => {
    setIsAnimatedComplete(false)
    setTimeout(() => { setIsAnimatedComplete(true) }, 300)

  }, [open])

  const [loading, setLoading] = useState(true);
  return (
    <Box
      className={`${classes.lifeCategory} ${open ? classes.lifeCategory_open : null}`}
    >

      {open && isAnimatedComplete && <>
        <Box
          className={classes.lifeCategoryHeader}
          display="flex"
          alignItems="center"
        >
          <Box
            className={classes.header}
          >
            <Box
              className={classes.title}
            >
              Category Details
              3

            </Box>
          </Box>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.closeButton}
          >
            Close
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.589844 11.09L5.16984 6.5L0.589844 1.91L1.99984 0.5L7.99984 6.5L1.99984 12.5L0.589844 11.09Z" fill="#003E6F" />
            </svg>
          </IconButton>
        </Box>
        <Box
          className={classes.info}
        >
          <Box className={classes.elements}>
            <Box className={classes.elements_title_box}>
              <Box><span>Average {getCategoryLabel(elementName)} </span> across all life categories</Box>
              <Box className={classes.elements_title_percentage}>
                {/* {categoryDetails?.health_percentage}% */}
                {calculateAvgHealthPercentage(dimensionResult)}%

              </Box>

            </Box>
            {loading ? <ItemLoader /> :
              Object.entries(dimensionResult).map(([key, element], index) => {
                const color = getColor(element.value);
                console.log(element.name , element.value)
                return (
                  <Box
                    className={`${classes.gauge}`}
                    key={index}
                  >
                    <Box
                      className={`${classes.gauge_label} ${key === category && classes.gauge_label_red}`}

                    >
                      {element.name}

                      <Box className={classes.gauge_scale}>
                        {key === category ? <>
                          <img src={`/${color}_back.jpg`} style={{ width: '100%' }} alt='' />
                          <img
                            src={`/${color}.jpg`}
                            className={classes.gauge_scale_main}
                            style={{ width: `${element.value * 10}%` }}
                            alt=''
                          />
                        </> : <img
                          src={`/${color}.jpg`}
                          className={classes.gauge_scale_main}
                          style={{ width: `${element.value * 10}%` }}
                          alt=''
                        />}
                        {/* <Box style={{ background: color }}>
                            </Box> */}
                      </Box>
                    </Box>
                    {element.value * 10}%
                  </Box>
                )
              })
            }

          </Box>
          <Box className={classes.zone}>
            <Typography className={classes.zone_text}>
              {elementName &&
                <ElementDescription
                  assessmentId={assessmentId}
                  category={category}
                  elementName={elementName}
                  userName={userName}
                  setDimensionResult={setDimensionResult}
                  setLoadingParrent={setLoading}
                />}
            </Typography>
          </Box>


        </Box>
      </>}
    </Box>
  );
}