export async function getChartData({
  assessmentId,
  dataProvider,
  setSurveyResults,
  enchanceSurveyResults,
  setAssessment,
  setCoachNotes = () => { },
  setLifeJournal = () => { },
}) {
  const { data: response } = await dataProvider.getOne('assessments', { id: assessmentId });
  setSurveyResults(
    enchanceSurveyResults(response.SurveyResults)
  );
  console.log(response.SurveyResults)
  setAssessment(response);
  setCoachNotes(response.summary_notes);
  setLifeJournal(response.client_life_journal);
}