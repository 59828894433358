import React, { useState, useCallback, useEffect } from "react";
import parse from 'html-react-parser';
import {
  Box, Typography, Tooltip,
  IconButton, Popover, Button
} from "@mui/material";
import ReactQuill from "react-quill";
import { useDebouncedCallback } from 'use-debounce';
import {
  useDataProvider, TextField,
  ReferenceField,
  DateField,
  useNotify,
  useDelete,
  useGetIdentity,
  useRefresh,
  useUpdate
} from 'react-admin';
import { makeStyles } from 'tss-react/mui';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.colors.shark,
    width: '100%',
    boxSizing: 'border-box',
    // boxShadow: '0px 3px 6px #00000029',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    border: '2px solid rgba(174,174,174, 0.50)',
    padding: '16px 64px 16px 12px',
    position: 'relative',
    fontFamily: '"Roboto", sans- serif',
  },
  title: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#232323',
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'translateY(-60%)',
    marginLeft: '16px',
    padding: '5px',
    background: 'white',

    span: {
      fontSize: '12px',
      fontWeight: 'bold',
    }
  },
  more: {
    position: 'absolute',
    top: '15px',
    right: '5px'

  },
  description: {
    fontSize: '16px',
    color: theme.colors.azureRadiance,
    textAlign: 'left',
  },
  notes: {
    padding: '0',
    fontSize: '14px',
    color: '#667085',
    wordWrap: 'break-word',

    '& > p': {
      margin: 0,
    },
  },
  emptyNotes: {
    fontStyle: 'italic',
  },
  textInput: {
    padding: '0 !important',
  },
  newTextArea: {
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    height: '32px',
    fontFamily: 'inherit'
  },
  buttonSend: {
    maxWidth: '140px'
  },
  textArea: {
    '& .ql-container': {
      backgroundColor: theme.colors.white,
      height: '150px',
    }
  },
  form: {
    '& .MuiCardContent-root': {
      padding: 0,

      '& .MuiFormControl-root': {
        margin: 0,
      },
    },
  },
}));

const Notes = ({ title, isCoach, description, note, editable, assessmentId, source, autosave, refetch = () => { } }) => {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const [text, setText] = useState(note?.text || '');
  const [isHover, setHover] = useState(false);
  const notify = useNotify();
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const [edit, setEditable] = useState(editable || (isCoach && !text));

  useEffect(() => {
    setText(note?.text || '');
  }, [note?.text]);


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const refresh = useRefresh();
  const [deleteNote] = useDelete();
  const [updateNote] = useUpdate();

  const handleDelete = () => {
    setAnchorEl(null);

    if (source === 'summary_notes') {
      const data = {};
      data[source] = '';
      if (source.includes("notes")) {
        data['text'] = ''
      }
      dataProvider.update(
        `assessments/${assessmentId}/${source}/${note.id}`,
        { data }
      ).then(res => {
        setEditable(true);
        notify('Note deleted', { type: 'info', undoable: false });
        refresh();
        refetch();
      })
      return;
    }

    deleteNote(
      `assessments/${assessmentId}/${source}`,
      { id: note.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify('Note deleted', { type: 'info', undoable: false });
        },
      }
    );
  };

  const isNoteOwner = identity?.id === note?.user_id || editable;

  const handleSave = useCallback((value) => {
    const data = {};
    data[source] = value;
    if (source.includes("notes")) {
      data['text'] = value
    }

    // if there is a note we are updating
    if (note) {
      setAnchorEl(null);
      dataProvider.update(
        `assessments/${assessmentId}/${source}/${note.id}`,
        { data }
      ).then(res => {
        setEditable(false);
        notify("Note was updated succesfully.", { type: "success", undoable: "false", autoHideDuration: 1500 });
        refresh();
        refetch();
        setText(value)

      })
    } else {
      dataProvider.create(
        `assessments/${assessmentId}/${source}`,
        { data }
      ).then(res => {
        notify("Note was added succesfully.", { type: "success", undoable: "false" });
        refresh();
        refetch();


        if (!autosave) {
          setText("")
        }
      })
    }


  }, [assessmentId, source, dataProvider]);

  const debounceCallback = useDebouncedCallback(handleSave, 1000);
  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setText(value);
    if (autosave) debounceCallback(value);

  }, [autosave, debounceCallback]);
  return (
    <Box className={classes.root}>
      {title ? <Box className={classes.title} sx={{
      }}>
        {title}
      </Box> : null}
      <Box marginTop={1}>
        {
          edit || (note.text === null && isCoach)
            ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <textarea
                  className={classes.newTextArea}
                  value={text}
                  onChange={handleChange}
                  placeholder={description}
                />
              </Box>
              <Button
                type="button"
                variant="contained"
                disabled={text?.replace("<p><br></p>", "") === ""}
                onClick={e => {
                  handleSave(text)
                }}
                className={classes.buttonSend}
              >
                {!note ? "Add note" : "Update Note"}
              </Button>

            </Box>
            :
            <Box>
              <Box
                sx={{
                  visibility: isNoteOwner ? 'visible' : 'hidden',
                }}
                className={classes.more}
              >
                <Tooltip title="More actions">
                  <IconButton size="small" aria-describedby={id} onClick={handlePopoverClick}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}

                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Button variant="text" sx={{ p: 1 }} onClick={() => setEditable(true)}>Update Note</Button>
                    <Button variant="text" sx={{ p: 1, color: 'red' }} onClick={handleDelete}>Delete Note</Button>
                  </Box>

                </Popover>
              </Box>
              <Box
                color="text.secondary"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {note?.updated_at && <Box className={classes.title}>
                  <ReferenceField
                    record={note}
                    resource="users"
                    reference="users"
                    source="user_id"
                  >
                    <TextField source="first_name" variant="body1" />
                  </ReferenceField>
                  {' '}
                  <Typography component="span" variant="body1">
                    added a note on{' '}
                  </Typography>
                  <DateField
                    source="created_at"
                    record={note}
                    variant="body1"
                    showTime
                    locales="en"
                    options={{
                      dateStyle: 'full',
                      timeStyle: 'short',
                    }}
                  />{' '}
                </Box>}

                <Box
                  sx={{
                    // visibility: isNoteOwner ? 'visible' : 'hidden',
                  }}
                  className={classes.more}
                >
                  <Tooltip title="More actions">
                    <IconButton size="small" aria-describedby={id} onClick={handlePopoverClick}>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}

                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Button variant="text" sx={{ p: 1 }} onClick={() => setEditable(true)}>Update Note</Button>
                      <Button variant="text" sx={{ p: 1, color: 'red' }} onClick={handleDelete}>Delete Note</Button>
                    </Box>

                  </Popover>
                </Box>
              </Box>

              <Box sx={{
                // borderRadius: '10px',
                display: 'flex',
                alignItems: 'stretch',
              }}
              >
                <Box
                  className={`${classes.notes} ${note.text ? '' : classes.emptyNotes}`}
                  // fontSize="body1.fontSize"
                  lineHeight={1.3}
                  width="100%"
                >
                  {parse(note.text || 'Nothing to display.')}
                </Box>

              </Box>

            </Box>
        }
      </Box>
    </Box>
  )

};


export default Notes;