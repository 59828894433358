
import React from 'react';
import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';
import { pdfStyles, color_action, color_ease, color_flow, color_watch } from "./pdfstyle";

export const LastPage = (props) => {
    return(
        <View>
            <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName}/>
            <View style={{width:'100%', height:'698px', textAlign:'center', alignItems:'center', paddingTop:'275px', marginTop:'24px'}}>
                <Text style={pdfStyles.text_font_16_bold}>{props.firstName}{","}</Text>
                <Text style={[pdfStyles.text_font_12, {marginTop:'8px'}]}>here's to you living your best life. If you haven't yet booked an Illumination Life Map</Text>
                <Text style={pdfStyles.text_font_12}>review with a certified LIA coach, do that here; www.mylifeinventory.com</Text>
                <Text style={[pdfStyles.title_text_32, {marginTop:'64px'}]}>Thank you!</Text>
            </View>
            <PDFFooter/>
        </View>
    )
}

export default LastPage;