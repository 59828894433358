export const getQuadrantColor = (quadrant) => {
  if (quadrant === 'action') {
    return '#EE613F'

  }
  if (quadrant === 'flow') {
    return '#40C4C9'

  }
  if (quadrant === 'watch') {
    return '#FFD106'

  }
  if (quadrant === 'ease') {
    return '#8F1EFF'

  }

  return 'linear-gradient(0.25turn, #F0753F, #F6B091)'

}