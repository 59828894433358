import react, { useEffect, useState } from "react";
import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { pdfStyles, color_action, color_ease, color_flow, color_watch } from "./pdfstyle";
import ZoneCard from "./ZoneCard";
import ActionGraph from "./Graph/ActionGraph";
import FlowGraph from "./Graph/FlowGraph";
import EaseGraph from "./Graph/EaseGraph";
import WatchGraph from "./Graph/WatchGraph";

import Slide from "./Slide";

export const ZonePageMain = (props) => {
  const { zoneList, rating, title, zoneStatusArray, lists, theme } = props;
  let countOfZoneFirst = 0;
  let countOfZone = 0;
  const [color, setColor] = useState("");
  useEffect(() => {
    switch (title) {
      case "Action":
        setColor(color_action);
        break;
      case "Ease":
        setColor(color_ease);
        break;
      case "Flow":
        setColor(color_flow);
        break;
      case "Watch":
        setColor(color_watch);
        break;
    }
  })


  return (
    <>
      <Page size="A4" wrap>
        <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
        <View style={pdfStyles.main_page}>
          <View style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '24px' }}>
            <Text style={pdfStyles.text_30}>Your</Text>
            <Text style={[pdfStyles.text_30_bold, { color: color }]}>
              {" "}{title}{" "}
            </Text>
            <Text style={pdfStyles.text_30}>Zone</Text>
          </View>
          {zoneList.length !== 0 &&
            <>
              <View style={{ display: 'flex', flexDirection: 'row' }}>

                <View style={{ paddingRight: '20px' }}>
                  <Text style={{ ...pdfStyles.text_font_12, marginBottom: '24px' }}>These life categories are in the
                    <Text style={pdfStyles.text_font_12_bold}>{" "}{title} Zone.</Text>
                  </Text>

                  {zoneList.map((data, index) => (
                    <View key={title + data.id} style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
                      <Text style={pdfStyles.text_font_12}>{index + 1}{". "}</Text>
                      <Text style={pdfStyles.text_font_12}>{data.category.replace('&amp;', '&')}</Text>
                    </View>
                  ))}

                </View>

                <View>
                  <View style={{ width: '310px', height: '240px' }}>
                    {title === "Action" &&
                      <ActionGraph lists={lists} rating={rating} theme={theme} />}
                    {title === "Flow" &&
                      <FlowGraph lists={lists} rating={rating} theme={theme} />}
                    {title === "Ease" &&
                      <EaseGraph lists={lists} rating={rating} theme={theme} />}
                    {title === "Watch" &&
                      <WatchGraph lists={lists} rating={rating} theme={theme} />}
                  </View>
                </View>

              </View>

              <View style={{ marginBottom: '16px', marginTop: "16px" }}>
                <Text style={[pdfStyles.text_font_12_bold]}>View the bar charts below to gain deeper perspective of each life category.</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {zoneList.map((data, index) => {
                  return rating.map(rate => {

                    if (data.selector === rate.selector) {

                      countOfZoneFirst++;
                      if (countOfZoneFirst <= 6)
                        return (
                          <ZoneCard key={title + data.id + rate.selector} data={rate} />
                        );
                    }
                  });
                })}
              </View>
            </>
          }
          {zoneList.length === 0 &&
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>

              <View style={{ width: '226px', paddingRight: '10px' }}>
                <Text style={pdfStyles.text_font_12}>
                  Your
                  {" "}{title}{" Zone "}
                  is empty from any of your life category.

                </Text>
                <Text style={[pdfStyles.text_font_12_bold, { marginTop: '8px', marginBottom: '8px' }]}>Note:</Text>
                {title === "Action" &&
                  <>
                    <View style={[pdfStyles.text_font_12, { width: '226px' }]}>
                      <Text>
                        Niceee! No categories in your ActionZone means one thing, you are in FLOW!
                      </Text>
                    </View>
                  </>}
                {title === "Flow" &&
                  <>
                    <View>


                      <Text style={pdfStyles.text_font_12}>
                        <Text style={pdfStyles.text_font_12_bold}>
                          {props.firstName}{" "}
                        </Text>
                        you currently show no life categories in Flow.
                      </Text>
                      <Text style={pdfStyles.text_font_12}>
                        Just the act of bringing this notion to consciousness can energetically begin a shift toward moving your life to a place that you desire and deserve.
                        With this said, it is highly recommended that you consider establishing a support system as soon as possible. When a person is not experiencing flow in any categories, it creates additional barriers for them to access the energy needed to move forward.
                        Establishing a community of support is very important during your next steps.
                        You are not alone, there are a number of resources (free and for service) available to you.
                      </Text>
                    </View>
                  </>}
                {title === "Ease" &&
                  <>
                    <View style={[pdfStyles.text_font_12_Inter, { width: '200px' }]}>

                      <Text style={pdfStyles.text_font_12}>
                        Okay, so you aren't living on eas-y street yet.
                        Don't worry, just by being here, you are on
                        your way. Read any Life Insights in the Watch
                        and Action zone and remember two things;
                      </Text>
                      <Text style={pdfStyles.text_font_12}>
                        1. Never understimate the power of small
                        incremental changes towards that which
                        you love; this is how everything in the
                        world has been achieved.
                      </Text>
                      <Text style={pdfStyles.text_font_12}>
                        2. Receive and seek out the community that
                        will support you, you are not alone,
                        something it requires looking in another
                        direction to find what you need.
                      </Text>
                    </View>
                  </>}
                {title === "Watch" &&
                  <>
                    <View style={[pdfStyles.text_font_12_Inter, { width: '226px' }]}>
                      <Text style={pdfStyles.text_font_12}>
                        Nothing to watch, doesn't mean you should binge out on Netflix.
                        Focusing on your other zones will bring you the continuous happiness you seek (yoda even said so).
                      </Text>
                    </View>
                  </>}
              </View>
              <View>
                <View style={{ width: '310px', height: '240px' }}>
                  {title === "Action" &&
                    <ActionGraph lists={lists} rating={rating} theme={theme} />}
                  {title === "Flow" &&
                    <FlowGraph lists={lists} rating={rating} theme={theme} />}
                  {title === "Ease" &&
                    <EaseGraph lists={lists} rating={rating} theme={theme} />}
                  {title === "Watch" &&
                    <WatchGraph lists={lists} rating={rating} theme={theme} />}
                </View>
              </View>
            </View>}
        </View>
        <PDFFooter />
      </Page>
      {
        zoneList.length > 6 &&
        <Page wrap size='A4'>
          <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
          <View style={pdfStyles.main_page}>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '16px' }}>
              {zoneList.length > 6 && zoneList.map((data) => {
                return rating.map((rate, index) => {
                  if (data.selector === rate.selector) {
                    countOfZone++;
                    if (countOfZone > 6)
                      return (
                        <ZoneCard key={title + data.id + rate.selector} data={rate} />
                      );
                  }
                  return null;
                });
              })}
            </View>
          </View>
          <PDFFooter />

        </Page>
      }
    </>
  )
}

export default ZonePageMain;