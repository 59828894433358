import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  FilterButton,
  CreateButton,
  ExportButton,
  FileInput,
  FileField,
  TopToolbar,
  Button,
  useListContext
} from "react-admin";

import { ImportButton } from "react-admin-import-csv";
import { ListToolbar, NameField, PostPagination, useListStyles } from "../clients/client.list";
import classNames from "classnames";

const config = {
  logging: true,
  disableCreateMany: true
}


const ListActions = (props) => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
    <ImportButton {...props} {...config} parseConfig={{ dynamicTyping: true }} />
  </TopToolbar>
);


const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput label="Coaches" source="id" reference="coaches" allowEmpty>
    <SelectInput optionText={optionRenderer} />
  </ReferenceInput>
];

export const CoachList = props => {
  const { classes } = useListStyles();
  const [lifeScoreState, setLifeScoreState] = useState(null)
  return (
    <List
      // filters={postFilters}
      actions={<ListToolbar isCoachList />}
      pagination={<PostPagination setLifeScoreState={setLifeScoreState} />}
      perPage={10}
      {...props}
      className={classNames(classes.table, classes.table_coaches, {
        // [classes.table_admin]: isAdmin,
        // [classes.table_coach]: !isAdmin,

      })}
    >
      <RenderDataGrid />
    </List>
  )
};

const RenderDataGrid = ({ }) => {
  const listContext = useListContext();

  return <Datagrid
    rowClick="edit"
    rowSx={(record, i, ar) => {
      return listContext.selectedIds.includes(record.id) ? { bgcolor: '#2196F334' } : {}
    }}
  >
    <TextField source="id" />
    <NameField label="Full Name" />
    <EmailField source="email" />
  </Datagrid>

}
