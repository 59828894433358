import React from "react";
import { Box } from "@mui/material";
import CategoryBubbleChart from "../clients/CategoryBubbleChart";

const LifeInventoryAssessmentChart = ({ surveyResults, handleCategoryClick, handleItemHover }) => {
  return (
    <Box
      className='chart-container'
      onMouseLeave={() => handleItemHover(false)}
    >
      {surveyResults &&
        Object.keys(surveyResults).length > 0 ? (
        <CategoryBubbleChart
          series={surveyResults}
          handleCategoryClick={handleCategoryClick}
          handleItemHover={handleItemHover}
        />
      ) : null}
    </Box>
  );
};

export default LifeInventoryAssessmentChart;