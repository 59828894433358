export default [
  {
    name: "Fulfillment",
    selector: "fulfillment",
    description: "Fulfillment is the X-axis which runs left to right. Fulfillment represents your degree of overall life satisfaction. The further to the right, the higher your life satisfaction, the further to the left, the lower your life satisfaction for that category.",
  },
  {
    name: "Self-Ranked Priority",
    selector: "priority",
    description: "Self-Ranked Priority is the Y-axis which runs top to bottom. Priority represents the order that you ranked your life categories, from highest (#1, top) to lowest (#13, bottom).",
  },
];