import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, Box } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatFullName } from '../../utils/formatFullName';

import {
  useDataProvider,
  Notification,
  ReferenceInput,
  AutocompleteInput,
  Form,
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
} from "react-admin";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CssBaseline, Button, Link, Grid } from "@mui/material";

import { Typography } from "@mui/material";

import { makeStyles, withStyles } from 'tss-react/mui';
import { useAppContext } from '../../contexts/AppContext';
import { CoacheSelect } from './CoacheSelect/CoacheSelect';
import { Mixpanel } from '../../analytics';

const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;

const useStyles = makeStyles()(theme => ({
  player: {
    display: 'block',
  },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const ChangeCoachDialog = ({ changeCoach }) => {

  const refresh = useRefresh();
  const record = useRecordContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [updateCoach] = useUpdate();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [coach_id, setCoachId] = useState(record.coach_id || 0);
  const [inputValue, setInputValue] = useState('');
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const { coaches, refetch } = useAppContext();
  const currentCoach = coaches?.find(item => item.id === coach_id) || null;
  const renderCoaches = useMemo(() => {
    if (inputValue.length <= 2) {
      return []

    }
    const newCoaches = coaches.filter(item => {
      return item?.role !== 1 && `${item.first_name} ${item.last_name}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
      .map((item) => ({ label: `${item.first_name} ${item.last_name}`, id: item.id, role: item?.role }));
    return newCoaches;

  }, [coaches, inputValue])



  function handleClose(close) {
    if (close) {
      setOpen(false);
      setCoachId(record.coach_id)
    }
  }
  const handleSubmit = async values => {
    try {
      const data = {}
      data["coach_id"] = coach_id || ''
      await dataProvider.update(
        `clients/${record.id}`,
        { data }
      );

      const previousCoach = coaches?.find(item => item.id === record.coach_id);
      const newCoach = coaches?.find(item => item.id === coach_id);

      let eventName = 'Remove';
      if (coach_id && !record.coach_id) {
        eventName = 'Add';
      } else if (coach_id && record.coach_id) {
        eventName = 'Replace';
      }

      Mixpanel.track(`${eventName} Coach`, {
        client_id: record.id,
        client_full_name: formatFullName(record.firstName, record.lastName),
        previous_coach_id: record.coach_id,
        previous_coach_full_name: previousCoach ? formatFullName(previousCoach.first_name, previousCoach.last_name) : null,
        new_coach_id: coach_id,
        new_coach_full_name: newCoach ? formatFullName(newCoach.first_name, newCoach.last_name) : null,
      });

      notify("Coach succesfully changed.", { type: "success", undoable: "false" });
      handleClose(true)
      refresh()
      // refetch()
      setCoachId(coach_id || 0);
    } catch (e) {
      notify(e.message, "warning");
    }
  };

  useEffect(() => {
    const play = changeCoach > 0;
    setOpen(play);
  }, [changeCoach]);

  const coachValue = useMemo(() => `${currentCoach?.first_name || ''} ${currentCoach?.last_name || ''}`.trim(),
    [currentCoach?.first_name, currentCoach?.last_name])

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={false}
      onClose={() => handleClose()}
    >
      <DialogContent >
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(true)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Form classes={classes.grid}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >

            <Typography variant="h5" mb={"1em"} >
              Add or Remove a LIA Certified Coach
            </Typography>
            {/* 
            <Autocomplete
              // disablePortal
              value={coachValue}
              id="combo-box-demo"
              options={renderCoaches}
              // sx={{ width: 300 }}
              open={isOpenSelect}
              // renderOption={() => {}}
              onFocus={() => {
                if (inputValue.length >= 3) {
                  setIsOpenSelect(true)
                } else {
                  setIsOpenSelect(false)
                }
              }}
              onBlur={() => {
                setIsOpenSelect(false)
                setInputValue('')
              }}
              renderInput={(params) => <TextField
                onChange={(e) => {
                  setInputValue(e.target.value)
                  if (e.target.value.length >= 3) {
                    setIsOpenSelect(true)
                  } else {
                    setIsOpenSelect(false)
                  }
                }}
                {...params}
                label="Start typing to see results…"
              />}
              onChange={(event, newValue) => {
                setCoachId(newValue?.id);
                setIsOpenSelect(false);
              }}
            /> */}
            <CoacheSelect
              setCoachId={setCoachId}
              coachValue={coachValue}
              renderCoaches={renderCoaches}
              inputValue={inputValue}
              setInputValue={setInputValue} />
            {/* <ReferenceInput
              label="Coach"
              source="coach_id"
              reference="coaches"
              sort={{ field: 'first_name', order: 'ASC' }}
            >

              <AutocompleteInput
                optionText={optionRenderer}
                onChange={(event, value) => {
                  setCoachId(event)
                }}
              />
            </ReferenceInput> */}
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              *Note: Selecting a coach authorizes the coach to access all of your Life Maps. They will be notified of your Life Map.
            </Typography>
          </Box>

          <Box mt="1em" />
          <Box
            display="flex"
            justifyContent="center"
          >
            <Button
              // disabled={!coach_id}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update Coach
            </Button>
          </Box>
          <Notification />

        </Form>
      </DialogContent>
    </Dialog >
  );
};

export default ChangeCoachDialog;