// in src/ClientLayout.js
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useAppContext } from '../../contexts/AppContext';

const useStyles = makeStyles()(theme => ({
  header: {
    background: 'linear-gradient(0.25turn, #003E6E, #000F0F)',
    display: 'flex',
    padding: '16px 32px',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    position: 'relative',
    height: '64px',
    color: 'white',
    fontSize: '20px',
  },
  button: {
    maxWidth: '40px',
    maxHeight: '40px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    marginRight: '16px',
    cursor: 'pointer',

  },
  account: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    marginLeft: 'auto',
    fontSize: '16px',
  },

  account_img_container: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    img: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
      objectFit: 'cover',

    }
  }

}));

const NewHeader = ({
  children,
  userName,
  props
}) => {
  const { classes } = useStyles();
  const { setIsOpenSidebar } = useAppContext();
  return (
    <>
      <Box className={classes.header}>
        <button
          className={classes.button}
          onClick={() => { setIsOpenSidebar(prev => !prev) }}
        >
          <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.77539H19M1 1.77539H19M1 13.7754H19" stroke="#fff"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <Typography>Life Inventory Assessment</Typography>

        <Box className={classes.account}>
          <Typography>{userName}</Typography>
          <Box className={classes.account_img_container}>
            <img src='/Smiling_Face.png' alt='profile_image' />

          </Box>

        </Box>
      </Box>
    </>
  );
};

export default NewHeader;