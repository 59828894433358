import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#003E6F',
    color: '#FFFFFF',
    maxWidth: 'max-content',
    padding: '8px 16px',
    fontSize: theme.typography.pxToRem(16),
  },
}));