import React, { useEffect, useState, useRef } from 'react';

import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';
import SimpleLoading from '../common/SimpleLoading';
import parse from 'html-react-parser';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    // display: 'flex',
    // maxHeight: '100%',
    overflowY: 'auto',
  },
  title: {
    color: theme.colors.shark,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  description: {
    marginTop: '10px',
    fontSize: '14px',
  },
  content: {
    overflowY: 'auto',
    paddingRight: '24px',
    scrollBehavior: 'smooth',
  },
  content_loading: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
  openJournal: {
    backgroundColor: theme.colors.lightTeal,
    height: '28px',
    color: '#FFFFFF',
    fontSize: '11px',

    '&:hover': {
      backgroundColor: theme.colors.lightTeal,
    },
  },
}));

const ElementDescription = ({ userName, setLoadingParrent = () => {}, assessmentId, category, elementName, setDimensionResult = () => { } }) => {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const [elementInfo, setElementInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const contentRef = useRef(null);


  useEffect(() => {
    async function fetchData() {
      try {
        const { data: res } = await dataProvider.getOne(`assessments/${assessmentId}/categories/${category}/element`, { id: elementName });
        setElementInfo(res);
        setDimensionResult(res.dimension_result)
        setLoading(false);
        setLoadingParrent(false)
        if (contentRef) contentRef.current.scrollTop = 0;
      } catch (e) {
        setElementInfo({});
        setLoading(false);
        setLoadingParrent(false)
      }
    }
    setLoading(true);
    setLoadingParrent(true)
    fetchData();
  }, [assessmentId, category, elementName, contentRef]);

  return (
    <Box
      display="flex"
      // height={232}
      className={classes.root}
      flexDirection="column"
      flexGrow={1}
    >
      <Box
        // maxHeight={412}
        className={`${classes.content} ${loading && classes.content_loading}`}
        ref={contentRef}
      >
        <Box
          className={classes.title}
        >
          {userName}, your results indicate that you are:
        </Box>
        {loading ? <SimpleLoading /> : <Box
          className={classes.description}
          marginTop={2}
        >
          {parse(elementInfo.text || '')}
        </Box>}

      </Box>
    </Box>
  )
};

export default ElementDescription;