import React from "react";
import { Box, CircularProgress } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

import { Loading } from "ra-ui-materialui";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    zIndex: 100000,
  }
}));

export const FullscreenLoading = props => {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Loading />
    </Box>
  );
}

export default FullscreenLoading;