import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Typography } from '@mui/material';
import { useGetIdentity, usePermissions } from "react-admin";
import { roles } from "../../config";
import Notes from './Notes';

const useStyles = makeStyles()((theme) => ({
  root: {
    '& > *': {
      width: '100%',
      textAlign: 'justify',

      '&:nth-of-type(n+2)': {
        marginLeft: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        borderLeft: `1px solid ${theme.colors.gallery}`,
      },
    },

    // [theme.breakpoints.down('xl')]: {
    //   display: 'none'
    // },
  }, comments_title: {
    color: '#003E6E',
    fontSize: '32px',
    fontWeight: '600',
  },
  comments_subtitle: {
    color: '#878787',
    fontSize: '16px'
  },
}));

export const NotesGroup = ({
  props, refetch, assessmentId, editable, coachNotes, lifeJournal,
  isDrawerOpen, handleCoachNotesUpdate, handleLifeJournalUpdate
}) => {
  const { classes } = useStyles();
  const { isLoading, permissions } = usePermissions();
  // if (!loaded) return null;
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const isCoach = identity?.role === roles.ADMIN || identity?.role === roles.COACH;
  let note = { text: coachNotes, user_id: isCoach ? identity?.id : 0 }

  return isLoading
    ? (<div>Waiting for permissions...</div>) :
    (
      <>
        <Box className={classes.comments}>
          <Box className={classes.comments_text}>
            <Typography className={classes.comments_title}>Comments</Typography>
            <Typography className={classes.comments_subtitle}>Summary reflections, general guidance and recommendations here.</Typography>

          </Box>
        </Box>
        <Box
          display="flex"
          className={classes.root}
          paddingY={3}
          flexGrow={1}
          {...props}
          overflow="auto"
          minHeight={0}
        >

          <Box
            display="flex"
          >

            <Notes
              title={isCoach ? "Coach" : "Individuals"}
              description="Summary reflections, general guidance and recommendations here."
              note={note}
              editable={false}
              assessmentId={assessmentId}
              source="summary_notes"
              // autosave={!isDrawerOpen}
              handleUpdate={handleCoachNotesUpdate}
              refetch={refetch}
              isCoach={isCoach}
            />
          </Box>

        </Box>
      </>
    );
}

export default NotesGroup;