import React, { useEffect, useMemo, useState } from 'react';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { Accordion, AccordionDetails, Link, AccordionSummary, Box, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecordContext } from 'react-admin';
import Moment from 'react-moment';


const useStyles = makeStyles()((theme) => ({
  box: {
    // width: '80px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },
  label: {
    fontSize: '16px',
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
  },
  mediaCircle: {
    transform: 'rotate(-90deg)',
    width: '240px',
    height: '240px',

    path: {
      transform: 'scale(2.5)'
    }
  },
  circleContainer: {
    position: 'relative',
    width: '240px',
    height: '240px',
    maxWidth: '240px',
    maxHeight: '240px',
    zIndex: '1',
    display: 'none',

    [theme.breakpoints.up('xl')]: {
      display: 'none'
    },
  },
  text: {
    color: '#667085',
    fontSize: '12px',
    position: 'absolute',
    top: '70px',
    left: '50%',
    transform: 'translateX(-38%)',
  },
  progress: {
    position: 'absolute',
    top: '86px',
    left: '50%',
    transform: 'translateX(-38%)',
    fontWeight: '700',

    color: '#101828',
    fontSize: '30px'
  },
  progress_main: {
    [theme.breakpoints.down('xl')]: {
      // display: 'none'
    },
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    // gap: '100px',
    paddingBottom: '24px',
    borderBottom: '1px solid #EAECF0',
    marginBottom: '24px',
    marginTop: '32px',

    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  headerItems: {
    display: 'flex',
    gap: '12px',
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: 'transparent',
    gap: '10px',
    height: '40px',
    minWidth: '40px',
    padding: '0 12.5px',
    position: 'relative',
  },
  headerButton_wrapper: {
    position: 'relative',
    cursor: 'pointer',
  },
  headerButtonAccordion: {
    position: 'absolute',
    background: 'white',
    transform: 'translateY(0%)',
    maxHeight: '0px',
    overflow: 'auto',
    transition: '1s',
    padding: '0 8px',
    zIndex: 20,

  },
  headerButtonAccordion_open: {
    maxHeight: '200px',
    padding: '8px',

  },
  headerButtonSmall: {
    width: '40px',

    svg: {
      flexShrink: '0'
    }
  },
  headerText: {
    fontSize: '18px',
    fontWeight: '700'
  },
  accordion: {
    position: 'absolute'
  }
}));

const CircleMediaItem = ({ progress }) => {
  const filling = useMemo(() => {
    return (progress * 125.6) / 100;
  }, [progress])
  const { classes } = useStyles();

  return (
    <div className={classes.circleContainer}>
      <svg className={classes.mediaCircle} viewbox="0 0 100 100">
        <path fill="none" stroke-linecap="round" stroke-width="10" stroke="#EDF7FF"
          stroke-dasharray="125.6,125.6"
          d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80"/>

        <path fill="none" stroke-linecap="round" stroke-width="10.4" stroke="#0689FB"
          stroke-dasharray={`${filling},250.2`}
          d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80"/>
      </svg>
      <span className={classes.text}>Life Score</span>
      <span className={classes.progress}>{progress}%</span>
    </div>
  )
}

const HeaderMediaItem = () => {
  const { classes } = useStyles();
  const record = useRecordContext();
  const surveys = record.Surveys ? [...record.Surveys].reverse() : [];
  const userId = record.id;
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const surveyId = window.location.pathname.split('/')[4]
  const currentSurveys = surveys.find(item => `${item.id}` === surveyId)
  return (
    <div className={classes.headerContainer}>
      <span className={classes.headerText}>LIA Results</span>
      <div className={classes.headerItems}>
        <span className={classes.headerButton_wrapper}>
          <Box className={classes.headerButton} onClick={() => { setIsOpenAccordion(prev => !prev) }}>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.3333 1.66666V4.99999M5.66667 1.66666V4.99999M1.5 8.33332H16.5M3.16667 3.33332H14.8333C15.7538 3.33332 16.5 4.07952 16.5 4.99999V16.6667C16.5 17.5871 15.7538 18.3333 14.8333 18.3333H3.16667C2.24619 18.3333 1.5 17.5871 1.5 16.6667V4.99999C1.5 4.07952 2.24619 3.33332 3.16667 3.33332Z" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <Moment format='ll'>
              {currentSurveys?.created_at}
            </Moment>
          </Box>
          <Box className={`${classes.headerButtonAccordion} ${isOpenAccordion && classes.headerButtonAccordion_open}`}>
            {surveys.map((survey, key) => (
              <Box
                key={key}
                className={classes.subMenuItem}
              >
                {(() => {
                  const pathname = `/client/${userId}/assessments/${survey.id}`;
                  const isCurrent = pathname === window.location.pathname;

                  return (
                    <MenuItem
                      component={Link}
                      className={classes.link}
                      href={pathname}
                      display="block"
                      selected={isCurrent}
                    >
                      <Moment format='ll'>
                        {survey?.created_at}
                      </Moment>
                    </MenuItem>
                  )
                })()}
              </Box>
            ))}
          </Box>
        </span>
        {/* <Accordion
          expanded={expanded === 'lifeMapResults'}
          onChange={handleChange('lifeMapResults')}
        >
          <AccordionSummary
            aria-controls="lifeMapResultsbh-content"
            id="lifeMapResultsbh-header"
          >
            <Typography>LIA Results</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordion}>
            {surveys.map((survey, key) => (
              <Box
                key={key}
                className={classes.subMenuItem}
              >
                {(() => {
                  const pathname = `/client/${userId}/assessments/${survey.id}`;
                  const isCurrent = pathname === window.location.pathname;

                  return (
                    <MenuItem
                      component={Link}
                      className={classes.link}
                      href={pathname}
                      display="block"
                      selected={isCurrent}
                    >
                      <Moment format="DD/MM/YYYY">
                        {survey?.created_at}
                      </Moment>
                    </MenuItem>
                  )
                })()}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion> */}
        {/* <button className={`${classes.headerButton} ${classes.headerButtonSmall}`}>
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </button> */}
      </div>

    </div>
  )
}

export default function TotalLifeHealth(props) {
  const { classes } = useStyles();
  const current = props.percentage || 0;
  const { isCoach } = props
  const speed = 0;
  const [progress, setProgress] = React.useState(0);
  const tooltip = 'Your total life score represents your average level of fulfillment across all 13 categories.';
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= current) {
          clearInterval(timer);
          return current;
        }
        else {
          return prevProgress + 1;
        }
      });
    }, speed);
  }, [current]);

  return (
    <Box
      className={classes.box}
    >
      {/* <HeaderMediaItem /> */}
      {/* <CircleMediaItem progress={progress} /> */}
      <Tooltip className={classes.progress_main} title={tooltip} placement="left">
        <Box>
          <CircularProgressWithLabel value={progress} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export function TotalLifeHealthMobile(props) {
  const { classes } = useStyles();
  const current = props.percentage || 0;
  const { isCoach } = props
  const speed = 0;
  const [progress, setProgress] = React.useState(0);
  const tooltip = 'Your total life score represents your average level of fulfillment across all 13 categories.';
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= current) {
          clearInterval(timer);
          return current;
        }
        else {
          return prevProgress + 1;
        }
      });
    }, speed);
  }, [current]);

  return (
    <Box
      className={classes.box}
    >
      <HeaderMediaItem />
      <CircleMediaItem progress={progress} />
    </Box>
  );
};