import react, { useEffect, useState } from "react";
import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { pdfStyles, color_action, color_ease, color_flow, color_watch } from "./pdfstyle";
import ZoneCard from "./ZoneCard";
import ActionGraph from "./Graph/ActionGraph";
import FlowGraph from "./Graph/FlowGraph";
import EaseGraph from "./Graph/EaseGraph";
import WatchGraph from "./Graph/WatchGraph";

import Slide from "./Slide";
import { getCategoryLabel } from "../../utils/getCategoryLabel";

export const ZonePage = (props) => {
  const { zoneList, rating, title, zoneStatusArray, lists, theme } = props;
  let countOfZoneFirst = 0;
  let countOfZone = 0;
  const [color, setColor] = useState("");
  useEffect(() => {
    switch (title) {
      case "Action":
        setColor(color_action);
        break;
      case "Ease":
        setColor(color_ease);
        break;
      case "Flow":
        setColor(color_flow);
        break;
      case "Watch":
        setColor(color_watch);
        break;
    }
  })


  return (
    <>
      {
        zoneList.map((data) => {
          const rate = rating.find(rate => data.selector === rate.selector);
          const zonedata = zoneStatusArray.find(zoneStatus => zoneStatus.category === data.selector);

   

          return zonedata && rate && zonedata.data.map((zone, index) => {
            let str = zone.data.text.replace(/<\/?p>/g, '').replace(/<\/?strong>/g, '');
            let dimenions = ["character", "family", "financial", "intellectual_creative", "leisure", "love", "mental_emotional_health", "parenting", "physical", "social_contributions", "social_life", "spiritual", "work"]
            let average = 0;
            {
              dimenions.map(dimen => {
                average = zone.data.dimension_result[dimen]?.value + average;
              })
            }


            let isNewPage = false;
            return (
              <>
                <Page key={title + data.id + index} wrap size="A4">
                  <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
                  <View style={{ ...pdfStyles.main_page, height: 'auto', marginBottom: '0' }}>
                    {zone.data.element === "happiness" &&
                      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={pdfStyles.text_font_24_bold}>{data.category.replace('&amp;', '&')}{" "}</Text>
                        <View style={{ width: '1px', height: "24px", backgroundColor: 'black' }}></View>
                        <Text style={[pdfStyles.text_font_24_bold, { color: '#FF551C' }]}>
                          {" "}{rate.percentage}{"%"}
                        </Text>
                      </View>
                    }
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: zone.data.element === "happiness" ? '24px' : '6px' }}>

                      {zone.data.average === 10 &&
                        <Image src={'/Smiling_Face.png'} style={{ width: '24px', height: '24px', marginRight: '16px' }} />}

                      {zone.data.average >= 8 && zone.data.average < 10 &&
                        <Image src={'/Smiling_Face_With_Sunglasses.png'} style={{ width: '24px', height: '24px', marginRight: '16px' }} />}

                      {zone.data.average >= 6 && zone.data.average < 8 &&
                        <Image src={'/Person_In_Lotus_Position.png'} style={{ width: '24px', height: '24px', marginRight: '16px' }} />}

                      {zone.data.average < 6 &&
                        <Image src={'/Red_Heart.png'} style={{ width: '24px', height: '24px', marginRight: '16px' }} />}

                      <View>
                        <View style={{ width: '452px', display: 'flex', flexDirection: 'row' }}>
                          <Text style={pdfStyles.text_font_12_Inter_Bold}>{data.category.replace('&amp;', '&')}{" "}</Text>
                          <View style={{ width: '1px', height: "14px", backgroundColor: 'black' }}></View>
                          <Text style={pdfStyles.text_font_12_Inter_Bold}>{" "}{getCategoryLabel(zone.data.element)}{" "}</Text>
                        </View>
                        <Slide scale={zone.data.average} width="450pt" height="10pt" radius="8pt" />
                      </View>
                      <Text style={[pdfStyles.text_font_8_Inter_bold, { marginLeft: '16px' }]}>
                        {+(zone.data.average * 10).toFixed(2)}{"%"}</Text>
                    </View>

                    <View style={{
                      flexShrink: '0',
                      marginTop: '12px',
                      borderColor: '#C4C4C4',
                      borderRadius: '8px',
                      borderWidth: "1px",
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      paddingBottom: '12px',
                    }}>
                      <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '12px' }}>
                        <Text style={[pdfStyles.text_font_16_Inter, { color: '#FF480A' }]}>Average {getCategoryLabel(zone.data.element)} across all{" "}</Text>
                        <Text style={[pdfStyles.text_font_16_Inter, { color: '#959595' }]}>life categories</Text>
                        <Text style={[pdfStyles.text_font_16_Inter, { color: 'black' }]}>{" "}{(average * 10 / 13).toFixed(2)}{"%"}</Text>
                      </View>
                      {/* /////////////// */}
                      {dimenions.map((dimen, index) => {
                        // if (zone.data.dimension_result[dimen]?.value) {
                        return (
                          <View key={title + data.id + index} style={{ width: '50%', paddingLeft: '16px', paddingTop: '4px' }}>
                            <View>
                              <Text style={pdfStyles.text_font_8_Inter_bold}>{zone.data.dimension_result[dimen]?.name}</Text>
                              <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <Slide
                                  width={"180px"}
                                  height={"8px"}
                                  scale={zone.data.dimension_result[dimen]?.value}
                                  type={data.selector !== dimen ? 'grey' : ""}
                                  radius={"8px"}
                                />
                                <Text style={[pdfStyles.text_font_8_Inter_bold, { paddingLeft: '4px' }]}>{zone.data.dimension_result[dimen]?.value * 10}{"%"}</Text>
                              </View>
                            </View>
                          </View>
                        )
                        // }
                      })}
                    </View>


                  </View>
                  {/* <View wrap style={{ ...pdfStyles.main_page, flexGrow: '1', height: 'auto', marginTop: '12px' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Text style={pdfStyles.text_font_12_bold}>{props.firstName}{" "}</Text>
                      <Text style={pdfStyles.text_font_12}>
                        as you read the insights below as yourself, "What feels true for you?" What insights are most valuable
                      </Text>
                    </View>
                    <Text style={pdfStyles.text_font_12}>
                      here?
                    </Text>
                    <Text style={[pdfStyles.text_font_12_bold, { marginTop: '12px' }]}>Your results indicates you are:</Text>
                    {
                      str.split("It can serve you to reflect")[0].split('\n').map((item) => {
                        return <Text
                          wrap
                          style={{ ...pdfStyles.text_font_12, marginTop: '6px', fontSize: '10px' }}
                          render={({ subPageNumber }) => {

                            if (subPageNumber === 2 && !isNewPage) {
                              isNewPage = true;
                              return <View>

                                <Text>{subPageNumber}{item}</Text>
                              </View>
                            }
                            return <Text>
                              {item}
                            </Text>
                          }}
                        >
                          {item}
                        </Text>
                      })
                    }
                    
                  </View> */}
                  <View style={{
                    paddingLeft: '32px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                    paddingRight: '32px',
                    marginTop: '12px'


                  }}>

                    <Text style={{
                      ...pdfStyles.text_font_12,
                    }}>
                      <Text style={{
                        ...pdfStyles.text_font_12_bold
                      }}>{props.firstName}{" "}</Text>
                      as you read the insights below as yourself, "What feels true for you?" What insights are most valuable here?
                    </Text>
                  </View>
                  <Text style={{
                    ...pdfStyles.text_font_12,
                    paddingLeft: '32px',
                    paddingRight: '32px'
                  }}>
                  </Text>
                  <Text style={[pdfStyles.text_font_12_bold, {
                    marginTop: '12px', paddingLeft: '32px',
                    paddingRight: '32px'
                  }]}>Your results indicates you are:</Text>
                  {
                    str.split("It can serve you to reflect")[0].split('\n').map((item) => {
                      return <Text
                        wrap
                        style={{
                          ...pdfStyles.text_font_12, marginTop: '4px', fontSize: '10px', paddingLeft: '32px',
                          paddingRight: '32px'
                        }}
                        render={({ subPageNumber }) => {

                          if (subPageNumber === 2 && !isNewPage) {
                            isNewPage = true;
                            return <View style={{ 'marginTop': '40px' }}>
                              <PDFFooter />
                              <Text>{subPageNumber}{item}</Text>
                            </View>
                          }
                          return <Text>
                            {item}

                          </Text>
                        }}
                      >
                        {item}
                      </Text>
                    })
                  }

                  <PDFFooter />
                </Page>
                {str.includes("It can serve you to reflect on the elements at play") &&
                  <Page wrap size={"A4"}>
                    <PDFHeader resultsDate={props.resultsDate} firstName={props.firstName} />
                    <View style={pdfStyles.main_page}>
                      <Text style={pdfStyles.text_font_12}>
                        It can serve you to reflect on the elements at play in making you successfully manage your time so that you may
                      </Text>
                      <Text style={pdfStyles.text_font_12}>
                        replicate these elements in other less successful areas, and/or, if you fall out of alignment in this area, you’ll
                      </Text>
                      <Text style={pdfStyles.text_font_12}>have record of reflection on what to come back into.</Text>
                    </View>
                    <PDFFooter />
                  </Page>}
              </>
            );
          });
        })
      }

    </>
  )
}

export default ZonePage;