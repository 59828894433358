import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font, pdf } from '@react-pdf/renderer';
import { pdfStyles, color_action, color_ease, color_flow, color_watch } from "./pdfstyle";
import Slide from "./Slide";
export const ZoneCard = (props) => {
  const zoneData = props.data;
  let zonetitle;
  let zonePercentage = props.data.percentage;

  switch (zoneData.selector) {
    case "financial":
      zonetitle = "Financial";
      break;
    case "social_life":
      zonetitle = "Social";
      break;
    case "work":
      zonetitle = "Work";
      break;
    case "parenting":
      zonetitle = "Immediate Family";
      break;
    case "family":
      zonetitle = "Extend Family";
      break;
    case "love":
      zonetitle = "Love";
      break;
    case "leisure":
      zonetitle = "Leisure"
      break;
    case "character":
      zonetitle = "Character";
      break;
    case "spiritual":
      zonetitle = "Spiritual";
      break;
    case "physical":
      zonetitle = "Physical Health";
      break;
    case "intellectual_creative":
      zonetitle = "Talent";
      break;
    case "social_contributions":
      zonetitle = "Contribution";
      break;
    case "mental_emotional_health":
      zonetitle = "Emotional"
      break;
  }
  return (
    <View style={{ width: '170px', height: '195px', border: '1px', borderColor: '#F3F3F3', borderRadius: '8px', padding: '16px', marginBottom: '10px' }}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Text style={[pdfStyles.text_font_12_Inter, { color: '#A6A6A6' }]}>{zonetitle}</Text>
        <View style={{ width: '1px', height: '12px', marginLeft: '4px', marginRight: '4px', backgroundColor: '#A6A6A6' }}></View>
        <Text style={[pdfStyles.text_font_12_Inter, { color: '#A6A6A6' }]}>{zonePercentage}{"%"}</Text>
      </View>
      <View style={{ marginTop: '10px' }}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '43px' }}>
          <Image src={'/Smiling_Face.png'} style={{ width: '16px', height: '16px' }} />
          <View style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Text style={pdfStyles.text_font_8_Inter_bold}>Happiness Level</Text>
            <Slide scale={zoneData.ratings[0].scale} width="90px" height="6px" radius="6px" />
          </View>
          <Text style={pdfStyles.text_font_8_Inter_bold}>
            {zoneData.ratings[0].scale * 10}{"%"}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', height: '43px' }}>
          <Image src={'/Alarm_Clock.png'} style={{ width: '16px', height: '16px' }} />
          <View style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Text style={pdfStyles.text_font_8_Inter_bold}>Time Alignment</Text>
            <Slide scale={zoneData.ratings[1].scale} width="90px" height="6px" radius="6px" />
          </View>
          <Text style={pdfStyles.text_font_8_Inter_bold}>
            {zoneData.ratings[1].scale * 10}{"%"}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', height: '43px' }}>
          <Image src={'/Person_In_Lotus_Position.png'} style={{ width: '16px', height: '16px' }} />
          <View style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Text style={pdfStyles.text_font_8_Inter_bold}>Engagement Level</Text>
            <Slide scale={zoneData.ratings[2].scale} width="90px" height="6px" radius="6px" />
          </View>
          <Text style={pdfStyles.text_font_8_Inter_bold}>
            {zoneData.ratings[2].scale * 10}{"%"}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', height: '43px' }}>
          <Image src={'/Smiling_Face_With_Sunglasses.png'} style={{ width: '16px', height: '16px' }} />
          <View style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Text style={pdfStyles.text_font_8_Inter_bold}>Capability Level</Text>
            <Slide scale={zoneData.ratings[3].scale} width="90px" height="6px" radius="6px" />
          </View>
          <Text style={pdfStyles.text_font_8_Inter_bold}>
            {zoneData.ratings[3].scale * 10}{"%"}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px', height: '43px' }}>
          <Image src={'/Red_Heart.png'} style={{ width: '16px', height: '16px' }} />
          <View style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <Text style={pdfStyles.text_font_8_Inter_bold}>Emotional Energy</Text>
            <Slide scale={zoneData.ratings[4].scale} width="90px" height="6px" radius="6px" />
          </View>
          <Text style={pdfStyles.text_font_8_Inter_bold}>
            {zoneData.ratings[4].scale * 10}{"%"}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default ZoneCard;