export default [
  {
    name: "Flow",
    selector: "flow",
    description: "<strong>Celebrate</strong> your abundance<br /><strong>Ask:</strong> what can I learn here and apply in other areas of my life.<br /><strong>Please keep doing</strong> what you are doing; it's working.<br /><strong>Share and serve</strong> what you've learned; we are meant to share, and when we do, our abundance multiplies; how can you <strong>help others</strong> achieve the same level of abundance?<br /><strong>Pay Gratitude</strong> for the flow that you are experiencing pay attention to all the factors and people that have supported you to get here.",
  },
  {
    name: "Action",
    selector: "action",
    description: "<strong>Take Action:</strong> focus your energy on moving these most important categories to total fulfillment.<br /><strong>Focus:</strong> focus your vital energy (your limited time and money) on doing what matters most: this zone represents your highest priority categories; they deserve to be in total abundance.<br /><b>Ask:</b> what do I need to support me in achieving my highest level of fulfillment in this area. Seek help and build resources: consider how you can build your capabilities, what do you need to learn, who can you lean in to?",
  },
  {
    name: "Watch",
    selector: "watch",
    description: "<b>Pay Attention:</b> these aren't your most important categories, yet all things are connected, so 'watch' these categories.<br /><br />The message here is not to try to do everything; your energies are likely better spent on items in your action zone...BUT you'll know best.<br /><br /><b>Look for Easy Wins:</b> what are the small things that you can change, start doing, stop doing, that doesn't take up a lot of your time and would help move these categories into greater ease.",
  },
  {
    name: "Ease",
    selector: "ease",
    description: "<strong>Celebrate</strong> your abundance<br /><strong>Ask:</strong> what can I learn here and apply in other areas of my life.<br /><strong>Please keep doing</strong> what you are doing; it's working.<br /><strong>Share and serve</strong> what you've learned; we are meant to share, and when we do, our abundance multiplies; how can you <strong>help others</strong> achieve the same level of abundance?<br /><strong>Pay Gratitude</strong> for the flow that you are experiencing pay attention to all the factors and people that have supported you to get here.",
  },
];