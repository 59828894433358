import React, { useState, useRef, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ElementDescription from '../common/ElementDescription';
import ElementalDashboard from '../common/ElementalDashboard';
import { useDataProvider } from "react-admin";
import InformationCard from '../common/InformationCard';
import quadrants from '../../resources/quadrants';
import NotesGroup from '../common/NotesGroup';
import AllCategoriesBarChart from '../common/AllCategoriesBarChart';
import NotesIterator from '../common/NotesIterator';
import { Loading } from "ra-ui-materialui";
import CapabilityLevelDrawer from './CapabilityLevelDrawer';
import quadrantsContent from '../../resources/quadrants';
import parse from 'html-react-parser';
import { HtmlTooltip } from '../common/HtmlTooltip';
import CapabilityCompareLevelDrawer from './CapabilityCompareLevelDrawer';
import { getCategoryLabel } from '../../utils/getCategoryLabel';
import { getCategoryImage } from '../../utils/getCategoryImage';
import { getCategoryColor } from '../../utils/geCategoryColor';
import { getQuadrantColor } from '../../utils/getQuadrantColor';
const drawerWidth = '40%';
const drawerMinWidth = 656;

const useStyles = makeStyles()((theme) => ({
  root: {
    // width: drawerWidth,
    // minWidth: drawerMinWidth,
    height: '100%',

    flexShrink: 0,
    overflowY: 'scroll'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth})`,
    // minWidth: `calc(100% - ${drawerMinWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  lifeCategoryHeader: {
    borderBottom: '1px solid #E2E2E2',
    padding: '12px 24px',
  },
  header: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  title: {
    color: '#003E6F',
    fontSize: '24px',
  },
  subtitle: {
    fontSize: '18px',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    // minWidth: drawerMinWidth,
    // overflowX: 'scroll',
    overflowY: 'visible',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  lifeCategory: {
    // backgroundColor: theme.colors.porcelain,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  closeButton: {
    padding: 0,
    display: 'flex',
    gap: '12px',
    fontSize: '12px',
    alignItems: 'center',
  },
  category: {
    display: 'flex',
    justifyContent: 'start',
    gap: '12px',
    marginLeft: 'auto',
    fontSize: '16px',
    flexDirection: 'column',
    padding: '16px 24px',
    fontWeight: '600',
  },
  category_percentage_wrapper: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    height: '10px',
  },
  category_percentage: {
    height: '10px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#cccccc',
    position: 'relative',
  },
  category_percentage_main: {
    height: '10px',
    borderRadius: '4px',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    background: 'black'
  },
  category_percentage_number: {
    position: 'relative',
    fontSize: '20px',
    fontWeight: '600',
    bottom: '16px'

  },
  info: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    // maxHeight: '350px',
    padding: '0 24px',
    gap: '16px',
    overflow: 'hidden',
  },
  zone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    // padding: '16px 24px',
    // fontWeight: '600',
  },
  zone_title: {
    fontSize: '18px',
    fontWeight: '600',
    // color: '#EE613F',
  },
  zone_text: {
    fontSize: '14px',
    color: '#667085',
  },
  elements: {
    padding: '16px',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    height: 'fit-content'
  },
  elements_title_box: {
    marginBottom: '20px',
    fontSize: '14px',
    color: '#667085',
    display: 'flex',
    justifyContent: 'space-between',
  },
  gauge: {
    display: 'flex',
    gap: '12px',
    padding: '4px 8px',
    fontSize: '10px',
    fontWeight: '600',
    color: '#667085',
    alignItems: 'center',
    marginBottom: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  gauge_active: {
    background: '#003E6F',
    color: 'white !important',
    div: {
      color: 'white !important',


    }

  },
  gauge_label: {
    flexGrow: '1',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    fontWeight: '600',
    color: '#232323',
  },
  gauge_scale: {
    width: '100%',
    position: 'relative',
    height: '5.5px',
    borderRadius: '5px',
    backgroundColor: '#F3F3F3',

    img: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      borderRadius: '5px',
      height: '5.5px',
    }
  },
  notes: {
    padding: '24px',
  },
  bottomContent: {
    '& > *': {
      width: '50%',
      textAlign: 'justify',

      '&:nth-of-type(n+2)': {
        // marginLeft: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        borderLeft: `1px solid ${theme.colors.gallery}`,
      },
    }
  },
  allCategoriesBarChart: {
    // minWidth: theme.spacing(50),
    width: '100%',

    [theme.breakpoints.up('xl')]: {
      margin: '0 32px',
      padding: '0',
    },
  },
  '@media (min-width: 1024px)': {
    appBarShift: {
      // marginRight: drawerMinWidth,
    },
    content: {
      // marginRight: -drawerMinWidth,
    },
  },
  categoryInfo: {
    color: theme.colors.shark,
    fontWeight: 'bold',
  },
  informationCard: {
    [theme.breakpoints.down('xl')]: {
      padding: '0'
    },
  },
  category_percentage_container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',

  }
}));

export default function CompareCategoryDrawer({
  // open, assessmentId, category, categoryName, quadrant, userName, categoryLevel, coachNotes, lifeJournal,
  // handleDrawerClose, handleCoachNotesUpdate, handleLifeJournalUpdate, categoryCount, getCategoryColor,


  assessmentId1, assessmentId2, category, categoryName, quadrant1, quadrant2, handleDrawerClose, open,
}) {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const [currentQuadrant, setCurrentQuadrant] = useState(quadrant1);
  const [categoryDetails, setCategoryDetails] = useState({
    assessmentId1: {},
    assessmentId2: {},
  });
  const [elementName, setElementName] = useState(null);
  const [elementDetails, setElementDetails] = useState({
    assessmentId1: null,
    assessmentId2: null,

  });
  const elementNameRef = useRef();
  elementNameRef.current = elementName;
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  // Reason of why the component is being re-rendered twice
  useEffect(() => {
    setIsLoading(true)
    async function fetchData() {
      const { data: res1 } = await dataProvider.getOne(`assessments/${assessmentId1}/categories`, { id: category });
      const { data: res2 } = await dataProvider.getOne(`assessments/${assessmentId2}/categories`, { id: category });
      setCategoryDetails({
        assessmentId1: res1,
        assessmentId2: res2,

      });
      setIsLoading(false)

    }
    if (open) {
      fetchData();
    } else {
      setCategoryDetails({
        assessmentId1: {},
        assessmentId2: {},
      });
      setIsLoading(false)
    }
  }, [assessmentId1, assessmentId2, category, open]);

  function handleElementClick(name) {
    setElementName(name);
  }

  useEffect(() => {
    handleElementClick(null);
  }, [open]);



  const getColor = (average) => {

    if (average === 10) {
      return 'purple'
    }

    if (average >= 7) {
      return 'green'
    }
    if (average > 3) {
      return 'yellow'
    }

    return "red"
  }


  return (<>
    <Drawer
      // className={classes.root}
      anchor="right"
      open={open}
      classes={{ paper: classes.drawerPaper }}
      onClose={() => {
        setIsOpenDrawer(false)
        handleDrawerClose()
      }}
    >

      {
        open && (isLoading ? <Loading /> : <>
          <Box className={classes.root}>
            <Box
              className={classes.lifeCategory}
            // paddingX={4}
            // paddingY={2.5}
            // flexShrink={0}
            >


              <Box
                className={classes.lifeCategoryHeader}
                display="flex"
                alignItems="center"
              >
                <Box
                  className={classes.header}
                >
                  <Box
                    className={classes.title}
                  >
                    Category Details
                    2
                    {/* 1233333333333333333
                  {categoryLevel}. {categoryName} | {categoryDetails.health_percentage}% */}
                  </Box>
                </Box>
                <IconButton
                  onClick={() => {
                    setIsOpenDrawer(false)
                    handleDrawerClose()
                  }}
                  className={classes.closeButton}
                >
                  Close
                  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.589844 11.09L5.16984 6.5L0.589844 1.91L1.99984 0.5L7.99984 6.5L1.99984 12.5L0.589844 11.09Z" fill="#003E6F" />
                  </svg>
                </IconButton>
              </Box>
              <Box className={classes.category}>
                <Box className={classes.category_percentage_container}>
                  <b>{categoryName}</b>
                  <b>{categoryName}</b>

                  <Box className={classes.category_percentage_wrapper}>
                    <Box className={classes.category_percentage}>
                      <Box
                        className={classes.category_percentage_main}
                        style={{
                          background: `${getCategoryColor(quadrant1)}`,
                          width: `${categoryDetails.assessmentId1.health_percentage}%`
                        }}>

                      </Box>
                    </Box>
                    <Typography className={classes.category_percentage_number}>
                      {categoryDetails.assessmentId1.health_percentage}%

                    </Typography>
                  </Box>

                  <Box className={classes.category_percentage_wrapper}>

                    <Box className={classes.category_percentage}>
                      <Box
                        className={classes.category_percentage_main}
                        style={{
                          background: `${getCategoryColor(quadrant2)}`,
                          width: `${categoryDetails.assessmentId2.health_percentage}%`
                        }}>

                      </Box>
                    </Box>
                    <Typography className={classes.category_percentage_number}>
                      {categoryDetails.assessmentId2.health_percentage}%

                    </Typography>
                  </Box>

                  <Typography style={{ color: `${getQuadrantColor(quadrant1)}` }} className={classes.zone_title}>
                    {quadrants.find((q) => q.selector === quadrant1).name} Zone

                  </Typography>
                  <Typography style={{ color: `${getQuadrantColor(quadrant2)}` }} className={classes.zone_title}>
                    {quadrants.find((q) => q.selector === quadrant2).name} Zone

                  </Typography>
                </Box>

              </Box>
              <Box
                className={classes.info}
              >
                <Box className={classes.elements}>
                  <Box className={classes.elements_title_box}>
                    Elements
                    <HtmlTooltip placement="left" title={
                      <Box style={{ width: '224px' }}>
                        <b>
                          INSTRUCTION
                        </b>
                        <Typography color="inherit">
                          Click on the gauges below to receive deeper insight and guided direction.
                        </Typography>

                      </Box>
                    }>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2820_6794)">
                          <path d="M8.25 5.25H9.75V6.75H8.25V5.25ZM8.25 8.25H9.75V12.75H8.25V8.25ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z" fill="#667085" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2820_6794">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>


                    </HtmlTooltip>


                  </Box>
                  {
                    categoryDetails.assessmentId1.ratings?.filter((r) => r.name !== 'Total Health').map((element, index) => {
                      const color = getColor(element.scale)
                      return (
                        <Box
                          className={`${classes.gauge} ${element.name === elementName && classes.gauge_active}`}
                          key={index}
                          onClick={() => {
                            setIsOpenDrawer(true)
                            handleElementClick(element.name)
                          }
                          }
                        >
                          {/* <ElementGauge category={element} /> */}
                          <img height={16} width={16} src={getCategoryImage(element.name)} />
                          <Box
                            className={classes.gauge_label}

                          >
                            {getCategoryLabel(element.name)}
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>

              </Box>
            </Box>

          </Box>


        </>)

      }
      <CapabilityCompareLevelDrawer
        assessmentId1={assessmentId1}
        assessmentId2={assessmentId2}
        element1={categoryDetails?.assessmentId1?.ratings?.find(element => element.name === elementName)}
        element2={categoryDetails?.assessmentId2?.ratings?.find(element => element.name === elementName)}
        category={category}
        categoryName={categoryName}
        open={isOpenDrawer}
        handleDrawerClose={() => { setIsOpenDrawer(false) }}
        elementName={elementName}
        getColor={getColor}
        categoryDetails={categoryDetails}
      />
    </Drawer>

  </>

  );
}