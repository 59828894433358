import { CoachList } from "./coach.list";
import { CoachCreate } from "./coach.create";
import { CoachEdit } from "./coach.edit";


export const coaches = {
  list: CoachList,
  create: CoachCreate,
  edit: CoachEdit
};
