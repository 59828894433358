import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email

} from "react-admin";
import { Typography, Box } from "@mui/material";


const Separator = () => <Box pt="1em" />;
const requiredValidate = [required()];

const SectionTitle = ({ label }) => {

  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

export const CoachCreate = props => {

  return (
    <Create {...props} >
      <SimpleForm mode="onBlur">
        <SectionTitle label="Identity" />
        <TextInput autoFocus
          source="first_name"
          validate={requiredValidate}
        />
        <TextInput
          source="last_name"
          validate={requiredValidate}
        />
        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          validate={[required(), email()]}
        />
      </SimpleForm>
    </Create>

  );
};
