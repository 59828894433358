// in src/ClientLayout.js
import React, { useState } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecordContext } from 'react-admin';
import Logo from '../components/common/Logo';
import LogoutButton from '../pages/LogoutButton';
import { useLogout } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../analytics';

const useStyles = makeStyles()(theme => ({
  header: {
    background: 'white',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  button: {
    maxWidth: '40px',
    maxHeight: '40px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
  logo: {
    margin: '0 auto',
  },
  sideBar: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    height: '100%',
    width: 'auto',
    minWidth: '317px',
    borderRadius: '0 30px 30px 0',
    background: 'white',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateX(-100%)',
    transition: '1s',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  sideBar_open: {
    transform: 'translateX(0%)'
  },
  menuItem: {
    padding: '16px 16px 16px 32px',
    cursor: 'pointer',
    borderBottom: '1px solid #F3F3F3',
    fontSize: '14px',
    color: '#344054',
    lineHeight: '20px',
    fontWeight: '700',

  },
  menuItem_active: {
    color: 'white',
    background: '#0689FB',

  },
  account: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  account_image: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    img: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',

    }
  },
  account_image_point: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    border: '2px solid white',
    background: '#12B76A',
  },
  account_name: {
    color: '#344054',
    fontSize: '14px',
    lineHeight: '20px',
  },
  account_email: {
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',

  },
  sideBar_close: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logOut: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    padding: '20px 20px 20px 34px',
    borderTop: '1px solid #F3F3F3',
    coler: '#344054',
  },
}));

const MobileHeader = ({
  handlePlayInstructions,
  handleChangeCoach,
  children,
  props
}) => {
  const { classes } = useStyles();
  const record = useRecordContext();
  const userName = record.firstName;
  const userLastName = record.lastName;
  const userEmail = record.email;
  const logout = useLogout();
  const navigate = useNavigate();
  const handleClick = () => logout().then(result => {
    Mixpanel.reset();
    navigate('/login')
  });
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Box className={classes.header}>
        <button
          className={classes.button}
          onClick={() => { setIsOpen(true) }}
        >
          <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.77539H19M1 1.77539H19M1 13.7754H19" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <Box className={classes.logo}>
          <Logo width={53} height={37} />
        </Box>
      </Box>
      <Box className={`${classes.sideBar} ${isOpen && classes.sideBar_open}`}>
        <Box className={classes.account}>
          <Box className={classes.account_image}>
            <img src='/Smiling_Face.png' />
            <Box className={classes.account_image_point}></Box>
          </Box>
          <Box>
            <Typography className={classes.account_name}>{userName} {userLastName}</Typography>
            <Typography className={classes.account_email}>{userEmail}</Typography>
          </Box>
          <button className={classes.sideBar_close} onClick={() => { setIsOpen(false) }}>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 13L1 7L7 1" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </button>
        </Box>
        <Box
          className={`${classes.menuItem} ${classes.menuItem_active}`}
        >
          <Typography>Home</Typography>
        </Box>
        <Box
          className={`${classes.menuItem} ${classes.menuButton}`}
          onClick={handlePlayInstructions}
        >
          <Typography>Video Demo</Typography>
        </Box>
        <Box
          className={`${classes.menuItem} ${classes.menuButton}`}
          onClick={handleChangeCoach}
        >
          <Typography>Coach Details</Typography>
        </Box>
        <Box className={`${classes.logOut}`} onClick={handleClick}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H5M9.66667 10.3333L13 7M13 7L9.66667 3.66667M13 7H5" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          Logout
        </Box>
      </Box>
    </>
  );
};

export default MobileHeader;