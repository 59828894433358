import { Box, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui';
import parse from 'html-react-parser';
import { getCategoryColor } from '../../utils/geCategoryColor';

const useStyles = makeStyles()((theme) => ({
  categories_item: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: '25px 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    cursor: 'pointer',
    // padding: '16px 0',
  },
  categories_item_with_details: {
    gridTemplateColumns: '25px 1fr auto',

    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '25px 1fr',
    },
  },
  categories_item_percentage_container: {
    flexGrow: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

  },
  categories_item_title: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#000',
  },
  categories_item_percentage: {
    height: '6px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#cccccc',
    position: 'relative',
  },
  categories_item_percentage_fill: {
    position: 'absolute',
    height: '6px',
    top: '0',
    bottom: '0',
    left: '0',
    borderRadius: '4px',
    zIndex: '10'
  },
  categories_item_arrow: {
    display: 'flex',
    width: '170px',
    flexShrink: '0',
    gap: ' 16px',
    alignItems: 'center',
    color: '#003E6F',
    fontSize: '14px',
    textDecoration: 'underline',
    textDecorationColor: '#003E6F',
    textTransform: 'uppercase',
    fontWeight: '900',

    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  categories_title: {

    fontSize: '16px',
    color: 'black',
  },
  categories_container_title: {
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '8px',

  },
}));

export const CompareCategories = ({ categoriesData, handleCategoryClick = () => { }, withDetails }) => {
  const { classes } = useStyles();
  return (

    categoriesData.map((category) => {
      return <Box
        key={category.id}
        onClick={() => {
          handleCategoryClick(category.selector)
        }
        } className={`${classes.categories_item} ${withDetails && classes.categories_item_with_details}`}>
        <Typography className={`${classes.categories_item_title}`}>{category.percentage}%</Typography>
        <Box className={`${classes.categories_item_percentage_container}`}>
          <Typography className={`${classes.categories_item_title}`}>{parse(category.category || '')}</Typography>
          <Box className={`${classes.categories_item_percentage}`}>
            <Box className={`${classes.categories_item_percentage_fill}`} style={{
              background: getCategoryColor(category.quadrant),
              width: `${category.percentage}%`
            }}>

            </Box>
          </Box>



        </Box>
        {withDetails && <Box className={`${classes.categories_item_arrow}`}>
          Detail Comparison
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.590088 10.59L5.17009 6L0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z" fill="#003E6F" />
          </svg>
        </Box>}
      </Box>
    })

  )
}
