import React, { FC } from "react";
import {
  DateInput,
  Edit,
  NullableBooleanInput,
  TextInput,
  PasswordInput,
  Toolbar,
  useTranslate,
  FormWithRedirect,
  Form,
  TopToolbar,
  ShowButton,
  SimpleForm,
  required
} from "react-admin";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";


import { validatePasswords } from "./coach.create";


const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
    {/* Add your custom actions */}
    <Button
      color="primary"
      onClick={e => {
      }}
    >
      Custom Action
    </Button>
  </TopToolbar>
);

export const CoachEdit = props => {
  return (
    <Edit
      // actions={<PostEditActions />}
      title={<CoachTitle />}
      component="div"
      {...props}
    >
      <CoachForm />
    </Edit>
  );
};

const CoachTitle = ({ record }) => {
  return <span>Coach {record ? `"${record.firstName}"` : ""}</span>;
};

const CoachForm = props => {
  return (
    <SimpleForm>
      <Card>
        <CardContent>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <Typography variant="h6" gutterBottom>
                Identity
                {/* {translate("resources.coaches.fieldGroups.identity")} */}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="first_name"
                    resource="coaches"
                    validate={required()}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="last_name"
                    resource="coaches"
                    validate={required()}
                    fullWidth
                  />
                </Box>
              </Box>
              <TextInput
                type="email"
                source="email"
                resource="coaches"
                validation={{ email: true }}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
        </CardContent>

        {/* <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          undoable={true}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          resource="coaches"
        /> */}
      </Card>
      {/* <Button
        type="submit"
        variant="contained"
        sx={{ m: 2 }}
      
      >Submit</Button> */}
    </SimpleForm>

  );
};
