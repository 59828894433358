export const getCategoryColor = (quadrant) => {
  if (quadrant === 'action') {
    return 'linear-gradient(0.25turn, #F0753F, #F6B091)'

  }
  if (quadrant === 'flow') {
    return 'linear-gradient(0.25turn, #08E1EB, #9FE1E4)'

  }
  if (quadrant === 'watch') {
    return 'linear-gradient(0.25turn, #FFD000, #FFE882)'

  }
  if (quadrant === 'ease') {
    return 'linear-gradient(0.25turn, #9064ff, #8f59ff)'

  }

  return 'linear-gradient(0.25turn, #F0753F, #F6B091)'
}