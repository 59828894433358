export async function getAssessmentData({
  assessmentId,
  dataProvider,
  setRatingData,
  setZoneStatusArray = () => { },
  setPercentage = () => { },
  setLists,
  enchanceSurveyResults,
  setDataFetched = () => { },
  setActionList,
  setFlowList,
  setWatchList,
  setEaseList,
}) {
  if (assessmentId) {

    const { data: response } = await dataProvider.getOne('assessments', { id: assessmentId });
    const updatedRatingData = await Promise.all(response.SurveyResults.map(async (data, index) => {
      const res = await dataProvider.getOne(`assessments/${assessmentId}/categories`, { id: data.selector });
      const updatedRatings = {
        ratings: res.data.ratings,
        percentage: res.data.health_percentage,
        selector: res.data.category
      }

      return updatedRatings
    }))
    setRatingData(updatedRatingData)
    const zoneStatus = ['happiness', 'emotional', 'capabilities', 'engagement', 'time'];
    let newArray;
    try {
      newArray = await Promise.all(response.SurveyResults.map(async (data) => {
        return {
          category: data.selector, data:
            await Promise.all(zoneStatus.map(async (element) => {
              const res = await dataProvider.getOne(
                `assessments/${assessmentId}/categories/${data.selector}/element`,
                { id: element }
              );
              return res;
            }))
        }
      }));
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setZoneStatusArray(newArray);

    setPercentage(response.total_life_score)

    setLists(enchanceSurveyResults(response.SurveyResults));


    if (setActionList &&
      setFlowList &&
      setWatchList &&
      setEaseList) {
      setActionList([])
      setFlowList([])
      setWatchList([])
      setEaseList([])
      enchanceSurveyResults(response.SurveyResults) &&
        enchanceSurveyResults(response.SurveyResults).map(data => {
          switch (data.quadrant) {
            case "action":
              setActionList((previewActionList) => [...previewActionList, data]);
              break;
            case "flow":
              setFlowList((previewFlowList) => [...previewFlowList, data]);
              break;
            case "watch":
              setWatchList((previewWatchList) => [...previewWatchList, data]);
              break;
            case "ease":
              setEaseList((previewEaseList) => [...previewEaseList, data]);
              break;
            default:
              break;
          }
        })

    }


    setDataFetched(true);
  }
}