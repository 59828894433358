import React from 'react'
import { useStylesAssessments } from '../../assessments/LIARecordPage';
import { Box, Typography } from '@mui/material';
import TotalLifeHealth from '../TotalLifeHealth';
import { ItemLoader } from '../../ui/ItemLoader';
import parse from 'html-react-parser';

export const AssessmentsLifeScore = ({
  assessment,
  isDataFetched,
  categoriesData,
  handleCategoryClick,
  getCategoryColor,

}) => {
  const { classes: classes2 } = useStylesAssessments();
  return (
    <>
      <Box className={`${classes2.life_score}`}>
        <Box>
          <Typography className={`${classes2.life_score_title}`}>Life Score</Typography>
          <Typography className={`${classes2.life_score_text}`}>Your total life score represents your average level of fulfillment across all 13 categories.</Typography>
        </Box>

        <TotalLifeHealth
          percentage={assessment?.total_life_score || 0}
        // color={theme.colors.lightTeal}
        />
      </Box>
      {!isDataFetched ? <ItemLoader /> : <Box className={`${classes2.categories}`}>
        <Typography className={`${classes2.categories_title}`}>Categories</Typography>
        {categoriesData.map((category) => {
          return <Box
            key={category.id}
            onClick={() => {
              handleCategoryClick(category.selector)
            }
            } className={`${classes2.categories_item}`}>
            <Typography className={`${classes2.categories_item_title}`}>{category.percentage}%</Typography>
            <Box className={`${classes2.categories_item_percentage_container}`}>
              <Typography className={`${classes2.categories_item_title}`}>{parse(category.category || '')}</Typography>
              <Box className={`${classes2.categories_item_percentage}`}>
                <Box className={`${classes2.categories_item_percentage_fill}`} style={{
                  background: getCategoryColor(category.quadrant),
                  width: `${category.percentage}%`
                }}>

                </Box>
              </Box>



            </Box>
            <Box className={`${classes2.categories_item_arrow}`}>
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.590088 10.59L5.17009 6L0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z" fill="#003E6F" />
              </svg>
            </Box>
          </Box>
        })}

      </Box>}
    </>
  )
}
