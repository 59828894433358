import React from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import moment from 'moment';

export const PDFHeader = (props) => {

  const styles = StyleSheet.create({
    page_header: {
      width: '100%',
      height: '53px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'col',
      paddingLeft: '32px',
      paddingRight: '32px',
      marginTop: '16px',
    },
    lineStyle: {
      width: '100%',
      height: '1px',
      marginTop: '6px',
      backgroundColor: 'black'
    },
    lineMain: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
    inlineText: {
      display: 'flex',
      flexDirection: 'row',
      height: '16px',
    },
    imageStyle: {
      width: "68px",
      height: "48px"
    },
    textStyle: {
      fontSize: '12px',
      fontFamily: 'Times-Roman',
    },
    viewSpace: {
      width: '15px'
    },
    viewLine: {
      width: '1px',
      backgroundColor: 'grey'
    }
  });

  const date = moment(props.resultsDate).format("YYYY-MM-DD")

  return (
    <View style={styles.page_header}>
      <View style={styles.lineMain}>
        <View>
          <Image src={"/logo.png"} style={styles.imageStyle} />
        </View>
        <View style={styles.inlineText}>
          <Text style={styles.textStyle}>
            {props.firstName}
          </Text>
          <View style={styles.viewSpace}></View>
          <View style={styles.viewLine}></View>
          <View style={styles.viewSpace}></View>
          <Text style={styles.textStyle}>
            {date}
          </Text>
        </View>

      </View>
      <View style={styles.lineStyle} />
    </View>
  );
};

export default PDFHeader;