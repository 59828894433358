import React, { useState, useEffect } from "react";
import {
    useAuthProvider,
    Notification,
    TextInput,
    PasswordInput,
    required,
    useRedirect,
    useNotify,
    Loading,
    Error,
    Form
} from "react-admin";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";


import Grid from "@mui/material/Grid";

import { Typography, Box } from "@mui/material";
import red from "@mui/material/colors/red";
import { ThemeProvider } from '@mui/material/styles'
// import { ThemeProvider, StyledEngineProvider } from "@mui/styles";
import { makeStyles } from 'tss-react/mui';
import { createTheme } from "@mui/material/styles";
import Logo from '../common/Logo';

import { useLocation, BrowserRouter as Router } from "react-router-dom";

const myTheme = createTheme({
    palette: {
        primary: {
            main: "#182C3B"
        },
        secondary: {
            main: "#3694F9"
        },
        // background: {
        //     default: "#3694F9"
        // },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Arial",
            "sans-serif"
        ].join(",")
    },
    overrides: {
        MuiButton: {
            // override the styles of all instances of this component
            root: {
                // Name of the rule
                color: "white" // Some CSS
            }
        }
    }
});

const useStyles = makeStyles()(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        zIndex: 1,
        minHeight: "100vh",
        position: "relative",
    },
    loginText: {
        textAlign: "center",
    },
    grid: { backgroundColor: "Lavender" }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const validatePasswordsMatch = ({ password, confirm_password }) => {
    const errors = {};

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = "password mismatch";
        // errors.confirm_password = ["resources.customers.errors.password_mismatch"];
    }

    return errors;
};

// const validatePassword = regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,255}$/, 'Password must be 8 characters minimum ')

const ResetPassword = (props) => {
    const authProvider = useAuthProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const [confirm_password, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");

    const params = useQuery();
    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await authProvider.verify_reset_password(params);

                setShowForm(true);
                setLoading(false);
            } catch (err) {
                notify(err.message, "warning");
                redirect("/login");

                console.log(err);
                setError(err.message);
                setLoading(false);
                setShowForm(false);
            }
        }
        fetchData();
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!showForm) return <Notification />;

    const submit = async values => {
        try {
            const res = await authProvider.change_password({
                password: password,
                confirm_password: confirm_password,
                email: params.get("email"),
                token: params.get("token")
            });
            notify("Password successfully changed.");
            redirect("/login");
        } catch (e) {
            console.log(e);
            notify(e.message, "warning");
        }
    };
    return (
        <ThemeProvider theme={createTheme(myTheme)}>
            <div className={classes.root}>
                <CssBaseline />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <Form
                            classes={classes.grid}
                            onSubmit={submit}
                            validate={validatePasswordsMatch}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Logo
                                    height={100}
                                />
                                <Typography variant="h6" className={classes.loginText}>
                                    Enter a new password
                                </Typography>
                                <PasswordInput
                                    name="password"
                                    source="password"
                                    value={password}
                                    onChange={e =>
                                        setPassword(e.target.value)
                                    }
                                    validate={[required()]}
                                />
                                <PasswordInput
                                    name="confirm_password"
                                    source="confirm_password"
                                    validate={[required()]}
                                    onChange={e =>
                                        setConfirmPassword(e.target.value)
                                    }
                                />
                                <Typography variant="caption" className={classes.loginText}>
                                    * Please make sure the new password has a minimum of 8 characters, both upper and lower case, contains numbers, letters and at least one special character.
                                </Typography>
                                <Box mt="1em" />
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submit}
                                    >
                                        Save
                                    </Button>
                                </Box>
                                {/* </Toolbar> */}
                                <Notification />
                            </Box>

                        </Form>
                    </Grid>
                </Grid>

            </div>
        </ThemeProvider>
    );
};

export default ResetPassword;
