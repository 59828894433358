export default [
  {
    type: "innerIndividual",
    selector: "intellectual_creative",
    name: "Talents",
    description: "Use of and expression of one's specific intellectual and creative gifts, one's unique genius."
  },
  {
    type: "individualExpression",
    selector: "character",
    name: "Character",
    description: "The alignment of one's actions with one's values. Being the best one can be. Degree of honor and self-respect."
  },
  {
    type: "relational",
    selector: "love",
    name: "Love",
    description: "A deep personal connection with a loved one in a romantic relationship.<br />Finding and sharing a life with 'your person.' An intimate friendship of trust and respect."
  },
  {
    type: "communityExpression",
    selector: "work",
    name: "Work",
    description: "Your occupation, vocation, career or work. Doing work that matters to you, that honors your skills and qualities. Sense of purpose through your work."
  },
  {
    type: "communityExpression",
    selector: "leisure",
    name: "Leisure",
    description: "Time spent in leisure activities and experiences. Leisure life may include sports, hobbies, associations, and passions filling up 'me' time."
  },
  {
    type: "individualExpression",
    selector: "physical",
    name: "Physical Health",
    description: "The quality and function of your body and its ability to serve you. Most often directly associated with choices around exercise, nutrition, sleep, and lifestyle. "
  },
  {
    type: "communityExpression",
    selector: "social_contributions",
    name: "Contribution",
    description: "Investment and attention to contributing to make the world a better place.<br />Your contribution to improving the social and environmental elements for a better world.<br />Your accountability for your personal actions in being a good human."
  },
  {
    type: "communityExpression",
    selector: "financial",
    name: "Financial",
    description: "The financial means to meet your basic needs and fulfill your desires.<br />Abundance in this category means many options and freedoms are fully available to you and you have confidence in being ‘okay’ in the long run."
  },
  {
    type: "innerIndividual",
    selector: "mental_emotional_health",
    name: "Emotional",
    description: "Your mental and emotional state of well-being, ability and ease to cope and contribute in society."
  },
  {
    type: "relational",
    selector: "social_life",
    name: "Social",
    description: "Your social life is the belonging and connection you have in meaningful relationships with friends.<br />Spending time with the people that fill you up."
  },
  {
    type: "relational",
    selector: "family",
    name: "Extended Family",
    description: "Your family relations outside of your Immediate Family.<br /><br />If you are parent/grandparent this is your other family outside of your immediate family, and includes your siblings, parents, aunts, uncles, etc.<br /><br />If you do not have children then this refers to your aunts, uncles, nephews, nieces; the family you consider outside your direct family."
  },
  {
    type: "relational",
    selector: "parenting",
    name: "Immediate Family",
    description: "As a parent or grandparent this is a relationship with your sons, daughters, grandchildren, or other direct dependants.<br /><br />If you do not have children this is your relationship with your parents and siblings and those members you consider your 'direct' family.<br /><br />Your sense of family belonging & togetherness."
  },
  {
    type: "innerIndividual",
    selector: "spiritual",
    name: "Spiritual",
    description: "Belief in and connection with something bigger than oneself, a greater source.<br />This may or may not be associated with the faith and practice of organized religion."
  }
];