import { Typography } from '@mui/material'
import React from 'react'
import { useStylesAssessments } from '../../assessments/LIARecordPage';
import { Box } from '@mui/material';
import LifeInventoryAssessmentChart from '../LifeInventoryAssessmentChart';

export const AssessmentsChart = ({
  surveyResults,
  handleCategoryClick,
  handleItemHover,
}) => {
  const { classes: classes2 } = useStylesAssessments();
  return (
    <>
      <Box
        className={classes2.chartLegendExplanation}
      >
        <Typography className={classes2.chartText}>Size of bubble = Total health of life category</Typography>
      </Box>

      <Box
        marginTop={0.5}
        className={classes2.lifeInventoryAssessmentChart}
      >
        <LifeInventoryAssessmentChart
          surveyResults={surveyResults}
          handleCategoryClick={handleCategoryClick}
          handleItemHover={handleItemHover}
        />
      </Box>
    </>
  )
}
