import { View, Image } from '@react-pdf/renderer';
export const Slide = (props) => {
  const { scale, width, height, radius, type } = props;
  return (
    <View style={{ width: width, height: height, marginTop: '2px', marginBottom: '2px' }}>
      {scale === 10 && type !== "grey" &&
        <View>
          <Image src={"/purple.png"} style={{ width: '100%', height: height, borderRadius: radius }} />
        </View>
      }
      {scale >= 8 && scale < 10 && type !== "grey" &&
        <View>
          <Image src={"/green_back.png"} style={{ width: '100%', height: height, borderRadius: radius, position: 'absolute' }} />
          <Image src={"/green.png"} style={{ width: scale * 10 + "%", height: height, borderRadius: radius }} />
        </View>
      }
      {scale >= 6 && scale < 8 && type !== "grey" &&
        <View>
          <Image src={"/yellow_back.png"} style={{ width: '100%', height: height, borderRadius: radius, position: 'absolute' }} />
          <Image src={"/yellow.png"} style={{ width: scale * 10 + "%", height: height, borderRadius: radius }} />
        </View>
      }
      {scale < 6 && type !== "grey" &&
        <View>
          <Image src={"/red_back.png"} style={{ width: '100%', height: height, position: 'absolute', borderRadius: radius }} />
          <Image src={"/red.png"} style={{ width: scale * 10 + "%", height: height, borderRadius: radius }} />
        </View>
      }
      {type == 'grey' &&
        <View>
          <Image src={"/red_grey.png"} style={{ width: '100%', height: height, position: 'absolute', borderRadius: radius }} />
          <Image src={"/grey.png"} style={{ width: scale * 10 + "%", height: height, borderRadius: radius }} />
        </View>
      }
    </View>)
}
export default Slide;