import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  accordion_title: {
    color: '#003E6E',
    display: 'block',
    fontSize: '24px',
    fontWeight: '600',
  },
  accordion_text: {
    color: '#878787',
    display: 'block',
    fontSize: '16px',
    fontWeight: '400',
  },
  accordion_text_container: {
    '.MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '0',
    },
  },
  infoContainerItem_accordion: {
    margin: '0 !important',
    boxShadow: 'none',
    outline: 'none',
    padding: '24px',

    "&::before": {
      display: 'none',

    },

    [theme.breakpoints.down('xl')]: {
      padding: '10px'
    },

  },

}));

export const CompareAccordion = ({ defaultExpanded, title, text, children }) => {
  const { classes } = useStyles();
  return (
    <Accordion

      defaultExpanded={defaultExpanded}
      className={`${classes.infoContainerItem} ${classes.infoContainerItem_accordion}`}>
      <AccordionSummary
        expandIcon={<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.59 7.90991L6 3.32991L1.41 7.90991L0 6.49991L6 0.499912L12 6.49991L10.59 7.90991Z" fill="#003E6F" />
        </svg>
        }
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${classes.accordion_text_container}`}
      >
        <Typography
          className={`${classes.accordion_title}`}

        >
          {title}

        </Typography>
        <Typography
          className={`${classes.accordion_text}`}

        >
          {text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}

      </AccordionDetails>
    </Accordion>
  )
}
