import React, { useState, useEffect } from 'react';
import { Dialog, Box } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import { makeStyles, withStyles } from 'tss-react/mui';
import YouTube from 'react-youtube';

const useStyles = makeStyles()(theme => ({
  player: {
    display: 'block',
  },
  dialog: {
    width: '1000px',
    height: '600px',
  },
  dialog_content: {
    width: '800px',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {
      width: '300px',
      height: '250px',
    },
  }
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const IntructionsVideoDialog = ({ playInstructions }) => {
  const introVideo = 'intro-video';
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const opts = {
    width: 'auto',
    height: 'auto',
    playerVars: {
      autoplay: 1,
    },
  };

  function isWatched() {
    return localStorage.getItem(introVideo) === 'true';
  }

  function handleClose(close) {
    if (close) {
      setOpen(false);
      localStorage.setItem(introVideo, true);
    }
  }

  useEffect(() => {
    const play = !isWatched() || playInstructions > 0;
    setOpen(play);
  }, [playInstructions]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={false}
      onClose={() => handleClose()}
    // className={classes.dialog}
    >
      <DialogContent className={classes.dialog_content}>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(true)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* <YouTube
          className={classes.player}
          videoId="vSGcbP6OXio"
          opts={opts}
          onEnd={() => handleClose(true)}
        /> */}
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ATIwZqkqDLs?si=j9a5b_ksXGuBq_9j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </DialogContent>

    </Dialog>
  );
};

export default IntructionsVideoDialog;