export const create = ({
  title,
  subtitle,
  width = 1000,
  height = '100%',
  series,
  zoomType = "xy",
  displayBottomLines = true,
  handleClick = () => { },
  handleHover = () => { },
}) => {
  series = series || [];
  const bottomLineConfig = displayBottomLines
    ? {}
    : {
      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
    };

  return {
    chart: {
      type: "bubble",
      plotBorderWidth: 0,
      zoomType,
      // width,
      height,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle
    },
    accessibility: {
      // point: {
      //   descriptionFormatter: function(point) {
      //     const index = point.index + 1;
      //     const category = point.name;
      //     const rating = point.x;
      //     const priority = point.y;
      //     const bubble = point.z;

      //     return (
      //       index +
      //       ", " +
      //       category +
      //       ", rating: " +
      //       rating +
      //       ", priority: " +
      //       priority +
      //       ", bubble: " +
      //       bubble
      //     );
      //   }
      // }
    },
    xAxis: {
      min: 0,
      max: 14,
      gridLineWidth: 0,
      title: {
        text: "Fulfillment",
        enabled: false,
      },
      labels: {
        format: "{value}",
        enabled: false
      },
      plotLines: [
        {
          color: "#70707082",
          dashStyle: "",
          width: 1,
          value: 8.5,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic"
            },
            text: ""
          },
          zIndex: 3
        }
      ],
      accessibility: {
        rangeDescription: "fulfillment"
      },
      ...bottomLineConfig
    },
    yAxis: {

      gridLineWidth: 0,

      startOnTick: false,
      endOnTick: false,
      title: {
        text: "Priority",
        enabled: false,
      },
      labels: {
        format: "{value}",
        enabled: false
      },
      maxPadding: 0.2,
      plotLines: [
        {
          color: "#70707082",
          dashStyle: "",
          width: 1,
          value: 7.5,
          label: {
            align: "right",
            style: {
              fontStyle: "italic"
            },
            text: "",
            x: -10
          },
          zIndex: 3
        }
      ],
      accessibility: {
        rangeDescription: "Range: 1 to 10"
      }
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        cursor: "pointer",
        point: {
          events: {
            click() {
              handleClick(this.selector);
              console.log(this.selector)
            },
            mouseOver(event) {
              if (event.target.selector) handleHover(true, event.target.selector, 'category');
            },
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            fontSize: "10px",
            color: '#000000',
            textOutline: '0',
            fontWeight: 'normal',
          },
        }
      },
      bubble: {
        minSize: 65,
        maxSize: 125,
        borderWidth: 0,
      }
    },
    series: [
      { data: series },
      // {
      //   enableMouseTracking: false,
      //   allowOverlap: true,
      //   linkedTo: 0,
      //   marker: {
      //     enabled: false
      //   },
      //   dataLabels: {
      //     defer: false,
      //     enabled: true,
      //     allowOverlap: true,
      //     y: 20,
      //     style: {
      //       fontSize: "20px"
      //     },
      //     format: "{point.name}"
      //   },
      //   keys: ["x", "y", "name", "selector"],
      //   data: [
      //     {
      //       x: -1,
      //       y: 15,
      //       name: "ACTION",
      //       dataLabels: {
      //         color: "red",
      //         fontSize: '13px',
      //       }
      //     },
      //     {
      //       x: -1,
      //       y: 0,
      //       name: "WATCH",
      //       dataLabels: {
      //         color: "#990000",
      //         fontSize: '13px',
      //       }
      //     },
      //     {
      //       x: 11,
      //       y: 0,
      //       name: "EASE",
      //       dataLabels: {
      //         color: "green",
      //         fontSize: '13px',
      //       }
      //     },
      //     {
      //       x: 11,
      //       y: 15,
      //       name: "FLOW",
      //       dataLabels: {
      //         color: "purple",
      //         fontSize: '13px',
      //       }
      //     }
      //   ]
      // }
    ]
  };
};