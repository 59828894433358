import React, {
  useState,
  useMemo,
  useContext,

} from 'react';
import { useGetList } from 'react-admin';



const AppContext = React.createContext({
  isLoading: true,
  isLoggedIn: false,
});

// export const AppProvider = ({ children }) => {
//   const [isOpenSidebar, setIsOpenSidebar] = useState(true)
//   const { data: coaches } = useGetList("coaches", { pagination: { page: 1, perPage: 400 } });

//   const value = useMemo(
//     () => ({
//       isOpenSidebar,
//       setIsOpenSidebar,
//       coaches,
//     }),
//     [coaches, isOpenSidebar],
//   );

//   return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
// };

// export const UserConsumer = AppContext.Consumer;

export const useAppContext = () => {
  const context = useContext(AppContext);

  return context;
};

export default AppContext;
