import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  loader: {},
  loader_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

export const ItemLoader = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.loader_container}>
      <CircularProgress className={classes.loader} />
    </Box>
  )
}
