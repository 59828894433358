import React from "react";
import {
  Show,
  usePermissions,
  useRecordContext,
  useResourceContext,
  RecordContextProvider
} from "react-admin";
import { roles } from "../../config";
import ClientLayout from '../../layouts/ClientLayout';
import { useParams, useLocation } from 'react-router-dom';

const ClientTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <div>Client {record ? `"${record.firstName}"` : ""}</div>;
};

const NoActions = ({ basePath, data, resource }) => (
  <div />
);

export const AssessmentShow = (props) => {
  const { permissions } = usePermissions();
  let params = useParams();
  const isCoach = permissions === roles.ADMIN || permissions === roles.COACH;

  const assessmentId = params.assessmentId;

  return (
    <Show
      {...props}
      component="div"
      title={<ClientTitle />}
      actions={<NoActions />}
      hasList={false}
      hasEdit={false}
    >
      <ClientLayout withoutPadding={props.withoutPadding}>
        {React.cloneElement(props.children, { assessmentId })}
      </ClientLayout>
    </Show>
  );
};