import { HttpError } from "react-admin";
import { roles } from "./config";
const apiURL = process.env.REACT_APP_API_URL;

export const AuthProvider = () => {
    const authProvider = {
        login: async ({ username, password, role }) => {
            const request = new Request(`${apiURL}/api/v1/auth/login`, {
                method: "POST",
                body: JSON.stringify({ username, password, role }),
                headers: new Headers({ "Content-Type": "application/json" })
            });

            const response = await fetch(request);
            const res = await response.json();

            if (response.status < 200 || response.status >= 300) {
                throw new Error(res.message);
            }

            localStorage.setItem("token", res.token);
            localStorage.setItem("permissions", res.user.Roles[0].code);
            localStorage.setItem('auth', JSON.stringify(res.user));
        },
        forgot_password: async ({ email }) => {
            const request = new Request(
                `${apiURL}/api/v1/auth/forgot_password`,
                {
                    method: "POST",
                    body: JSON.stringify({ email }),
                    headers: new Headers({ "Content-Type": "application/json" })
                }
            );

            const response = await fetch(request);
            const res = await response.json();
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (res && res.message) || res.statusText,
                        res.status,
                        res
                    )
                );
            }
            return res;
        },
        verify_reset_password: async qparams => {
            const request = new Request(
                `${apiURL}/api/v1/auth/reset_password?${qparams.toString()}`,
                {
                    method: "GET",
                    headers: new Headers({ "Content-Type": "application/json" })
                }
            );

            const response = await fetch(request);
            const res = await response.json();
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (res && res.message) || res.statusText,
                        res.status,
                        res
                    )
                );
            }
            return res;
        },
        change_password: async ({
            token,
            email,
            password,
            confirm_password
        }) => {
            const request = new Request(
                `${apiURL}/api/v1/auth/change_password`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        token,
                        email,
                        password,
                        confirm_password
                    }),
                    headers: new Headers({ "Content-Type": "application/json" })
                }
            );

            const response = await fetch(request);
            const res = await response.json();
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (res && res.message) || res.statusText,
                        res.status,
                        res
                    )
                );
            }
            return res;
        },
        logout: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("permissions");
            localStorage.removeItem("auth");
            return Promise.resolve();
        },
        checkAuth: () =>
            localStorage.getItem("token")
                ? Promise.resolve()
                : Promise.reject(),
        checkError: error => {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem("token");
                localStorage.removeItem("permissions");
                localStorage.removeItem("roles");
                localStorage.removeItem("user_id");
                return Promise.reject();
            }
            return Promise.resolve();
        },
        getPermissions: () => {
            const role = localStorage.getItem("permissions");

            return role ? Promise.resolve(parseInt(role)) : Promise.resolve([]);
        },
        getIdentity: () => {
            try {
                const { id, first_name, last_name, role } = JSON.parse(localStorage.getItem('auth'));
                return Promise.resolve({ id, first_name, last_name, role, fullName: `${first_name} ${last_name}` });
            } catch (error) {
                return Promise.reject(error);
            }
        }
    };
    return authProvider;
};
