import { Box } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    ////////////////////
    flexGrow: 2,
    padding: theme.spacing(6),
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      padding: '20px',
      // overflowX: 'hidden',
      // maxWidth: "100vw",
      // backgroundColor: 'white'
    },
  },
  content_without_padding: {
    padding: '0',

  },
}));

export const Content = ({ children, withoutPadding, record }) => {
  const { classes } = useStyles();
  const props = { record }
  return (
    <Box className={`${classes.content} ${withoutPadding && classes.content_without_padding}`}>
      {React.cloneElement(children, props)}
    </Box>
  )
}
